import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TOAST_TYPE } from "../constants";

export const useToast = () => {
	const [showToast, setShowToast] = useState(false);
	const [message, setMessage] = useState("");
	const [toastType, setToastType] = useState(TOAST_TYPE.info);

	useEffect(() => {
		if (showToast) {
			toast[`${toastType}`](message, {
				position: toast.POSITION.BOTTOM_CENTER,
				theme: "colored",
			});
		}

		return () => setShowToast(false);
	}, [showToast, message, toastType]);

	const triggerToast = ({ message, type = TOAST_TYPE.info }) => {
		setMessage(message);
		setToastType(type);
		setShowToast(true);
	};

	return {
		triggerToast,
	};
};
