import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { BsSendPlus } from "react-icons/bs";
import { PageTitleBar } from "../common";
import { BADGE_STYLE } from "../common/constants";
import { useApiService } from "../context"; //theme
import AddInvitationFormContainer from "./AddInvitationFormContainer";
import Card from "./Card";

const Invitations = () => {
	const { auth, rotaPadInvitations, rotaPadUser } = useApiService();
	const [visibleLeft, setVisibleLeft] = useState(false);
	const [newGroup, setNewGroup] = useState({
		email: "",
		role: "",
		group: "",
		firstname: "",
		lastname: "",
		uid: undefined,
		createdAt: moment().format(),
	});

	if (!rotaPadUser) return null;

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-12'>
					<AddInvitationFormContainer
						visibleLeft={visibleLeft}
						setVisibleLeft={setVisibleLeft}
						auth={auth}
						newGroup={newGroup}
						setNewGroup={setNewGroup}
					/>

					<PageTitleBar
						title='Invitations'
						count={
							_.isObject(rotaPadInvitations)
								? Object.keys(rotaPadInvitations).length
								: 0
						}
						badgeStyle={BADGE_STYLE.Menu}
					/>

					{_.isObject(rotaPadInvitations) &&
					Object.keys(rotaPadInvitations).length > 0 ? (
						<div className='py-2 px-0'>
							<div className='row mx-0'>
								{Object.keys(rotaPadInvitations).map((key) => {
									return (
										<Card
											key={key}
											setNewGroup={setNewGroup}
											setVisibleLeft={setVisibleLeft}
											{...rotaPadInvitations[key]}
										/>
									);
								})}
							</div>
						</div>
					) : (
						<>
							<div className='card mt-3 text-center'>
								<div className='mt-4 pt-4 pb-2 text-muted'>
									<BsSendPlus style={{ fontSize: "5rem", color: "#ad8fdb" }} />
								</div>
								<div className='d-flex justify-content-center px-4 mb-2'>
									<div className='text-muted w-50'>
										<div className=''>
											Invite others to join your workspace. You can also allow
											members to sign up using your company’s email domain.
											Learn more
										</div>
										<div className='pt-4 mb-4 pb-3'>
											<button
												type='button'
												className='btn btn-sm btn-primary btnPrimary me-0'
												onClick={() => setVisibleLeft(true)}>
												<i
													className='pi pi-user-plus'
													style={{
														fontSize: "12px",
														marginRight: "4px",
													}}></i>
												Send an invite
											</button>
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default Invitations;
