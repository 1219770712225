import styled from '@emotion/styled';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdOpenInNew } from 'react-icons/md';
import { RiEditLine } from 'react-icons/ri';
import { Card as PageCard, Pill, RoundButtonBase, ToolTip } from '../../common';
import { BADGE_STYLE } from '../../common/constants';
import { useApiService } from '../../context';

const RoundedPill = styled.div`
  padding: 7px;
  color: rgb(255, 255, 255);
  background-color: rgba(89, 161, 49, 0.66);
  font-size: 12px;
`;

const RoundedCircle = styled.div`
  padding: 4px;
  background: #0000004f;
  line-height: 7px;
  cursor: pointer;
  &:hover {
    background: #00000098;
  }
`;

const TimeCheckStyledDiv = styled.span`
  background: #00000036;
  border-radius: 5px;
  padding: 2px;
  margin-left: 8px;
  font-size: 11px;
  letter-spacing: 0.04rem;
  &:hover {
    background: #000000a1;
  }
`;

const StyledCard = styled.div`
  cursor: pointer;
  // width: 15rem;
  font-size: 12px;
  padding-top: 0px;
  min-height: 211px;
  border: #af97d8 solid 3px;
  img {
    width: 50%;
  }

  div > svg {
    font-size: 34px;
    color: #af97d8;
  }
  &:hover {
    border: #b2d897 solid 3px;

    div > svg {
      color: #b2d897;
    }
  }
`;
const StyledDescriptionDiv = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;
const Card = ({
  autoApproved,
  endDate,
  leavePolicy,
  reason,
  startDate,
  startEndDateTimeRange,
  approver,
  user,
  status,
  uid,
  createdAt,
  setNewGroup,
  setVisibleLeft,
}) => {
  const {
    deleteDoc,
    setRotaPadInvitations,
    fetchData,
    database,
    setRotaPadAbsences,
    rotaPadTeam,
    rotaPadTeamMembersTopic,
  } = useApiService();

  const handleClick = useCallback(async () => {
    const DeleteGroup = await deleteDoc({
      db: database,
      path: 'absences/' + uid,
    });

    // const fetchGroups = await fetchData({
    // 	db: database,
    // 	path: "absences",
    // });

    // // console.log("absences ===== ", fetchGroups);
    // if (fetchGroups) {
    // 	setRotaPadAbsences(fetchGroups);
    // }
  }, [database, deleteDoc, fetchData, setRotaPadAbsences, uid]);

  const getUsers = useMemo(
    () =>
      _.isObject(rotaPadTeamMembersTopic)
        ? Object.keys(rotaPadTeamMembersTopic).map((key) => {
            return {
              name:
                rotaPadTeamMembersTopic[key].firstname +
                ' ' +
                rotaPadTeamMembersTopic[key].lastname,
              value: rotaPadTeamMembersTopic[key].uid,
            };
          })
        : [{}],
    [rotaPadTeamMembersTopic]
  );

  const requester = useMemo(
    () =>
      _.find(getUsers, { value: user }) || { name: 'User not in Workspace 😎' },
    [user, getUsers]
  );

  const manager = useMemo(
    () =>
      _.find(getUsers, { value: approver }) || {
        name: 'User not in Workspace 😎',
      },
    [approver, getUsers]
  );

  return (
    <>
      <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
        <PageCard className='card shadow-sm mb-3 me-3'>
          <div className='card-header bg-transparent pt-3'>
            <div
              className='text-uppercase fw-semibold'
              style={{
                fontSize: '11px',
                // color: '#a9a9a9',
                marginBottom: '-4px',
                marginTop: '-4px',
                lineHeight: '12px',
              }}
            >
              {`${moment(new Date(startDate)).format('dddd Do,')} ${moment(
                new Date(startDate)
              ).format('MMMM YYYY')}`}{' '}
              -{' '}
              {`${moment(new Date(endDate)).format('dddd Do,')} ${moment(
                new Date(endDate)
              ).format('MMMM YYYY')}`}
            </div>
            <div
              className='text-uppercase fw-bold mt-2'
              style={{
                fontSize: '14px',
                // color: '#8d95d2',
              }}
            >
              <div className='mb-1  text-truncate'>{requester?.name}</div>
            </div>
          </div>
          <div className='card-body '>
            <Pill
              content={leavePolicy}
              bgStyle={{ ...BADGE_STYLE[leavePolicy] }}
              toolTipMessage={leavePolicy}
            />
            <Pill
              content={status}
              bgStyle={{ ...BADGE_STYLE.Group }}
              toolTipMessage={status}
            />
            {/* {`by ${manager?.name}`} */}
            <StyledDescriptionDiv className='pt-2'>
              {reason}
            </StyledDescriptionDiv>
          </div>
          <div className='card-footer bg-transparent '>
            <ToolTip
              placement='top'
              overlay={<span style={{ fontSize: 10 }}>Update</span>}
            >
              <RoundButtonBase
                variant='outline'
                onClick={() => {
                  setNewGroup({
                    autoApproved,
                    endDate,
                    leavePolicy,
                    reason,
                    startDate,
                    startEndDateTimeRange: [
                      new Date(startDate),
                      new Date(endDate),
                    ],
                    approver,
                    user,
                    status,
                    uid,
                    createdAt,
                  });
                  setVisibleLeft(true);
                }}
                type='button'
                size='small'
                className={`
								me-2 `}
              >
                <RiEditLine />
              </RoundButtonBase>
            </ToolTip>
            <ToolTip
              placement='top'
              overlay={<span style={{ fontSize: 10 }}>Delete</span>}
            >
              <RoundButtonBase
                variant='outline'
                type='button'
                onClick={handleClick}
                size='small'
                className={`me-2 `}
              >
                <AiOutlineDelete />
              </RoundButtonBase>
            </ToolTip>
          </div>
        </PageCard>
      </div>
    </>
  );
};

export default Card;
