import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { TOAST_TYPE } from '../constants';
import { useClickAway } from 'react-use';

export const useSideBarClickAway = ({ ref, setVisibleLeft, setNewGroup }) => {
  // const ref = useRef(null);
  useClickAway(ref, () => {
    setVisibleLeft && setVisibleLeft();
    setNewGroup && setNewGroup();
  });

  // const triggerToast = ({ message, type = TOAST_TYPE.info }) => {
  // 	setMessage(message);
  // 	setToastType(type);
  // 	setShowToast(true);
  // };

  return {
    ref,
  };
};
