import _ from 'lodash';

export const convertObjectToArray = ({ item }) => {
  return _.isObject(item) && Object.keys(item).length > 0
    ? Object.keys(item).map((key) => {
        return { ...item[key] };
      })
    : [];
};

export const formatCurrency = (value) => {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const formatHour = (value) => {
  return value.toFixed(2);
};

export const getUnitName = ({ unitId, customers }) => {
  if (_.isEmpty(customers)) {
    return null;
  }

  // Get unit details using unitId - unitName
  // Step 1: Get all units
  const unitsCollection = _.reduce(
    customers || [],
    function (sum, n) {
      const { units = [], id, name } = n;
      const newUnitsArray = units.map((obj) => {
        return {
          ...obj,
          customerId: id,
          customerName: name,
        };
      });
      return [...sum, ...newUnitsArray];
    },
    []
  );

  // Get unit details
  const unitDetails = _.find(unitsCollection, { id: unitId });

  return {
    // year: '2023',

    unitPrintName: unitDetails && unitDetails.name,
    unitPrintId: unitDetails && unitDetails.unitId,
    unitPrintAddress: unitDetails && unitDetails.address,
    customerId: unitDetails && unitDetails.customerId,
    customerName: unitDetails && unitDetails.customerName,
  };
};
