import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  titleContainer: {
    // flexDirection: 'row',
    textAlign: 'center',
    marginTop: 12,
  },
  reportTitle: {
    fontSize: 12,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});

const InvoiceThankYouMsg = ({ reportType }) => (
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}>
      THANK YOU MSG GOES HERE
      {/* {reportType === INVOICE_REPORT_TYPE && 'Thank you for your business'}
      {reportType === WORKSHEET_REPORT_TYPE && 'Monthly Shifts Record'} */}
    </Text>
  </View>
);

export default InvoiceThankYouMsg;
