import styled from '@emotion/styled';
import _ from 'lodash';
import moment from 'moment';
import { useCallback } from 'react';
import { Form } from 'react-final-form';
import { generate as generateUUID } from 'short-uuid';
import { TOAST_TYPE } from '../../common/constants';
import { useToast } from '../../common/hooks/useToast';
import { useApiService } from '../../context';
import AddAbsencesForm from '../AddAbsencesForm';
import {
  StyledFooterDiv,
  StyledHeaderDiv,
} from '../../customers/AddCustomerFormContainer';
import { ButtonBase } from '../../common';

export const StyledDropDownDiv = styled.div`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
  .customDropdown {
    width: 100%;
  }
  .customDropdown:not(.p-disabled):hover {
    border-color: #6366f1;
  }

  .customDropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    padding: 8px 8px;
    font-size: 12px;
  }

  .customDropdown .p-dropdown-trigger {
    width: 2rem;
  }

  .customDropdown .p-dropdown-trigger-icon {
    font-size: 10px;
  }

  .p-disabled,
  .p-component:disabled {
    opacity: 0.6;
    background-color: #e9ecef;
  }
`;

export const StyledInput = styled.input`
  padding: 8px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;

const StyledLabel = styled.label`
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
`;
const StyledH5 = styled.h5`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
`;
const StyledDiv = styled.div`
  right: 12px !important;
  top: 42px !important;

  i {
    cursor: pointer;
    z-index: 200;
    font-size: large;
    color: #6c757d;

    &:hover {
      color: #7f00ff;
    }
  }
`;

const AddAbsencesFormContainer = ({
  visibleLeft,
  setVisibleLeft,
  auth,
  newGroup,
  setNewGroup,
  users,
}) => {
  const {
    createDoc,
    database,
    rotaPadUser,
    setRotaPadGroups,
    fetchData,
    rotaPadGroups,
    setRotaPadInvitations,
    rotaPadInvitations,
    setRotaPadAbsences,
  } = useApiService();
  const { triggerToast } = useToast();

  const validate = (data) => {
    let errors = {};

    if (!data.leavePolicy) {
      errors.leavePolicy = 'Request type is required.';
    }

    if (!data.startEndDateTimeRange) {
      errors.startEndDateTimeRange = 'Start and end dates are required.';
    }

    if (
      data.startEndDateTimeRange &&
      _.compact(data.startEndDateTimeRange).length === 1
    ) {
      errors.startEndDateTimeRange = 'End date is required.';
    }

    if (!data.approver) {
      errors.approver = 'Approver is required.';
    }

    if (!data.user) {
      errors.user = 'Requested by is required.';
    }
    return errors;
  };

  const handleSubmitForm = useCallback(
    async (data, form) => {
      // const { name, handle, description } = data;

      const { leavePolicy, startEndDateTimeRange, reason, user, approver } =
        data || {};
      // console.log("data &&&&&&&&&&&&&&& ", data);

      const newData = {
        leavePolicy,
        startDate: moment(_.compact(startEndDateTimeRange)[0]).format(),
        endDate: moment(_.compact(startEndDateTimeRange)[1]).format(),
        reason,
        autoApproved: true,
        status: 'APPROVED',
        user,
        approver,
        startEndDateTimeRange,
        createdAt: moment().format(),
      };

      const uuid = data.uid ? data.uid : generateUUID();

      const newGroup = _.pickBy(
        {
          ...newData,
          uid: uuid,
        },
        _.identity
      );
      if (rotaPadUser) {
        const success = await createDoc(database, `absences/${uuid}`, newGroup);

        // const fetchGroups = await fetchData({
        // 	db: database,
        // 	path: "absences",
        // });

        // // console.log("absences ===== ", fetchGroups);
        // if (fetchGroups) {
        // 	setRotaPadAbsences(fetchGroups);
        // }

        if (success) {
          triggerToast({
            message: 'Request successfully sent!',
            type: TOAST_TYPE.success,
          });
        } else {
          triggerToast({
            message: 'Request could not be sent!',
            type: TOAST_TYPE.warning,
          });
        }

        form.reset();
        form.resetFieldState('leavePolicy');
        form.resetFieldState('startEndDateTimeRange');
        form.resetFieldState('reason');

        form.resetFieldState('user');
        form.resetFieldState('approver');

        setNewGroup({});
        setVisibleLeft(false);
      }
    },
    [
      createDoc,
      database,
      fetchData,
      rotaPadUser,
      setNewGroup,
      setRotaPadAbsences,
      setVisibleLeft,
      triggerToast,
    ]
  );

  return (
    <Form
      onSubmit={handleSubmitForm}
      initialValues={newGroup}
      validate={validate}
      render={({
        handleSubmit,
        pristine,
        submitting,
        hasValidationErrors,
        form,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div
              className={`offcanvas offcanvas-end ${
                visibleLeft ? 'show  shadow-lg' : 'hiding'
              }`}
              // tabIndex='-1'
              id='offcanvasExample'
              aria-labelledby='offcanvasExampleLabel'
              style={{ width: '24em', zIndex: 555 }}
            >
              <StyledHeaderDiv
                className='offcanvas-header'
                // style={{ backgroundColor: '#f5ebff' }}
              >
                <h5
                  className='offcanvas-title'
                  style={{ lineHeight: '27px' }}
                >
                  Request leave
                </h5>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='offcanvas'
                  aria-label='Close'
                  onClick={() => {
                    setVisibleLeft(false);
                    form.reset();
                    form.resetFieldState('leavePolicy');
                    form.resetFieldState('startEndDateTimeRange');
                    form.resetFieldState('reason');

                    form.resetFieldState('user');
                    form.resetFieldState('approver');

                    setNewGroup({});
                  }}
                ></button>
              </StyledHeaderDiv>
              <div
                style={{ maxHeight: 'calc(100vh - 118px)', overflow: 'auto' }}
              >
                <div className='offcanvas-body p-0'>
                  <div
                    className='d-flex flex-column mb-0'
                    style={{ height: '100vh' }}
                  >
                    <div className='col-12 mb-1 text-muted px-3 pt-3'>
                      Create an absence request to let your manager and team
                      know when you are out-of-office. Options used and allowed
                      for team members to use vary based on your organization’s
                      work policies.
                    </div>
                    <AddAbsencesForm
                      hasValidationErrors={hasValidationErrors}
                      submitting={submitting}
                      pristine={pristine}
                      handleSubmit={handleSubmit}
                      visibleLeft={visibleLeft}
                      uid={newGroup.uid}
                    />
                  </div>
                </div>
              </div>

              <StyledFooterDiv
                className='offcanvas-header'
                // style={{ backgroundColor: '#f5ebff' }}
              >
                <div className='btn-toolbar my-2 my-sm-0 my-md-0'>
                  <div className='d-flex flex-row mb-0'>
                    <div className='me-2'>
                      <ButtonBase
                        type='button'
                        variant='primary'
                        size='small'
                        onClick={() => {
                          setVisibleLeft(false);
                          form.reset();
                          form.resetFieldState('leavePolicy');
                          form.resetFieldState('startEndDateTimeRange');
                          form.resetFieldState('reason');

                          form.resetFieldState('user');
                          form.resetFieldState('approver');

                          setNewGroup({});
                        }}
                      >
                        Cancel
                      </ButtonBase>
                    </div>
                    <div>
                      <ButtonBase
                        type='submit'
                        variant='primary'
                        size='small'
                        disabled={pristine || submitting || hasValidationErrors}
                      >
                        {`${newGroup.uid ? 'Update' : 'Send'} leave request`}
                      </ButtonBase>
                    </div>
                  </div>
                </div>
              </StyledFooterDiv>
            </div>
          </form>
        );
      }}
    />
  );
};

export default AddAbsencesFormContainer;
