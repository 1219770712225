import styled from '@emotion/styled';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useUpdatePassword } from 'react-firebase-hooks/auth';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import zxcvbn from 'zxcvbn';
import { ButtonBase, ToolTip } from '../../common';
import { TOAST_TYPE } from '../../common/constants';
import { useToast } from '../../common/hooks/useToast';
import { useApiService } from '../../context'; //theme

const StyledLabel = styled.label`
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
  margin-top: 8px;
`;

const StyledInput = styled.input`
  padding: 8px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;

const StyledH5 = styled.h5`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
`;

const StyledDiv = styled.div`
  cursor: pointer;
  right: 12px !important;
  top: 70% !important;

  i {
    cursor: pointer;
    z-index: 200;
    font-size: large;
    color: #6c757d;

    &:hover {
      color: #7f00ff;
    }
  }
`;

const UpdatePwdForm = ({ rotaPadUser }) => {
  const { user, createDoc, database, setRotaPadUser, auth } = useApiService();
  const [updatePassword, updating, error] = useUpdatePassword(auth);

  const [showNewPassword, setShowNewPassword] = useState({
    hide: true,
    info: 'Show password',
  });

  const [showConfirmPassword, setShowConfirmPassword] = useState({
    hide: true,
    info: 'Show password',
  });

  const { triggerToast } = useToast();

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return (
      isFormFieldValid(meta) && <small className='p-error'>{meta.error}</small>
    );
  };

  const validatePasswords = (data) => {
    let errors = {};

    if (!data.newPassword) {
      errors.newPassword = 'New password is required.';
    }

    if (!data.confirmPassword) {
      errors.confirmPassword = 'This field is required.';
    }

    if (data.newPassword && data.newPassword.length < 12) {
      errors.newPassword = 'New password should be 12 or more characters long.';
    }

    const {
      score = 0,
      feedback: { suggestions, warning },
    } = zxcvbn(data.newPassword || '');

    if (data.newPassword && data.newPassword.length >= 12 && score < 4) {
      errors.newPassword = warning || suggestions[0];
    }

    if (data.confirmPassword.length < 12) {
      errors.confirmPassword =
        'This password should be 12 or more characters long.';
    }

    if (
      data.confirmPassword.length >= 12 &&
      data.newPassword.length >= 12 &&
      data.newPassword !== data.confirmPassword
    ) {
      // errors.newPassword = "Password should match";
      errors.confirmPassword = 'Password should match';
    }

    return errors;
  };

  const handleSubmitUserDetails = useCallback(
    async (data, form) => {
      if (rotaPadUser && user && user.uid) {
        const success = await updatePassword(data.newPassword);

        if (success) {
          triggerToast({
            message: 'Password successfully updated!',
            type: TOAST_TYPE.success,
          });
        } else {
          triggerToast({
            message: 'Password could not be updated!',
            type: TOAST_TYPE.warning,
          });
        }

        form.reset();
        form.resetFieldState('newPassword');
        form.resetFieldState('confirmPassword');
      }
    },
    [rotaPadUser, triggerToast, updatePassword, user]
  );

  return (
    <div>
      <Form
        onSubmit={handleSubmitUserDetails}
        initialValues={{
          ...user,
          newPassword: '',
          confirmPassword: '',
        }}
        validate={validatePasswords}
        render={({
          handleSubmit,
          pristine,
          submitting,
          hasValidationErrors,
        }) => (
          <form
            onSubmit={handleSubmit}
            className='p-fluid'
          >
            <StyledH5 className='mb-3'>Change Your Password</StyledH5>
            <div className='row g-3'>
              <div className='col-xs-12 col-md-8 mb-2'>
                <Field
                  name='newPassword'
                  render={({ input, meta }) => (
                    <div className='field mb-4'>
                      <div className='block position-relative'>
                        <StyledLabel
                          htmlFor='firstname'
                          className={classNames({
                            'p-error': isFormFieldValid(meta),
                          })}
                        >
                          New password <span className='text-danger'>*</span>
                        </StyledLabel>
                        <StyledInput
                          id='newPassword'
                          autoComplete='new-password'
                          placeholder='Enter new password'
                          type={showNewPassword.hide ? 'password' : 'text'}
                          {...input}
                          className={classNames({
                            'p-invalid': isFormFieldValid(meta),
                          })}
                        />
                        <ToolTip
                          placement='top'
                          overlay={
                            <span
                              style={{
                                fontSize: 10,
                              }}
                            >
                              {showNewPassword.info}
                            </span>
                          }
                        >
                          <StyledDiv
                            className='position-absolute top-50 end-0 translate-middle-y'
                            onClick={() => {
                              setShowNewPassword({
                                hide: !showNewPassword.hide,
                                info: showNewPassword.hide
                                  ? 'Hide password'
                                  : 'Show password',
                              });
                            }}
                          >
                            {showNewPassword.hide ? (
                              <AiOutlineEyeInvisible />
                            ) : (
                              <AiOutlineEye />
                            )}
                          </StyledDiv>
                        </ToolTip>
                      </div>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />

                <Field
                  name='confirmPassword'
                  render={({ input, meta }) => (
                    <div className='field'>
                      <div className='block position-relative'>
                        <StyledLabel
                          htmlFor='confirmPassword'
                          className={classNames({
                            'p-error': isFormFieldValid(meta),
                          })}
                        >
                          Confirm new password{' '}
                          <span className='text-danger'>*</span>
                        </StyledLabel>
                        <StyledInput
                          id='confirmPassword'
                          autoComplete='confirm-password'
                          type={showConfirmPassword.hide ? 'password' : 'text'}
                          {...input}
                          className={classNames({
                            'p-invalid': isFormFieldValid(meta),
                          })}
                          placeholder='Confirm new password'
                        />
                        <StyledDiv
                          className='position-absolute top-50 end-0 translate-middle-y'
                          onClick={() => {
                            setShowConfirmPassword({
                              hide: !showConfirmPassword.hide,
                              info: showConfirmPassword.hide
                                ? 'Hide password'
                                : 'Show password',
                            });
                          }}
                        >
                          {setShowConfirmPassword.hide ? (
                            <AiOutlineEyeInvisible />
                          ) : (
                            <AiOutlineEye />
                          )}
                        </StyledDiv>
                      </div>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
              </div>
            </div>

            <div className='row g-3 mt-2'>
              <div className='col-xs-12 col-md-8'>
                <ButtonBase
                  type='submit'
                  label='Update Account Settings'
                  variant='primary'
                  size='large'
                  // type='submit'
                  // label='Update Password'
                  // className='mt-2'
                  disabled={
                    pristine || submitting || hasValidationErrors || updating
                  }
                >
                  Update Account Settings
                </ButtonBase>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default UpdatePwdForm;
