import styled from '@emotion/styled';
import { BiGridAlt, BiIdCard, BiMap, BiPlus, BiTable } from 'react-icons/bi';
import { Badge, ToolTip } from '..';
import ButtonBase, { BTN_VARIANTS } from '../ButtonBase';
import { useApiService } from '../../context';
import { useState } from 'react';
import { SelectButton } from 'primereact/selectbutton';

const StyledCard = styled.div`
  cursor: pointer;
  width: 12rem;
  font-size: 12px;
  padding-top: 20px;
  min-height: 175px;
  border: #af97d8 solid 3px;
  img {
    width: 70px;
  }

  div > svg {
    font-size: 34px;
    color: #af97d8;
  }
  &:hover {
    border: #b2d897 solid 3px;

    div > svg {
      color: #b2d897;
    }
  }
`;
const StyleViewBtn = styled.button`
  line-height: 14px;
  text-transform: uppercase;
  font-size: 11px !important;
`;
const StyledInput = styled.input`
  font-size: 12px !important;
  line-height: 15px;
`;
const StyledInfoDiv = styled.div`
  // cursor: pointer;
  font-size: 12px;
  background: #f3fbf5;
  &:hover {
    background: #fff;
  }
`;

const StyledTemplatesDiv = styled.div`
  background: #f5ebff;
  border-radius: 13px;
`;
const StyleNamesSpan = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: #555;
`;

const StyledHeaders = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #444;
  letter-spacing: 0.0543215rem;
`;

const StyledViewTypeDiv = styled.div`
  margin-top: 1px;
`;
const PageTitleBar = ({
  title,
  count,
  badgeStyle,
  handleClickNext,
  handleClickPrev,
  handleClickAdd,
  isCardView,
  setIsCardView,
}) => {
  // isRotaPadUserAdmin
  // const [value, setValue] = useState(true);

  const { isRotaPadUserAdmin } = useApiService();

  return (
    <div className='d-flex justify-content-between align-items-center mt-0 pt-0 mb-3'>
      <StyledHeaders>
        {title}
        {count && (
          <Badge
            value={count}
            variant={BTN_VARIANTS.primary}
          ></Badge>
        )}
      </StyledHeaders>
      <div>
        <div className='d-flex flex-row'>
          {/* <div className='btn-group input-group-sm ms-2 align-self-center'>
            <StyledInput
              type='text'
              className='form-control btn-sm'
              aria-label='Text input with dropdown button'
              placeholder={`Search ${title}`}
            />
          </div>
          <div className='btn-group input-group-sm ms-2 align-self-center'>
            <StyleViewBtn
              type='button'
              className='btn btn-sm btn-outline-secondary'
            >
              Filter
              <BiIdCard />
            </StyleViewBtn>
          </div> */}

          <div className='btn-toolbar my-2 my-sm-0 my-md-0'>
            <div className='d-flex flex-row mb-0'>
              {setIsCardView && (
                <StyledViewTypeDiv className='d-flex justify-content-center'>
                  <div className='me-1'>
                    <ToolTip
                      placement='top'
                      overlay={
                        <span
                          style={{
                            fontSize: 10,
                          }}
                        >
                          Card view
                        </span>
                      }
                    >
                      <ButtonBase
                        type='button'
                        variant='primary'
                        size='small'
                        onClick={() => setIsCardView(true)}
                        disabled={isCardView}
                      >
                        <BiGridAlt style={{ fontSize: '15px' }} />
                      </ButtonBase>
                    </ToolTip>
                  </div>
                  <div>
                    <ToolTip
                      placement='top'
                      overlay={
                        <span
                          style={{
                            fontSize: 10,
                          }}
                        >
                          Table view
                        </span>
                      }
                    >
                      <ButtonBase
                        type='submit'
                        variant='primary'
                        size='small'
                        onClick={() => setIsCardView(false)}
                        disabled={!isCardView}
                      >
                        <BiTable style={{ fontSize: '15px' }} />
                      </ButtonBase>
                    </ToolTip>
                  </div>
                </StyledViewTypeDiv>
              )}

              {handleClickPrev && (
                <div className='ms-2 me-1'>
                  <ButtonBase
                    type='button'
                    variant='primary'
                    size='small'
                    onClick={handleClickPrev}
                  >
                    Prev
                  </ButtonBase>
                </div>
              )}

              {handleClickNext && (
                <div>
                  <ButtonBase
                    type='submit'
                    variant='primary'
                    size='small'
                    onClick={handleClickNext}
                    // disabled={pristine || submitting || hasValidationErrors}
                  >
                    Next
                  </ButtonBase>
                </div>
              )}
              {handleClickAdd &&
                isRotaPadUserAdmin !== undefined &&
                isRotaPadUserAdmin && (
                  <div className='ms-2'>
                    <ButtonBase
                      type='button'
                      variant='outline'
                      size='small'
                      onClick={handleClickAdd}
                    >
                      <BiPlus /> {` Add New `}
                    </ButtonBase>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitleBar;
