import React, { useState, useCallback, useMemo } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import _ from 'lodash';

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import moment from 'moment';
import { useApiService } from '../../context';
import DownloadReport from './DownloadReport';
import { Pill } from '../../common';
import { BADGE_STYLE } from '../../common/constants';
import { INVOICE_REPORT_TYPE } from '..';
// import { formatCurrency, formatHour } from '../../../../common/utils';

// import { StyledInput } from 'authenticated-app/components/customers';
// import { StyledInput } from 'authenticated-app/components/team';
// import { CustomerService } from './service/CustomerService';

export default function SavedReports({ tableData }) {
  // console.log('tableData ==== ', tableData);
  const { user, storage, fetchProfileImageData, setProfileImage } =
    useApiService();
  // const { shifts } = tableData || {};
  // const [customers, setCustomers] = useState(tableData);

  // const { year, yearRota } = item || {};
  // const { customers: customersCollection, users } = useAuth();

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    // // 'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    // representative: { value: null, matchMode: FilterMatchMode.IN },
    // status: { value: null, matchMode: FilterMatchMode.EQUALS },
    // verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [loading, setLoading] = useState(false);

  const footerGroup = useMemo(() => {
    return (
      <ColumnGroup>
        <Row>
          <Column
            footer='Totals:'
            colSpan={4}
            footerStyle={{ textAlign: 'right' }}
          />
          {/* <Column footer={formatHour(0)} /> */}
          <Column footer='12' />
        </Row>
      </ColumnGroup>
    );
  }, [tableData]);

  const startTimeBodyTemplate = (rowData) => {
    const startTime = rowData.createdAt;

    return <div>{moment(startTime).format('MMMM Do YYYY, h:mm:ss a')}</div>;
  };

  const totalBodyTemplate = (rowData) => {
    const { filename, uid } = rowData;

    // const getUrl = await fetchProfileImageData({
    //   path: '/' + filename,
    // });

    return (
      <DownloadReport
        url={filename}
        uid={uid}
      />
    );
  };
  const typeBodyTemplate = (rowData) => {
    const reportType = rowData.type;

    return (
      <Pill
        content={reportType}
        bgStyle={{
          ...(INVOICE_REPORT_TYPE === reportType
            ? BADGE_STYLE.Customer
            : BADGE_STYLE.Admin),
        }}
        toolTipMessage={reportType}
      />
    );
  };

  return (
    <div>
      {/* <div onClick={() => handleOpenSidebar(true)}>invoice preview</div> */}
      <DataTable
        value={tableData}
        className='customTable'
        // paginator
        // rows={10}
        dataKey='uid'
        filters={filters}
        // filterDisplay='row'
        loading={loading}
        globalFilterFields={['start']}
        // footerColumnGroup={footerGroup}
        // header={header}
        emptyMessage='No reports found.'
        scrollable
        scrollHeight='100vh'
      >
        <Column
          field='createdAt'
          header='Date'
          sortable
          style={{ minWidth: '8rem' }}
          body={startTimeBodyTemplate}
        />
        <Column
          header='Type'
          field='type'
          style={{ minWidth: '8rem' }}
          body={typeBodyTemplate}
          // sortable
          // filter
          // filterElement={countryFilter}
        />
        <Column
          header='Name'
          field='filename'
          // showFilterMenu={false}
          // filterMenuStyle={{ width: '14rem' }}
          style={{ minWidth: '14rem' }}
          // body={representativeBodyTemplate}
          // filter
          // filterElement={representativeRowFilterTemplate}
        />
        <Column
          field='filename'
          header='Download'
          showFilterMenu={false}
          filterMenuStyle={{ width: '14rem' }}
          style={{ minWidth: '12rem' }}
          body={totalBodyTemplate}
          // sortable
          // filter
          // filterElement={statusRowFilterTemplate}
        />
      </DataTable>
    </div>
  );
}
