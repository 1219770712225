import { useApiService } from '../../../context';
import { useEffect, useState } from 'react';

const DownloadReport = ({ url, uid }) => {
  // console.log('url ==== ', url);
  // const { storage } = useApiService();
  // const [userImg] = useDownloadURL(
  //   storageRef(storage, '/' + uid + '/file.png')
  // );
  const [reportUrl, setReportUrl] = useState();
  const { user, storage, fetchProfileImageData, setProfileImage } =
    useApiService();
  useEffect(() => {
    const fetchUrl = async () => {
      const getUrl = await fetchProfileImageData({
        path: '/' + uid + '/' + url,
      });
      // console.log('getUrl ==== ', getUrl);
      setReportUrl(getUrl);
    };
    if (url) {
      fetchUrl();
    }
  }, [url, uid]);
  return reportUrl ? (
    <a
      href={reportUrl}
      target='_blank'
    >
      Download
    </a>
  ) : (
    <>Loading...</>
  );
};

export default DownloadReport;
