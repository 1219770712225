import styled from "@emotion/styled";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import Map, {
	FullscreenControl,
	GeolocateControl,
	Marker,
	Popup,
} from "react-map-gl";
import CustomMArker from "../../../common/img/custom_marker.png";
import GeocoderControl from "./GeoCoder";

const StyledLabel = styled.label`
	font-size: 12px;
	display: inline-block;
	font-weight: 500;
	margin-top: 8px;
`;

const StyledInput = styled.input`
	padding: 8px 8px;
	font-size: 12px;
	width: 100% !important;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
		Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	color: #495057;
	background: #ffffff;
	border: 1px solid #ced4da;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s,
		box-shadow 0.2s;
	appearance: none;
	border-radius: 6px;
	margin: 0;

	&:hover {
		border-color: #6366f1;
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem #c7d2fe;
		border-color: #6366f1;
	}
	&:disabled {
		background-color: #e9ecef;
		opacity: 0.6;
		&:hover {
			border-color: #ced4da;
		}
	}
`;

const StyledH5 = styled.h5`
	// border-width: 0 0 0 2px;
	// border-style: solid;
	// padding-left: 12px;
	// border-color: #6554c0;
	color: #6554c0;
	font-size: 18px;
	padding-top: 0px;
`;

const TeamMemberAddress = ({
	input: { onChange, value },
	label,
	visibleLeft,
	disabled,
	height,
	...rest
}) => {
	const [showPopup, setShowPopup] = React.useState(false);
	const [viewState, setViewState] = React.useState({
		longitude: -100,
		latitude: 40,
		zoom: 3.5,
	});
	return (
		<div className=''>
			<Map
				{...viewState}
				onMove={(evt) => setViewState(evt.viewState)}
				style={{
					width: "100%",
					height: height ? height : "100vh",
					borderRadius: "8px",
					border: "1px solid #ced4da",
				}}
				mapStyle='mapbox://styles/mapbox/outdoors-v12'
				mapboxAccessToken='pk.eyJ1Ijoia2NpamV6aWUiLCJhIjoiY2xoZ3R3eHQ0MGhlcTNrbXFvNnE2eXQ3eCJ9.zuptJ9FK3rYmkeRP3HVkvg'>
				<Marker longitude={-100} latitude={40} anchor='bottom'>
					<img src={CustomMArker} alt='Custom Marker' />
				</Marker>
				<FullscreenControl />
				<GeolocateControl />

				<GeocoderControl
					mapboxAccessToken='pk.eyJ1Ijoia2NpamV6aWUiLCJhIjoiY2xoZ3R3eHQ0MGhlcTNrbXFvNnE2eXQ3eCJ9.zuptJ9FK3rYmkeRP3HVkvg'
					position='top-left'
				/>
				{showPopup && (
					<Popup
						longitude={-100}
						latitude={40}
						anchor='bottom'
						onClose={() => setShowPopup(false)}>
						You are here123
					</Popup>
				)}
			</Map>
		</div>
	);
};

export default TeamMemberAddress;
