import { StyledDropDownDiv } from 'components/absences/AddAbsencesForm';
import { StyledLabel } from 'components/workspace/AddWorkSpaceForm/styles';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';

const Timer = ({
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  hours,
  setHours,
  setSleepHours,
  sleepHours,
  disabled = false,
}) => {
  return (
    <div className='mt-3'>
      <div className='d-flex flex-column mb-3 mt-2 '>
        <div className='d-flex flex-row'>
          <div className='flex-fill me-3'>
            <StyledLabel
              htmlFor='status'
              className={classNames({
                'p-error': false,
              })}
            >
              Start time
            </StyledLabel>
            <StyledDropDownDiv>
              <Calendar
                id='calendar-timeonly'
                value={startTime}
                onChange={(e) => {
                  setHours(null);
                  setStartTime(e.value);
                }}
                timeOnly
                hourFormat='12'
                disabled={disabled}
                className='w-100'
              />
            </StyledDropDownDiv>
          </div>
          <div className='flex-fill '>
            <StyledLabel
              htmlFor='status'
              className={classNames({
                'p-error': false,
              })}
            >
              End time
            </StyledLabel>
            <StyledDropDownDiv>
              <Calendar
                id='calendar-timeonly'
                value={endTime}
                onChange={(e) => {
                  // setHours(null);
                  setEndTime(e.value);
                  if (startTime && e.value) {
                    const duration = moment.duration(
                      moment(e.value).diff(startTime)
                    );
                    const hours = duration.asHours();
                    setHours(hours);
                  }
                }}
                timeOnly
                hourFormat='12'
                disabled={disabled}
                className='w-100'
              />
            </StyledDropDownDiv>
          </div>
        </div>

        <div className='flex-fill mt-2'>
          <StyledLabel
            htmlFor='status'
            className={classNames({
              'p-error': false,
            })}
          >
            Hours
          </StyledLabel>
          <StyledDropDownDiv>
            <InputNumber
              inputId='minmaxfraction'
              value={hours}
              onValueChange={(e) => {
                setStartTime(null);
                setEndTime(null);
                setHours(e.value);
              }}
              minFractionDigits={2}
              maxFractionDigits={2}
              className='w-100'
              disabled
            />
          </StyledDropDownDiv>
        </div>
      </div>
      <div className='d-flex flex-row mt-0'>
        <div className='flex-fill'>
          <StyledLabel
            htmlFor='status'
            className={classNames({
              'p-error': false,
            })}
          >
            Sleep
          </StyledLabel>
          <StyledDropDownDiv>
            <InputNumber
              inputId='minmaxfraction2'
              value={sleepHours}
              onValueChange={(e) => {
                setSleepHours(e.value);
              }}
              minFractionDigits={2}
              maxFractionDigits={2}
              className='w-100'
              disabled={disabled}
            />
          </StyledDropDownDiv>
        </div>
      </div>
    </div>
  );
};

export default Timer;
