import { ToolTip } from "..";
const Pill = ({ toolTipMessage, bgStyle, content }) => {
	return (
		<ToolTip
			placement='top'
			overlayClassName='tooltipCustom'
			overlay={
				<span
					style={{
						fontSize: 10,
					}}>
					{toolTipMessage}
				</span>
			}>
			<button
				type='button'
				style={{
					...bgStyle,
					color: "#fff",
					fontSize: "10px",
					height: "20px",
					border: "none",
					borderRadius: "10px",
					lineHeight: "19px",
					cursor: "auto",
					marginRight: "4px",
				}}>
				{content}
			</button>
		</ToolTip>
	);
};

export default Pill;
