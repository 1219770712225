import React, { useMemo } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from '@react-pdf/renderer';
import Logo from './xynexcare_logo.jpeg';
import InvoiceTitle from './components/InvoiceHeading';
import InvoiceNo from './components/InvoiceNo';
import BillTo from './components/BillTo';
import InvoiceItemsTable from './components/InvoiceItemsTable';
import InvoiceThankYouMsg from './components/InvoiceThankYouMsg';
import moment from 'moment';

import _ from 'lodash';

// Create styles
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
  body: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  weekEndingText: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
    borderWidth: 1,
    borderColor: 'black',
    backgroundColor: '#d6d6d6',
    padding: '4px',
    width: '95px',
  },
  image: {
    position: 'absolute',
    left: '40px',
    top: '25px',
    width: '85px',
    height: '70px',
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});
const invoiceValues = {
  id: '5df3180a09ea16dc4b95f910',
  invoice_no: '201906-28',
  balance: '$2,283.74',
  company: 'MANTRIX',
  email: 'susanafuentes@mantrix.com',
  phone: '+1 (872) 588-3809',
  address: '922 Campus Road, Drytown, Wisconsin, 1986',
  trans_date: '2019-09-12',
  due_date: '2019-10-12',
  items: [
    {
      sno: 1,
      desc: 'ad sunt culpa occaecat qui',
      qty: 5,
      rate: 405.89,
      payRate: 1,
      hours: 2,
    },
    {
      sno: 2,
      desc: 'cillum quis sunt qui aute',
      qty: 5,
      rate: 373.11,
      payRate: 1,
      hours: 2,
    },
    {
      sno: 3,
      desc: 'ea commodo labore culpa irure',
      qty: 5,
      rate: 458.61,
      payRate: 1,
      hours: 2,
    },
    {
      sno: 4,
      desc: 'nisi consequat et adipisicing dolor',
      qty: 10,
      rate: 725.24,
      payRate: 1,
      hours: 2,
    },
    {
      sno: 5,
      desc: 'proident cillum anim elit esse',
      qty: 4,
      rate: 141.02,
      payRate: 1,
      hours: 2,
    },
  ],
};
// Create Document Component
const CustomerInvoice = ({ payload, customers }) => {
  console.log('payload ===== ', payload);
  // const { customers } = useAuth();
  const { reportType } = payload;
  // console.log('customers ==== ', customers);

  return (
    <Document
      pageLayout='singlePage'
      pageMode='fullScreen'
    >
      <Page
        size='A4'
        style={styles.body}
      >
        <Image
          style={styles.logo}
          src={Logo}
        />
        <InvoiceTitle title={reportType} />
        <InvoiceNo
          invoice={invoiceValues}
          reportType={reportType}
        />
        <BillTo
          invoice={invoiceValues}
          reportType={reportType}
        />
        <InvoiceItemsTable invoice={invoiceValues} />
        <InvoiceThankYouMsg reportType={reportType} />
        {/* <Image
          style={styles.image}
          src={Logo}
          fixed
        /> */}
        {/* <Text
          style={styles.header}
          fixed
        >
          ~ Weekly Time Sheet ~
        </Text>
        <Text style={styles.title}>Weekly Timesheet</Text>
        <Text style={styles.author}>Bode Green</Text> */}

        {/* <View>
          <Text style={styles.weekEndingText}>Week Ending</Text>
          <Text style={styles.text}>
            En un lugar de la Mancha, de cuyo nombre no quiero acordarme, no ha
            mucho tiempo que vivía un hidalgo de los de lanza en astillero, adarga
            antigua, rocín flaco y galgo corredor. Una olla de algo más vaca que
            carnero, salpicón las más noches, duelos y quebrantos los sábados,
            lentejas los viernes, algún palomino de añadidura los domingos,
            consumían las tres partes de su hacienda. El resto della concluían
            sayo de velarte, calzas de velludo para las fiestas con sus pantuflos
            de lo mismo, los días de entre semana se honraba con su vellori de lo
            más fino. Tenía en su casa una ama que pasaba de los cuarenta, y una
            sobrina que no llegaba a los veinte, y un mozo de campo y plaza, que
            así ensillaba el rocín como tomaba la podadera. Frisaba la edad de
            nuestro hidalgo con los cincuenta años, era de complexión recia, seco
            de carnes, enjuto de rostro; gran madrugador y amigo de la caza.
            Quieren decir que tenía el sobrenombre de Quijada o Quesada (que en
            esto hay alguna diferencia en los autores que deste caso escriben),
            aunque por conjeturas verosímiles se deja entender que se llama
            Quijana; pero esto importa poco a nuestro cuento; basta que en la
            narración dél no se salga un punto de la verdad
          </Text>
        </View> */}
      </Page>
    </Document>
  );
};

export default CustomerInvoice;
