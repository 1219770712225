import { createContext } from 'react';

export const ReportContext = createContext({
  item: {},
  handleOpenSidebar: () => console.log('x'),
  date: [],
  setDate: () => console.log('x'),
  // previewType: '',
  // setPreviewType: () => console.log('x'),
  // customerName: '',
  // unitName: '',
  // setCustomerName: () => console.log('x'),
  // setUnitName: () => console.log('x'),
  // reportMonth: '',
  // setFilteredShifts: () => console.log('x'),
  // filteredShifts: [],
  // customerId: '',
  // setCustomerId: () => console.log('x'),
  unitId: '',
  setUnitId: (x) => console.log('x'),
  hideHeaders: false,
  setHideHeaders: (x) => console.log('x'),
  setPdfInvoicePayload: () => console.log('x'),
  expandAllUnits: false,
  // teamMemberEmail: '',
  setExpandAllUnits: () => console.log('x'),
  // isMyReport: false,
  // teamMemberName: '',
  // setTeamMemberName: () => console.log('x'),
});
