import { useState } from 'react';
import { ReportContext } from '../ReportContext';
// import Header from './Header';
// import Details from './Details';
import moment from 'moment';
import Summary from './Summary';
import { useToggle } from 'react-use';
// import ReportCards from '../reportCards';
// import { REPORTS_CUSTOMERS } from 'authenticated-app/components/common/constants';

const TeamMember = ({ item, handleOpenSidebar, setPdfInvoicePayload }) => {
  // const [previewType, setPreviewType] = useState(REPORTS_CUSTOMERS);
  const [customerName, setCustomerName] = useState('');
  const [unitName, setUnitName] = useState('');
  const [reportMonth, setReportMonth] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [unitId, setUnitId] = useState();

  //Team Members
  const [teamMemberEmail, setTeamMemberEmail] = useState('');
  const [teamMemberName, setTeamMemberName] = useState('');

  const [date, setDate] = useState([
    new Date(moment().startOf('month').format()),
    new Date(moment().format()),
  ]);

  const [expandAllUnits, setExpandAllUnits] = useState(false);
  const [hideHeaders, setHideHeaders] = useToggle(true);

  return (
    <div>
      <ReportContext.Provider
        value={{
          // previewType,
          // setPreviewType,
          item,
          handleOpenSidebar,
          setPdfInvoicePayload,
          // customerName,
          // setCustomerName,
          // unitName,
          // setUnitName,
          // reportMonth,
          // setReportMonth,
          // customerId,
          // setCustomerId,
          // unitId,
          // setUnitId,
          // setPdfInvoicePayload,
          // teamMemberEmail,
          // setTeamMemberEmail,
          // teamMemberName,
          // setTeamMemberName,
          date,
          setDate,
          expandAllUnits,
          setExpandAllUnits,
          hideHeaders,
          setHideHeaders,
          unitId,
          setUnitId,
        }}
      >
        {/* <Header /> */}
        <Summary />
        {/* <Details /> */}
      </ReportContext.Provider>
    </div>
  );
};

export default TeamMember;
