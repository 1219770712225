import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import { BiUser } from 'react-icons/bi';
import { useApiService } from '../context';
import { PageTitleBar } from '../common';
import _ from 'lodash';
import { BADGE_STYLE } from '../common/constants';
import CustomersContainer from './CustomersContainer';
import PDFContainer from './PDFContainer';
import TeamMembersContainer from './TeamMembersContainer';
import SavedReports from './SavedReports';
import { convertObjectToArray } from 'components/common/utils';

const StyledTabHeader = styled.span`
  cursor: pointer;
  font-size: 13px;
  &:hover {
    background: #fff;
  }
`;

const StyledInfoDiv = styled.div`
  // cursor: pointer;
  font-size: 12px;
  background: #f3fbf5;
  &:hover {
    background: #fff;
  }
`;
export const INVOICE_REPORT_TYPE = 'Invoice';

export const WORKSHEET_REPORT_TYPE = 'Worksheet';
export const REPORTS_TYPES = {
  Customers: 'Customers',
  TeamMembers: 'Team Members',
  Shifts: 'Shifts',
};
const Reports = () => {
  const { rotaPadUser, rotaPadPrinterTopic } = useApiService();
  const [active, setActive] = useState(0);
  const [pdfInvoicePayload, setPdfInvoicePayload] = useState({});
  const [visibleLeft, setVisibleLeft] = useState(false);

  const newPrinterArray = useMemo(() => {
    return _.isObject(rotaPadPrinterTopic) &&
      Object.keys(rotaPadPrinterTopic).length > 0
      ? convertObjectToArray({ item: rotaPadPrinterTopic })
      : [];
  }, [rotaPadPrinterTopic]);

  const displayPdfView = useMemo(() => {
    return (
      <PDFContainer
        visibleLeft={visibleLeft}
        setVisibleLeft={setVisibleLeft}
        pdfInvoicePayload={pdfInvoicePayload}
        setPdfInvoicePayload={setPdfInvoicePayload}
      />
    );
  }, [pdfInvoicePayload, visibleLeft]);
  if (!rotaPadUser) return null;

  // console.log('rotaPadPrinterTopic ===== ', rotaPadPrinterTopic);
  return (
    <div className='container-fluid mx-0 px-0'>
      {displayPdfView}
      <div className='row'>
        <div className='col-12 mt-0'>
          <PageTitleBar
            title='Reports'
            // count={'0'}
            // badgeStyle={BADGE_STYLE.Menu}
          />
          <div className='mt-4 pt-1'>
            <ul
              className='nav nav-tabs mt-34'
              style={{ borderBottom: 'none' }}
            >
              <li
                className='nav-item'
                onClick={() => setActive(0)}
              >
                <StyledTabHeader
                  className={`nav-link ${active === 0 ? 'active' : ''}`}
                >
                  {REPORTS_TYPES.Customers}
                </StyledTabHeader>
              </li>
              <li
                className='nav-item'
                onClick={() => setActive(1)}
              >
                <StyledTabHeader
                  className={`nav-link ${active === 1 ? 'active' : ''}`}
                >
                  {REPORTS_TYPES.TeamMembers}
                </StyledTabHeader>
              </li>
              <li
                className='nav-item'
                onClick={() => setActive(2)}
              >
                <StyledTabHeader
                  className={`nav-link ${active === 2 ? 'active' : ''}`}
                >
                  Saved Reports
                </StyledTabHeader>
              </li>
            </ul>
            <div
              className='py-3 px-3 border border-top rounded-bottom rounded-end rounded-end mb-4'
              style={{ background: '#fff' }}
            >
              {active === 0 && (
                <>
                  <CustomersContainer
                    handleOpenSidebar={setVisibleLeft}
                    setPdfInvoicePayload={setPdfInvoicePayload}
                  />
                </>
              )}
              {active === 1 && (
                <TeamMembersContainer
                  handleOpenSidebar={setVisibleLeft}
                  setPdfInvoicePayload={setPdfInvoicePayload}
                />
              )}
              {active === 2 && <SavedReports tableData={newPrinterArray} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
