import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    backgroundColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  description: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  qty: {
    width: '40%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  rate: {
    width: '35%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  amount: {
    width: '15%',
  },
});

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.description}>Date</Text>
    <Text style={styles.qty}>Customer</Text>
    <Text style={styles.rate}>Staff Name</Text>
    <Text style={styles.amount}>Rate</Text>

    <Text style={styles.amount}>Hours</Text>

    <Text style={styles.amount}>Total</Text>
  </View>
);

export default InvoiceTableHeader;
