import styled from "@emotion/styled";
import { BTN_VARIANTS } from "../ButtonBase";

const BadgeStyle = styled.span`
	background: ${(props) =>
		props.variant === BTN_VARIANTS.primary ? props.theme[`primary-300`] : ""};
`;

const Badge = ({ value, styles, variant }) => {
	return (
		<BadgeStyle variant={variant} className='badge rounded-pill' style={styles}>
			{value || 0}
		</BadgeStyle>
	);
};

export default Badge;

// const Badge = ({ value, styles }) => {
// 	return (
// 		<span className='badge rounded-pill' style={styles}>
// 			{value || 0}
// 		</span>
// 	);
// };

// export default Badge;
