import styled from '@emotion/styled';
import { ReportContext } from '../../../reports/ReportContext';
import { useCallback, useContext } from 'react';
import { WORKSHEET_REPORT_TYPE } from '../../../reports';
import moment from 'moment';
import { getUnitName } from 'components/common/utils';
import { useMemo } from 'react';
import { CustomerSelector } from '../Summary';
import { BsFilePdf, BsFiletypePdf } from 'react-icons/bs';

const CustomerNameDivStyled = styled.div`
  font-size: 12px;
  // font-weight: 600;
  font-weight: 400;
  color: #8f8f8f;
  // color: ${(props) => props.theme.primary};
  margin-bottom: 4px;
`;

const CustomerIdStyledDIv = styled.div`
  font-size: 0.65rem;
  font-weight: 600;
  // color: #9b9b9b;
`;

const ViewInvoiceStyledDIv = styled.div`
  font-size: 0.65rem;
  font-weight: 600;
  color: ${(props) => (props.onClick ? props.theme.primary : props.theme.dark)};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'not-allowed')};

  &:hover {
    color: ${(props) => props.theme[`primary-400`]};
  }
`;

const BilledToStyledDIv = styled.div`
  font-size: 0.675rem;
  font-weight: 700;
  color: #7c7c7c;
`;

const CustomerAddressStyledDiv = styled.div`
  width: 120px;
  font-size: 0.675rem;
  color: #7c7c7c;
  line-height: 14px;
  padding-top: 4px;
`;

const HeaderContainerStyledDiv = styled.div`
  padding: 0 8px 0px 8px;
`;

const Header = ({ computedShifts, customers, primary, value, onChange }) => {
  const {
    item,
    handleOpenSidebar,
    setPdfInvoicePayload,
    hideHeaders,
    date,
    unitId,
  } = useContext(ReportContext);

  // const { name, customerId, address } = item;

  const handleClickPreview = useCallback(() => {
    const getUnitDetails = getUnitName({ unitId, customers });
    // console.log('getUnitDetails ===== ', getUnitDetails);
    setPdfInvoicePayload({
      computedShifts,
      reportType: WORKSHEET_REPORT_TYPE,
      teamMembers: item,
      date,
      unitId,
      ...getUnitDetails,
    });
    handleOpenSidebar(true);
  }, [computedShifts, item, unitId, customers]);

  // console.log(`Difference is ${dateB.diff(dateC, 'days')}

  const numOfDays = useMemo(() => {
    const [start, end] = date;
    const numberOfDays =
      moment(end).startOf('day').diff(moment(start).startOf('day'), 'days') + 1;
    return numberOfDays ? numberOfDays : 0;
  }, [date]);

  // .toFixed(2);
  // console.log('numOfDays ===== ', numOfDays);
  return (
    <HeaderContainerStyledDiv
      style={{ display: hideHeaders ? 'inherit' : 'none' }}
    >
      <div className='d-flex flex-column mt-4 mb-4'>
        <CustomerNameDivStyled>Customer</CustomerNameDivStyled>
        <CustomerIdStyledDIv>
          <CustomerSelector
            customers={customers}
            primary={primary}
            value={value}
            onChange={onChange}
          />
        </CustomerIdStyledDIv>
      </div>

      <div className='d-flex justify-content-end mt-3'>
        <div className='d-flex flex-column text-end'>
          <ViewInvoiceStyledDIv
            {...(computedShifts &&
            computedShifts.length > 0 &&
            unitId &&
            date[0] &&
            date[1] &&
            numOfDays > 0 &&
            numOfDays < 8
              ? { onClick: handleClickPreview }
              : {})}
          >
            <BsFilePdf
              className='me-1'
              style={{ marginTop: '-3px' }}
            />
            View Worksheet
          </ViewInvoiceStyledDIv>
          {date[0] && date[1] && numOfDays > 0 && numOfDays < 8 && (
            <div>
              {`${moment(date[0]).format('DD/MM/YYYY')}/${moment(
                date[1]
              ).format('DD/MM/YYYY')}`.replace(/\//g, '')}
            </div>
          )}
        </div>
      </div>
    </HeaderContainerStyledDiv>
  );
};
export default Header;
