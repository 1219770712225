import styled from '@emotion/styled';
import { ReportContext } from '../../../reports/ReportContext';
import { useCallback, useContext } from 'react';
import { INVOICE_REPORT_TYPE } from 'components/reports';
import moment from 'moment';
import { BsFilePdf } from 'react-icons/bs';

export const CustomerNameDivStyled = styled.div`
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: ${(props) =>
    props.active !== undefined && props.active
      ? props.theme.dark
      : props.theme.primary};
`;

export const CustomerIdStyledDIv = styled.div`
  font-size: 0.65rem;
  font-weight: 600;
  color: #9b9b9b;
`;

const ViewInvoiceStyledDIv = styled.div`
  font-size: 0.65rem;
  font-weight: 600;
  color: ${(props) => (props.onClick ? props.theme.primary : props.theme.dark)};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'not-allowed')};

  &:hover {
    color: ${(props) => props.theme[`primary-400`]};
  }
`;

const BilledToStyledDIv = styled.div`
  font-size: 0.675rem;
  font-weight: 700;
  color: #7c7c7c;
`;

const CustomerAddressStyledDiv = styled.div`
  width: 120px;
  font-size: 0.675rem;
  color: #7c7c7c;
  line-height: 14px;
  padding-top: 4px;
`;

const HeaderContainerStyledDiv = styled.div`
  padding: 0 8px 0px 8px;
`;

const Header = ({ computedShifts }) => {
  const { item, handleOpenSidebar, setPdfInvoicePayload, hideHeaders, date } =
    useContext(ReportContext);

  const { name, customerId, address } = item;
  const handleClickPreview = useCallback(() => {
    setPdfInvoicePayload({
      computedShifts,
      reportType: INVOICE_REPORT_TYPE,
      customer: item,
      date,
    });
    handleOpenSidebar(true);
  }, [computedShifts]);
  return (
    <HeaderContainerStyledDiv
      style={{ display: hideHeaders ? 'inherit' : 'none' }}
    >
      <div className='d-flex flex-column mt-0 mb-4'>
        <CustomerNameDivStyled>{name}</CustomerNameDivStyled>
        <CustomerIdStyledDIv>{customerId}</CustomerIdStyledDIv>
      </div>

      <div className='d-flex justify-content-between'>
        <div className='d-flex flex-column'>
          <BilledToStyledDIv>Billed To</BilledToStyledDIv>
          <CustomerAddressStyledDiv>{address}</CustomerAddressStyledDiv>
        </div>

        <div className='d-flex flex-column text-end'>
          <ViewInvoiceStyledDIv
            {...(computedShifts &&
            computedShifts.length > 0 &&
            date[0] &&
            date[1]
              ? { onClick: handleClickPreview }
              : {})}
          >
            <BsFilePdf
              className='me-1'
              style={{ marginTop: '-3px' }}
            />
            View Invoice
          </ViewInvoiceStyledDIv>
          {date[0] && date[1] && (
            <div>
              {`${moment(date[0]).format('DD/MM/YYYY')}/${moment(
                date[1]
              ).format('DD/MM/YYYY')}`.replace(/\//g, '')}
            </div>
          )}
        </div>
      </div>
    </HeaderContainerStyledDiv>
  );
};
export default Header;
