import React, { useState, useCallback, useMemo } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import _ from 'lodash';

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import moment from 'moment';
import { formatCurrency, formatHour } from '../../../../common/utils';

// import { StyledInput } from 'authenticated-app/components/customers';
// import { StyledInput } from 'authenticated-app/components/team';
// import { CustomerService } from './service/CustomerService';

export default function CustomerInvoiceTable({
  tableData,
  reportType,
  payRate,
  unitName,
  sleepRate,
}) {
  // console.log('tableData ==== ', tableData);
  // const { shifts } = tableData || {};
  // const [customers, setCustomers] = useState(tableData);

  // const { year, yearRota } = item || {};
  // const { customers: customersCollection, users } = useAuth();

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    // // 'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    // representative: { value: null, matchMode: FilterMatchMode.IN },
    // status: { value: null, matchMode: FilterMatchMode.EQUALS },
    // verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [loading, setLoading] = useState(false);

  const countryBodyTemplate = useCallback(() => {
    return <div>{unitName ? unitName : 'Not assigned'}</div>;
  }, [unitName]);

  const representativeBodyTemplate = (rowData) => {
    const representative = rowData.attendees;

    return (
      <div>
        {representative && representative.length > 0 ? (
          <>
            {representative.map(({ firstname, lastname, uid }) => {
              return <span key={uid}>{`${firstname} ${lastname}`}</span>;
            })}
          </>
        ) : (
          <span style={{ color: 'orange' }}>Not assigned</span>
        )}
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    const attendees = rowData.attendees;
    const getTotalHours = _.reduce(
      attendees || [],
      function (sum, n) {
        const { timeEntry } = n;
        return timeEntry ? sum + timeEntry : sum;
      },
      0
    );
    return <span>{getTotalHours.toFixed(2)}</span>;
  };

  const sleepCyclesBodyTemplate = (rowData) => {
    const attendees = rowData.attendees;

    const getTotalSleep = _.reduce(
      attendees,
      function (sum, n) {
        const { sleepEntry } = n;
        return sleepEntry ? sum + sleepEntry : sum;
      },
      0
    );

    return <span>{getTotalSleep.toFixed(2)}</span>;
  };
  const footerGroup = useMemo(() => {
    const attendeesCollection = _.reduce(
      tableData,
      function (sum, n) {
        const { attendees } = n;
        return attendees && attendees.length > 0
          ? [...sum, ...attendees]
          : [...sum];
      },
      []
    );

    const attendeesTotalHours = _.reduce(
      attendeesCollection,
      function (sum, n) {
        const { timeEntry } = n;
        return timeEntry ? sum + timeEntry : sum;
      },
      0
    );

    const getTotalSleep = _.reduce(
      attendeesCollection,
      function (sum, n) {
        const { sleepEntry } = n;
        return sleepEntry ? sum + sleepEntry : sum;
      },
      0
    );

    return (
      <ColumnGroup>
        <Row>
          <Column
            footer='Totals:'
            colSpan={5}
            footerStyle={{ textAlign: 'right' }}
          />
          <Column footer={formatHour(getTotalSleep)} />
          <Column footer={formatHour(attendeesTotalHours)} />
          <Column
            footer={formatCurrency(
              payRate * attendeesTotalHours + sleepRate * getTotalSleep
            )}
          />
        </Row>
      </ColumnGroup>
    );
  }, [tableData, payRate, reportType, sleepRate]);

  const startTimeBodyTemplate = (rowData) => {
    const startTime = rowData.start;

    return <div>{moment(startTime).format('YYYY-MM-DD')}</div>;
  };
  const payRateBodyTemplate = useCallback(
    (rowData) => {
      // console.log(users);

      return <span>{formatCurrency(payRate)}</span>;
    },
    [payRate]
  );

  const sleepRateBodyTemplate = useCallback(
    (rowData) => {
      // console.log(users);

      return <span>{formatCurrency(sleepRate)}</span>;
    },
    [sleepRate]
  );

  const totalBodyTemplate = useCallback(
    (rowData) => {
      const attendees = rowData.attendees;
      const getTotalHours = _.reduce(
        attendees || [],
        function (sum, n) {
          const { timeEntry } = n;
          return timeEntry ? sum + timeEntry : sum;
        },
        0
      );
      const getTotalSleep = _.reduce(
        attendees,
        function (sum, n) {
          const { sleepEntry } = n;
          return sleepEntry ? sum + sleepEntry : sum;
        },
        0
      );

      return (
        <span>
          {formatCurrency(getTotalHours * payRate + sleepRate * getTotalSleep)}
        </span>
      );
    },
    [payRate, sleepRate]
  );
  return (
    <div>
      {/* <div onClick={() => handleOpenSidebar(true)}>invoice preview</div> */}
      <DataTable
        value={tableData}
        className='customTable'
        // paginator
        // rows={10}
        dataKey='uid'
        filters={filters}
        // filterDisplay='row'
        loading={loading}
        globalFilterFields={['start']}
        footerColumnGroup={footerGroup}
        // header={header}
        emptyMessage='No shifts found.'
        scrollable
        scrollHeight='100vh'
      >
        <Column
          field='start'
          header='Start Date'
          sortable
          style={{ minWidth: '8rem' }}
          body={startTimeBodyTemplate}
        />
        <Column
          header='Unit'
          filterField='customer'
          style={{ minWidth: '16rem' }}
          body={countryBodyTemplate}
          // sortable
          // filter
          // filterElement={countryFilter}
        />
        <Column
          header='Team Members'
          filterField='attendees'
          showFilterMenu={false}
          filterMenuStyle={{ width: '14rem' }}
          style={{ minWidth: '14rem' }}
          body={representativeBodyTemplate}
          // filter
          // filterElement={representativeRowFilterTemplate}
        />

        <Column
          field='attendees'
          header='Rate'
          showFilterMenu={false}
          filterMenuStyle={{ width: '14rem' }}
          style={{ minWidth: '12rem' }}
          body={payRateBodyTemplate}
          // sortable
          // filter
          // filterElement={statusRowFilterTemplate}
        />

        <Column
          field='attendees'
          header='Sleep Rate'
          showFilterMenu={false}
          filterMenuStyle={{ width: '14rem' }}
          style={{ minWidth: '12rem' }}
          body={sleepRateBodyTemplate}
          // sortable
          // filter
          // filterElement={statusRowFilterTemplate}
        />

        <Column
          field='attendees'
          header='Sleep'
          showFilterMenu={false}
          filterMenuStyle={{ width: '14rem' }}
          style={{ minWidth: '12rem' }}
          body={sleepCyclesBodyTemplate}
          // sortable
          // filter
          // filterElement={statusRowFilterTemplate}
        />

        <Column
          field='attendees'
          header='Hours'
          showFilterMenu={false}
          filterMenuStyle={{ width: '14rem' }}
          style={{ minWidth: '12rem' }}
          body={statusBodyTemplate}
          // sortable
          // filter
          // filterElement={statusRowFilterTemplate}
        />

        <Column
          field='attendees'
          header='Total'
          showFilterMenu={false}
          filterMenuStyle={{ width: '14rem' }}
          style={{ minWidth: '12rem' }}
          body={totalBodyTemplate}
          // sortable
          // filter
          // filterElement={statusRowFilterTemplate}
        />
        {/* <Column
          field='verified'
          header='Verified'
          dataType='boolean'
          style={{ minWidth: '6rem' }}
          body={verifiedBodyTemplate}
          // filter
          // filterElement={countryFilter}
        /> */}
      </DataTable>
    </div>
  );
}
