import { ref as storageRef } from 'firebase/storage';
import { useEffect, useRef, useState } from 'react';
import { useDownloadURL, useUploadFile } from 'react-firebase-hooks/storage';
// import { TOAST_TYPE } from '../../common/constants';
// import { useToast } from '../../common/hooks/useToast';
import UserImg from '../../../common/img/user.png';
import { useApiService } from '../../../context';
import { useToast } from '../../../common/hooks/useToast';
import { TOAST_TYPE } from '../../../common/constants';

const validateSelectedFile = (newImage) => {
  // const MIN_FILE_SIZE = 1024; // 1MB
  const MAX_FILE_SIZE = 2024; // 5MB

  if (!newImage) {
    // setIsSuccess(false);
    return false;
  }

  // console.log("newImage.size ===== ", newImage.size);
  const fileSizeKiloBytes = newImage.size / 1024;

  // if (fileSizeKiloBytes < MIN_FILE_SIZE) {
  // 	setErrorMsg("File size is less than minimum limit");
  // 	setIsSuccess(false);
  // 	return;
  // }
  if (fileSizeKiloBytes > MAX_FILE_SIZE) {
    return false;
  }

  return true;
};

const RenderLogo = ({ placeholder, uid }) => {
  // const { storage } = useApiService();
  // const [userImg] = useDownloadURL(
  //   storageRef(storage, '/' + uid + '/file.png')
  // );

  return (
    <img
      src={placeholder}
      className='rounded-circle mr-2'
      style={{ width: '65px', height: '65px', cursor: 'pointer' }}
      alt='user-img'
    />
  );
};

const UpdateLogo = ({ uid }) => {
  const hiddenFileInput = useRef(null);
  const [companyLogo, setCompanyLogo] = useState(UserImg);
  const { user, storage, fetchProfileImageData, setProfileImage } =
    useApiService();
  const [uploadFile] = useUploadFile();
  const ref = storageRef(storage, '/' + uid + '/file.png');
  const { triggerToast } = useToast();
  const [userImg] = useDownloadURL(
    storageRef(storage, '/' + uid + '/file.png')
  );

  useEffect(() => {
    if (userImg) {
      setCompanyLogo(userImg);
    }
  }, [userImg]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    // console.log("file.size ===== ", file);

    if (validateSelectedFile(file)) {
      await uploadFile(ref, file, {
        contentType: file.type,
      });
      const getUrl = await fetchProfileImageData({
        path: '/' + uid + '/file.png',
      });
      // console.log('getUrl ==== ', getUrl);
      setCompanyLogo(getUrl);
    } else {
      //toast
      triggerToast({
        message: 'Image should not exceed 2MB',
        type: TOAST_TYPE.warning,
      });
    }
  };

  return (
    <div>
      <input
        type='file'
        label='Image'
        name='myFile'
        accept='.jpeg, .png, .jpg'
        onChange={(e) => handleFileUpload(e)}
        ref={hiddenFileInput}
        // onChange={handleChange}
        style={{ display: 'none' }}
      />
      <div className='d-flex flex-column  mt-2 mb-2 align-items-left'>
        <div className='ps-1 pb-1'>
          <RenderLogo
            placeholder={companyLogo}
            uid={uid}
          />
        </div>
        <div
          className='d-flex flex-column ms-0 text-truncate'
          style={{ marginTop: '0px' }}
        >
          <div
            className='fw-semibold'
            style={{ marginTop: '-8px' }}
          >
            <button
              type='button'
              className='btn btn-link p-0'
              style={{ fontSize: '12px' }}
              onClick={handleClick}
            >
              Change logo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateLogo;
