import styled from '@emotion/styled';
import moment from 'moment';
import { useCallback } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdOpenInNew } from 'react-icons/md';
import { RiEditLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { Card as PageCard, Pill, RoundButtonBase, ToolTip } from '../../common';
import { BADGE_STYLE } from '../../common/constants';
import { useApiService } from '../../context';

const RoundedPill = styled.div`
  padding: 7px;
  color: rgb(255, 255, 255);
  background-color: rgba(89, 161, 49, 0.66);
  font-size: 12px;
`;

const RoundedCircle = styled.div`
  padding: 4px;
  background: #0000004f;
  line-height: 7px;
  cursor: pointer;
  &:hover {
    background: #00000098;
  }
`;

const TimeCheckStyledDiv = styled.span`
  background: #00000036;
  border-radius: 5px;
  padding: 2px;
  margin-left: 8px;
  font-size: 11px;
  letter-spacing: 0.04rem;
  &:hover {
    background: #000000a1;
  }
`;

const StyledCard = styled.div`
  cursor: pointer;
  font-size: 12px;
  padding-top: 0px;
  min-height: 211px;
  border: transparent solid 3px;
  img {
    width: 70px;
  }

  div > svg {
    font-size: 34px;
    color: ${(props) => props.theme[`primary-200`]};
  }
  &:hover {
    border: ${(props) => props.theme[`primary-100`]} solid 3px;

    div > svg {
      color: ${(props) => props.theme.cardHoverBorder};
    }
  }

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

// const StyledCard = styled.div`
// 	cursor: pointer;
// 	// width: 15rem;
// 	font-size: 12px;
// 	padding-top: 0px;
// 	min-height: 211px;
// 	border: #af97d8 solid 3px;
// 	img {
// 		width: 50%;
// 	}

// 	div > svg {
// 		font-size: 34px;
// 		color: #af97d8;
// 	}
// 	&:hover {
// 		border: #b2d897 solid 3px;

// 		div > svg {
// 			color: #b2d897;
// 		}
// 	}
// `;
const StyledDescriptionDiv = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;
const Card = ({
  name,
  uid,
  createdAt,
  setVisibleLeft,
  setNewGroup,
  customerId,
  // start,
  // end,
  // description,
  type,
  description,
  units,
  // reporter,
  // tags,
  // status,
  disabled,
  ...rest
}) => {
  const {
    deleteDoc,
    setRotaPadInvitations,
    fetchData,
    database,
    setRotaPadAbsences,
    setRotaPadWorkOrders,
    setRotaPadWorkSpaces,
    setRotaPadWorkflows,
  } = useApiService();
  const navigate = useNavigate();

  const handleClick = useCallback(async () => {
    const DeleteGroup = await deleteDoc({
      db: database,
      path: 'customers/' + uid,
    });

    // const fetchGroups = await fetchData({
    // 	db: database,
    // 	path: "workflows",
    // });

    // // console.log("absences ===== ", fetchGroups);
    // if (fetchGroups) {
    // 	setRotaPadWorkflows(fetchGroups);
    // }
  }, [database, deleteDoc, fetchData, setRotaPadWorkflows, uid]);
  return (
    <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
      <PageCard className='card shadow-sm me-3'>
        <div className='ps-3 bg-transparent pt-3'>
          <div
            className='fw-semibold'
            style={{
              fontSize: '12px',
              // color: '#a9a9a9',
              // marginBottom: '-4px',
              // marginTop: '-4px',
              lineHeight: '11px',
            }}
          >
            {customerId}
          </div>
          <div
            style={{
              fontSize: '13px',
            }}
          >
            {name}
          </div>
        </div>
        <div className='card-body '>
          <Pill
            content={
              !units
                ? `0 Homes`
                : `${units?.length} Home${
                    units?.length > 1 || units?.length === 0 ? 's ' : ' '
                  }`
            }
            bgStyle={{ ...BADGE_STYLE.Admin }}
            toolTipMessage='Homes'
          />
          <Pill
            content={disabled ? 'Disabled' : 'Enabled'}
            bgStyle={{ ...BADGE_STYLE[disabled ? 'Inactive' : 'Active'] }}
            toolTipMessage={disabled ? 'Disabled' : 'Enabled'}
          />
          {/* {group} */}
          <StyledDescriptionDiv className='pt-2'>
            {description}
          </StyledDescriptionDiv>
        </div>
        <div className='card-footer bg-transparent '>
          <ToolTip
            placement='top'
            overlay={<span style={{ fontSize: 10 }}>Update</span>}
          >
            <RoundButtonBase
              onClick={() => {
                setNewGroup({
                  name,
                  customerId,
                  uid,
                  createdAt,
                  type,
                  disabled,
                  description,
                  units,
                  ...{ ...rest },
                });
                setVisibleLeft(true);
              }}
              type='button'
              size='small'
              className={` me-2 `}
              variant='outline'
            >
              <RiEditLine />
            </RoundButtonBase>
          </ToolTip>
          <ToolTip
            placement='top'
            overlay={<span style={{ fontSize: 10 }}>Delete</span>}
          >
            <RoundButtonBase
              type='button'
              size='small'
              onClick={handleClick}
              className={` me-2 `}
              variant='outline'
            >
              <AiOutlineDelete />
            </RoundButtonBase>
          </ToolTip>
        </div>
      </PageCard>
    </div>
  );
};

export default Card;
