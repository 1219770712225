import styled from '@emotion/styled';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { TOAST_TYPE } from '../../common/constants';
import { useToast } from '../../common/hooks/useToast';
import { useApiService } from '../../context';
import { ButtonBase } from 'components/common';

const StyledLabel = styled.label`
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
  margin-top: 8px;
`;

const StyledInput = styled.input`
  padding: 8px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;

const StyledH5 = styled.h5`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
`;

const UserProfileForm = ({ rotaPadUser }) => {
  const { user, createDoc, database, setRotaPadUser, isUserAdmin } =
    useApiService();

  const { triggerToast } = useToast();

  const validate = (data) => {
    let errors = {};

    if (!data.firstname) {
      errors.firstname = 'First Name is required.';
    }

    if (!data.lastname) {
      errors.lastname = 'Last Name is required.';
    }

    if (!data.email) {
      errors.email = 'Email is required.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      errors.email = 'Invalid email address. E.g. example@email.com';
    }

    return errors;
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return (
      isFormFieldValid(meta) && <small className='p-error'>{meta.error}</small>
    );
  };
  const handleSubmitUserDetails = useCallback(
    async (data, form) => {
      // console.log("rotaPadUser ====== ", rotaPadUser);
      if (rotaPadUser && user && user.uid) {
        const success = await createDoc(
          database,
          `users/${user.uid}`,
          {
            ...rotaPadUser,
            firstname: data.firstname,
            lastname: data.lastname,
            group: data.group,
            role: data.role,
            email: data.email,
            uid: user.uid,
          },
          setRotaPadUser
        );

        if (success) {
          triggerToast({
            message: 'Profile successfully updated!',
            type: TOAST_TYPE.success,
          });
        } else {
          triggerToast({
            message: 'Profile could not be updated!',
            type: TOAST_TYPE.warning,
          });
        }
      }
    },
    [createDoc, database, rotaPadUser, setRotaPadUser, triggerToast, user]
  );
  return (
    <div>
      <Form
        onSubmit={handleSubmitUserDetails}
        initialValues={{
          email: rotaPadUser.email,
          firstname: rotaPadUser.firstname,
          lastname: rotaPadUser.lastname,
          group: rotaPadUser.group,
          role: rotaPadUser.role,
        }}
        validate={validate}
        render={({
          handleSubmit,
          pristine,
          submitting,
          hasValidationErrors,
        }) => (
          <form
            onSubmit={handleSubmit}
            className='p-fluid'
          >
            <StyledH5 className='mb-3'>Change Your Info</StyledH5>
            <div className='row g-3'>
              <div className='col-xs-12 col-md-8 mb-2'>
                <Field
                  name='firstname'
                  render={({ input, meta }) => (
                    <div className='field'>
                      <div className='block'>
                        <StyledLabel
                          htmlFor='firstname'
                          className={classNames({
                            'p-error': isFormFieldValid(meta),
                          })}
                        >
                          First Name <span className='text-danger'>*</span>
                        </StyledLabel>
                        <StyledInput
                          id='firstname'
                          {...input}
                          className={classNames({
                            'p-invalid': isFormFieldValid(meta),
                          })}
                        />
                      </div>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
                <Field
                  name='lastname'
                  render={({ input, meta }) => (
                    <div className='field'>
                      <div className='block'>
                        <StyledLabel
                          htmlFor='lastname'
                          className={classNames({
                            'p-error': isFormFieldValid(meta),
                          })}
                        >
                          Last Name <span className='text-danger'>*</span>
                        </StyledLabel>
                        <StyledInput
                          id='lastname'
                          {...input}
                          className={classNames({
                            'p-invalid': isFormFieldValid(meta),
                          })}
                        />
                      </div>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
                <Field
                  name='email'
                  render={({ input, meta }) => (
                    <div className='field'>
                      <div className='block'>
                        <StyledLabel
                          htmlFor='email'
                          className={classNames({
                            'p-error': isFormFieldValid(meta),
                          })}
                        >
                          Email <span className='text-danger'>*</span>
                        </StyledLabel>

                        <StyledInput
                          id='email'
                          {...input}
                          className={classNames({
                            'p-invalid': isFormFieldValid(meta),
                          })}
                          disabled
                        />
                      </div>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
              </div>
            </div>

            <StyledH5 className='mt-4 mb-3'>User Assignment</StyledH5>
            <div className='row g-3'>
              <div className='col-xs-12 col-md-8 mb-2'>
                <Field
                  name='role'
                  render={({ input, meta }) => (
                    <div className='field'>
                      <div className='block'>
                        <StyledLabel
                          htmlFor='role'
                          className={classNames({
                            'p-error': isFormFieldValid(meta),
                          })}
                        >
                          Role
                        </StyledLabel>
                        <StyledInput
                          id='role'
                          disabled={!isUserAdmin}
                          {...input}
                        />
                      </div>
                    </div>
                  )}
                />
                <Field
                  name='group'
                  render={({ input, meta }) => (
                    <div className='field'>
                      <div className='block'>
                        <StyledLabel htmlFor='group'>Group</StyledLabel>
                        <StyledInput
                          id='group'
                          disabled={!isUserAdmin}
                          {...input}
                        />
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>

            <div className='row g-3 mt-2'>
              <div className='col-xs-12 col-md-8'>
                <ButtonBase
                  variant='primary'
                  size='large'
                  type='submit'
                  // label='Update Profile'
                  className='mt-2'
                  disabled={pristine || submitting || hasValidationErrors}
                >
                  Update Profile
                </ButtonBase>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default UserProfileForm;
