import { css } from '@emotion/css';
import styled from '@emotion/styled';
import _ from 'lodash';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { BiUser } from 'react-icons/bi';
import Select from 'react-select';
import { useApiService } from '../../context';
import TeamMemberAddress from '../../team/AddTeamMemberForm/TeamMemberAddress';
import TeamAvailability from '../../workspace/TeamAvailability';
import {
  StyledCalendarDiv,
  StyledInput,
  StyledInputTextArea,
  StyledLabel,
  colourStyles,
} from '../../workspace/AddWorkSpaceForm/styles';
import { useShiftService } from '../ShiftContext';
import { useBranding } from '../../common/hooks/useBranding';
// import { useBranding } from 'components/common/hooks/useBranding';
// import {
// StyledCalendarDiv,
// StyledInput,
// StyledInputTextArea,
// StyledLabel,
// colourStyles,
// } from 'components/workspace/AddWorkSpaceForm/styles';
// import TeamMemberAddress from '../../team/AddTeamMemberForm/TeamMemberAddress';
// import TeamAvailability from '../TeamAvailability';
// import {
//   colourStyles,
//   StyledCalendarDiv,
//   StyledInput,
//   StyledInputTextArea,
//   StyledLabel,
// } from './styles';

const StyledH5 = styled.h5`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
`;

const StyledTimerSpan = styled.span`
  background: #00000036;
  border-radius: 5px;
  padding: 2px;
  margin-left: 8px;
  font-size: 11px;
  letter-spacing: 0.04rem;
  // &:hover {
  // 	background: #000000a1;
  // }

  ${({ isUserAdmin }) =>
    isUserAdmin
      ? css`
          pointer-events: none;
        `
      : css`
          cursor: pointer;
        `};
`;

export const StyledDropDownDiv = styled.div`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
  .customDropdown {
    width: 100%;
  }
  .customDropdown:not(.p-disabled):hover {
    border-color: #6366f1;
  }

  .customDropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    padding: 8px 8px;
    font-size: 12px;
  }

  .customDropdown .p-dropdown-trigger {
    width: 2rem;
  }

  .customDropdown .p-dropdown-trigger-icon {
    font-size: 10px;
  }

  .p-disabled,
  .p-component:disabled {
    opacity: 0.6;
    background-color: #e9ecef;
  }
`;

// const StyledLabel = styled.label`
// 	font-size: 12px;
// 	display: inline-block;
// 	font-weight: 500;
// `;
const customerColourStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: '6px',
    backgroundColor: 'white',
    borderColor: '#ced4da',
    fontSize: '12px',
    boxShadow: 'none',
    ':hover': {
      borderColor: '#6366f1',
    },
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? '#EEF2FF'
        : isFocused
        ? '#e8ebef'
        : undefined,
      color: isDisabled ? '#ccc' : isSelected ? '#4338CA' : '#495057',
      cursor: isDisabled ? 'not-allowed' : 'default',
      fontSize: '12px',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? 'blue'
            : '#f5ebff'
          : 'green',
      },
    };
  },
  valueContainer: (styles) => ({ ...styles, padding: '4px' }),
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: '#6a59c2',
      color: '#fff',
      padding: '2px 4px',
      borderRadius: '4px',
    };
  },
};

export const customerColourStylesFx = (color) => {
  return {
    control: (styles) => ({
      ...styles,
      borderRadius: '6px',
      backgroundColor: 'white',
      borderColor: '#e3e3e3',
      fontSize: '12px',
      boxShadow: 'none',
      ':hover': {
        borderColor: color,
      },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? '#EEF2FF'
          : isFocused
          ? '#e8ebef'
          : undefined,
        color: isDisabled ? '#ccc' : isSelected ? '#4338CA' : '#495057',
        cursor: isDisabled ? 'not-allowed' : 'default',
        fontSize: '12px',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? 'blue'
              : '#f5ebff'
            : 'green',
        },
      };
    },
    valueContainer: (styles) => ({ ...styles, padding: '4px' }),
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: '#fff',
        color: color,
        padding: '2px 4px',
        borderRadius: '4px',
      };
    },
  };
};
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 10,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};
const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span style={{ fontSize: 11 }}>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);
const CustomerSelectFieldAdapter = ({
  input,
  meta,
  isFormFieldValid,
  isUserAdmin,
  getFormErrorMessage,
  customers,
  primary,
  ...rest
}) => {
  // if (_.isEmpty(customers)) {
  //   return;
  // }

  const selectOptions = useMemo(
    () =>
      (customers || [])
        .filter((x) => !x.disabled)
        .map((obj) => {
          return {
            label: obj.name,
            options: (obj.units || []).map((x) => {
              return {
                value: x.name,
                label: x.name,
                id: x.id,
              };
            }),
          };
        }),
    [customers]
  );

  const mergedSelectOptions = useMemo(() => {
    let findOption = {};

    for (let index = 0; index < customers.length; index++) {
      const units = customers[index].units;
      const unit = _.find(units, { id: input.value });
      if (unit && !customers[index].disabled) {
        findOption = unit;
        break;
      }
    }
    return {
      id: findOption.id,
      value: findOption.name,
      label: findOption.name,
    };
  }, [customers, input.value]);

  return (
    <div className='field'>
      <div className='block'>
        <StyledLabel
          htmlFor='customer'
          className={classNames({
            'p-error': isFormFieldValid(meta),
          })}
        >
          Customer <span className='text-danger'>*</span>
        </StyledLabel>
        <StyledDropDownDiv>
          <Select
            id='customer'
            // {...input}
            styles={customerColourStylesFx(primary)}
            formatGroupLabel={formatGroupLabel}
            value={input.value ? mergedSelectOptions : null}
            onChange={(x) => {
              input.onChange(x && x.id ? x.id : undefined);
            }}
            options={selectOptions}
            isDisabled={!isUserAdmin}
          />
        </StyledDropDownDiv>
      </div>
      {getFormErrorMessage(meta)}
    </div>
  );
};
const AddShiftForm = ({
  handleSubmit,
  uid,
  pristine,
  submitting,
  hasValidationErrors,
  visibleLeft,
  // theme,
}) => {
  const { rotaPadCustomersTopic, rotaPadTeamMembersTopic } = useApiService();
  // console.log('theme ==== ', theme);
  const {
    colour: { primary },
  } = useBranding();
  // console.log('colour ==== ', primary);
  const { rotaCurrentDate } = useShiftService();
  const [showNewPassword, setShowNewPassword] = useState({
    hide: true,
    info: 'Show',
  });

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return (
      isFormFieldValid(meta) && <small className='p-error'>{meta.error}</small>
    );
  };

  const isSelf = false;
  const isUserAdmin = true;

  const getUsers = useMemo(
    () =>
      _.isObject(rotaPadTeamMembersTopic)
        ? Object.keys(rotaPadTeamMembersTopic).map((key) => {
            return {
              name:
                rotaPadTeamMembersTopic[key].firstname +
                ' ' +
                rotaPadTeamMembersTopic[key].lastname,
              value: rotaPadTeamMembersTopic[key].uid,
            };
          })
        : [{}],
    [rotaPadTeamMembersTopic]
  );
  // console.log('rotaPadTeamMembersTopic ==== ', rotaPadTeamMembersTopic);

  const startOfMonth = useMemo(
    () =>
      new Date(
        moment(rotaCurrentDate).startOf('month').format('YYYY-MM-DD hh:mm')
      ),
    [rotaCurrentDate]
  );
  const endOfMonth = useMemo(
    () =>
      new Date(
        moment(rotaCurrentDate).endOf('month').format('YYYY-MM-DD hh:mm')
      ),
    [rotaCurrentDate]
  );

  const convertCustomerstoArray = useMemo(() => {
    return _.isObject(rotaPadCustomersTopic) &&
      Object.keys(rotaPadCustomersTopic).length > 0
      ? Object.keys(rotaPadCustomersTopic).map((key) => {
          return { ...rotaPadCustomersTopic[key] };
        })
      : [];
  }, [rotaPadCustomersTopic]);

  // console.log('convertCustomerstoArray ===== ', convertCustomerstoArray);
  return (
    <div className='w-100 p-3'>
      <div className='row g-3'>
        <div className='col-12 mb-1'>
          <Field
            name='start'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='start'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Start <span className='text-danger'>*</span>
                  </StyledLabel>

                  <StyledCalendarDiv>
                    <Calendar
                      panelClassName='customPicker'
                      id='start'
                      {...input}
                      value={input.value ? new Date(input.value) : null}
                      onChange={(v) => {
                        input.onChange(
                          v.value ? moment(v.value).format() : null
                        );
                      }}
                      showTime
                      hourFormat='12'
                      showButtonBar
                      minDate={startOfMonth}
                      maxDate={endOfMonth}
                      placeholder='Enter start date/time'
                      disabled={!isUserAdmin}
                    />
                  </StyledCalendarDiv>
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>

        <Field
          name='start'
          subscription={{ value: true }}
        >
          {({ input: { value } }) => {
            // console.log('value ==== ', value);
            return (
              <div className='col-12 mt-1 mb-1'>
                <Field
                  name='end'
                  render={({ input, meta }) => (
                    <div className='field'>
                      <div className='block'>
                        <StyledLabel
                          htmlFor='end'
                          className={classNames({
                            'p-error': isFormFieldValid(meta),
                          })}
                        >
                          End
                        </StyledLabel>

                        <StyledCalendarDiv>
                          <Calendar
                            panelClassName='customPicker'
                            id='end'
                            {...input}
                            value={input.value ? new Date(input.value) : null}
                            onChange={(v) => {
                              input.onChange(
                                v.value ? moment(v.value).format() : null
                              );
                            }}
                            showTime
                            hourFormat='12'
                            showButtonBar
                            minDate={
                              new Date(moment(value).format('YYYY-MM-DD hh:mm'))
                            }
                            maxDate={endOfMonth}
                            placeholder='Enter end date/time'
                            disabled={!isUserAdmin}
                          />
                        </StyledCalendarDiv>
                      </div>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
              </div>
            );
          }}
        </Field>
        <Field
          name='end'
          subscription={{ value: true }}
        >
          {({ input }) => {
            // console.log("end value ===== ", input.value);
            return (
              <>
                <OnChange name='start'>
                  {(value) => {
                    // console.log('start value ===== ', value);
                    // console.log('end value ===== ', input.value);
                    const startTime = moment(value);
                    const endTime = moment(input.value);
                    if (endTime.isAfter(startTime)) {
                      console.log('all good ;)');
                    } else {
                      input.onChange(null);
                    }
                    // input.onChange(null);
                  }}
                </OnChange>
              </>
            );
          }}
        </Field>

        <div className='col-12 mt-1 mb-1'>
          <Field
            name='title'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='title'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Title <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledInput
                    id='title'
                    placeholder='Enter title'
                    {...input}
                    className={classNames({
                      'p-invalid': isFormFieldValid(meta),
                    })}
                    disabled={!isUserAdmin}
                  />
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>

        <div className='col-12 mt-1 mb-1'>
          <Field
            name='type'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='type'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Type <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledDropDownDiv>
                    <Select
                      id='type'
                      placeholder='Enter description'
                      {...input}
                      styles={customerColourStylesFx(primary)}
                      value={{
                        label: input.value,
                        value: input.value,
                      }}
                      onChange={(x) => {
                        input.onChange(x && x.value ? x.value : undefined);
                      }}
                      options={[
                        { label: 'Long Day', value: 'Long Day' },
                        { label: 'Sleep', value: 'Sleep' },
                        {
                          label: 'Long Day & Sleep',
                          value: 'Long Day & Sleep',
                        },
                        { label: 'Waking Night', value: 'Waking Night' },
                        { label: 'Short Shift', value: 'Short Shift' },
                      ]}
                      isDisabled={!isUserAdmin}
                    />
                  </StyledDropDownDiv>
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>

        <div className='col-12 mt-1 mb-1'>
          <Field
            name='customer'
            component={CustomerSelectFieldAdapter}
            isFormFieldValid={isFormFieldValid}
            isUserAdmin={isUserAdmin}
            getFormErrorMessage={getFormErrorMessage}
            customers={convertCustomerstoArray}
            primary={primary}
          />
        </div>
        {/* <StyledH5 className='mt-3 mb-2'>Location</StyledH5>
        <Field
          name='address'
          label='address'
          component={TeamMemberAddress}
          labelPosition='right'
          visibleLeft={visibleLeft}
          disabled={false}
        /> */}
        <div className='col-12 mt-1 mb-1'>
          <Field
            name='status'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='status'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Status <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledDropDownDiv>
                    <Select
                      id='status'
                      {...input}
                      styles={colourStyles}
                      value={{
                        label: input.value,
                        value: input.value,
                      }}
                      onChange={(x) => {
                        input.onChange(x && x.value ? x.value : undefined);
                      }}
                      options={[
                        { label: 'To Do', value: 'To Do' },
                        { label: 'On Hold', value: 'On Hold' },
                        {
                          label: 'In Progress',
                          value: 'In Progress',
                        },
                        { label: 'Done', value: 'Done' },
                      ]}
                      isDisabled={!isUserAdmin}
                    />
                  </StyledDropDownDiv>
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>
        <div className='col-12 mt-1 mb-1'>
          <Field
            name='description'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel htmlFor='description'>Description</StyledLabel>
                  <StyledInputTextArea
                    id='description'
                    placeholder='Enter description'
                    rows={5}
                    cols={30}
                    {...input}
                    className='w-100 p-inputtext-sm'
                    disabled={!isUserAdmin}
                  />
                </div>
              </div>
            )}
          />
        </div>
        <Field
          name='attendees'
          subscription={{ value: true }}
        >
          {({ input: { value } }) => {
            return (
              <>
                <StyledH5 className='mt-3 mb-2'>Assignees</StyledH5>

                <div className='mt-0 mb-2'>
                  {value.length > 0 ? (
                    <>
                      {value.map((attendee) => {
                        return (
                          <div
                            key={attendee.uid}
                            className='col-12 mb-1'
                          >
                            <div className='field'>
                              <div className='block'>
                                <BiUser
                                  style={{
                                    marginRight: '8px',
                                    marginTop: '-4px',
                                    fontSize: '0.65rem',
                                  }}
                                />
                                <StyledLabel
                                  htmlFor='name'
                                  className='ms-2'
                                >
                                  {attendee.firstname + ' ' + attendee.lastname}
                                </StyledLabel>

                                <StyledTimerSpan
                                  isUserAdmin={false}
                                  className='px-1'
                                >
                                  {attendee?.timeEntry &&
                                  attendee?.timeEntry !== 0
                                    ? attendee?.timeEntry.toFixed(2)
                                    : `0:00`}
                                </StyledTimerSpan>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className='col-12 mb-1'>
                      <div className='field'>
                        <div className='block'>
                          <BiUser
                            style={{
                              // marginRight: "8px",
                              // marginTop: "-4px",
                              fontSize: '0.65rem',
                              color: '#ff6200d9',
                            }}
                          />
                          <StyledLabel
                            htmlFor='zero'
                            className='ms-2'
                            style={{
                              color: '#ff6200d9',
                            }}
                          >
                            0 Team members assigned
                          </StyledLabel>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            );
          }}
        </Field>

        <Field
          name='start'
          subscription={{ value: true }}
        >
          {({ input: { value: startValue } }) => (
            <>
              <Field
                name='end'
                subscription={{ value: true }}
              >
                {({ input: { value: endValue } }) => (
                  <>
                    <Field
                      name='attendees'
                      label='attendees'
                      component={TeamAvailability}
                      users={null}
                      startValue={startValue}
                      endValue={endValue}
                      leaveRequests={null}
                      rotaPadTeam={rotaPadTeamMembersTopic}
                    />
                  </>
                )}
              </Field>
            </>
          )}
        </Field>
      </div>
    </div>
  );
};

export default AddShiftForm;
