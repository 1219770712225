import Color from 'color';
import _ from 'lodash';
import ClientLogo from './brands/xynexcare_logo_preview.png';
import variables from './RotaPad.module.scss';

export const useBranding = () => {
  const initialBrandingState = {
    mapboxAccessToken:
      'pk.eyJ1Ijoia2NpamV6aWUiLCJhIjoiY2xoZ3R3eHQ0MGhlcTNrbXFvNnE2eXQ3eCJ9.zuptJ9FK3rYmkeRP3HVkvg',
    mapboxStyles: [
      {
        value: 'mapbox://styles/mapbox/streets-v12',
        label: 'Streets',
      },
      {
        value: 'mapbox://styles/mapbox/outdoors-v12',
        label: 'Outdoors',
      },
      {
        value: 'mapbox://styles/mapbox/light-v11',
        label: 'Light',
      },
    ],
    logo: {
      fullLogo: ClientLogo,
    },

    title: 'Rota Pad V3 Branding',
    loginTitle: 'Control your workspaces',
    loginSubtitle: 'Grow your business',
    loginParagraph: 'We gon get it fam ... 😎',
  };

  const getBrandColours = () => {
    const colours = {
      dark: '#051b32',
      // primary: '#c8263b',
      primary: '#6554c0',
      // primary: '#f37002',
      // primary: '#6b9a11',
      light: '#fafafa',
    };

    const newColour = Color(colours.primary);

    const primaryColours = [
      newColour.desaturate(0.2).lighten(0.9),
      newColour.desaturate(0.1).lighten(0.7),
      newColour.desaturate(0.05).lighten(0.5),
      newColour.desaturate(0.04).lighten(0.3),
      newColour,
      newColour.saturate(0.04).lighten(0.2),
      newColour.saturate(0.05).lighten(0.4),
      newColour.saturate(0.1).lighten(0.6),
      newColour.saturate(0.2).lighten(0.8),
    ];

    const secondaryLight = Color(colours.light)
      .mix(Color(newColour), 0.1)
      .hex();

    const namedColours = _.reduce(
      primaryColours,
      (acc, cur, index) => ({
        ...acc,
        [`${'primary'}-${index + 1}00`]: cur.hex(),
      }),
      {}
    );

    // console.log('brandcolours ===== ', {
    //   ...colours,
    //   ...namedColours,
    //   ...variables,
    //   'secondary-light-500': secondaryLight,
    //   'secondary-light-600': '#DFE1E6',
    //   'secondary-light-700': '#C4C7CC',
    //   'secondary-light-800': '#AAADB3',
    // });

    return {
      ...colours,
      ...namedColours,
      ...variables,
      'secondary-light-500': secondaryLight,
      'secondary-light-600': '#DFE1E6',
      'secondary-light-700': '#C4C7CC',
      'secondary-light-800': '#AAADB3',
    };
  };

  return {
    ...initialBrandingState,
    colour: getBrandColours(),
  };
};
