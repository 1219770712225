import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBranding } from '../../common/hooks/useBranding';
import Logo from '../../common/img/logo.png';
import { useApiService } from '../../context';
import { getMenuOptions, getUrls } from '../utils/RenderSideNavMenu';

const SideNav = ({ user, auth, database, setVisibleLeft }) => {
  // const { logo } = useBranding();
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const {
    rotaPadTeam,
    rotaPadGroups,
    rotaPadInvitations,
    rotaPadAbsences,
    rotaPadWorkOrders,
    rotaPadActiveWorkSpace,
    rotaPadWorkflows,
    isRotaPadUserAdmin,
    rotaPadTeamMembersTopic,
    rotaPadUserGroupsTopic,
    rotaPadCustomersTopic,
  } = useApiService();

  const menuOptions = useMemo(() => {
    const urls = getUrls(
      rotaPadWorkOrders,
      [{}],
      rotaPadTeam,
      [{}],
      navigate,
      setVisibleLeft,
      auth,
      rotaPadGroups,
      rotaPadInvitations,
      rotaPadAbsences,
      pathname,
      rotaPadActiveWorkSpace,
      rotaPadWorkflows,
      isRotaPadUserAdmin,
      rotaPadTeamMembersTopic,
      rotaPadUserGroupsTopic,
      rotaPadCustomersTopic
    );
    return getMenuOptions(urls, pathname, rotaPadActiveWorkSpace);
  }, [
    rotaPadWorkOrders,
    rotaPadTeam,
    navigate,
    setVisibleLeft,
    auth,
    rotaPadGroups,
    rotaPadInvitations,
    rotaPadAbsences,
    pathname,
    rotaPadActiveWorkSpace,
    rotaPadWorkflows,
    isRotaPadUserAdmin,
    rotaPadTeamMembersTopic,
    rotaPadUserGroupsTopic,
    rotaPadCustomersTopic,
  ]);

  // console.log("pathname ====== ", pathname);
  return (
    <div
      id='sidebarMenu'
      className={`col-md-2 col-lg-2 col-xlg-2 text-bg-light p-0 d-none d-sm-block d-sm-none d-md-block sidebarMenu border-end`}
    >
      <div
        className='d-flex flex-column mb-0'
        style={{ height: 'calc(100vh - 59px)' }}
      >
        {/* {Logo && (
          <div className='mt-2 ms-3 text-start'>
            {' '}
            <img
              src={Logo}
              width='140'
              height='32'
              alt='ROTA PAD logo'
            />
          </div>
        )} */}
        {menuOptions}
      </div>
    </div>
  );
};

export default SideNav;
