import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApiService } from '../../context';
import Logo from '../img/logo.png';
import { getMenuOptions, getUrls } from '../utils/RenderSideNavMenu';
import { useBranding } from '../hooks/useBranding';

const MobileSideNav = ({ visibleLeft, setVisibleLeft, auth }) => {
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const { logo } = useBranding();

  const {
    rotaPadTeam,
    rotaPadGroups,
    rotaPadInvitations,
    rotaPadAbsences,
    rotaPadWorkOrders,
    rotaPadActiveWorkSpace,
    rotaPadWorkflows,
    isRotaPadUserAdmin,
    rotaPadTeamMembersTopic,
    rotaPadUserGroupsTopic,
    rotaPadCustomersTopic,
  } = useApiService();

  const menuOptions = useMemo(() => {
    const urls = getUrls(
      rotaPadWorkOrders,
      [{}],
      rotaPadTeam,
      [{}],
      navigate,
      setVisibleLeft,
      auth,
      rotaPadGroups,
      rotaPadInvitations,
      rotaPadAbsences,
      pathname,
      rotaPadActiveWorkSpace,
      rotaPadWorkflows,
      isRotaPadUserAdmin,
      rotaPadTeamMembersTopic,
      rotaPadUserGroupsTopic,
      rotaPadCustomersTopic
    );
    return getMenuOptions(urls, pathname, rotaPadActiveWorkSpace);
  }, [
    rotaPadWorkOrders,
    rotaPadTeam,
    navigate,
    setVisibleLeft,
    auth,
    rotaPadGroups,
    rotaPadInvitations,
    rotaPadAbsences,
    pathname,
    rotaPadActiveWorkSpace,
    rotaPadWorkflows,
    isRotaPadUserAdmin,
    rotaPadTeamMembersTopic,
    rotaPadUserGroupsTopic,
    rotaPadCustomersTopic,
  ]);

  return (
    <div
      className={`d-block d-xs-block d-sm-block d-lg-none d-md-none offcanvas offcanvas-start  ${
        visibleLeft ? 'show' : 'hiding'
      }`}
      // tabIndex='-1'
      id='offcanvasExample'
      aria-labelledby='offcanvasExampleLabel'
      style={{ width: '18em' }}
    >
      <div className='offcanvas-header'>
        <h5
          className='offcanvas-title'
          id='offcanvasExampleLabel'
        >
          {/* <img
            src={logo?.fullLogo}
            alt='Logo'
            style={{ height: '33px' }}
          /> */}
          <img
            src={Logo}
            width='140'
            height='32'
            alt='ROTA PAD logo'
          />
        </h5>
        <button
          type='button'
          className='btn-close'
          data-bs-dismiss='offcanvas'
          aria-label='Close'
          onClick={() => setVisibleLeft(false)}
        ></button>
      </div>
      <div className='offcanvas-body p-0'>
        <div
          className='d-flex flex-column mb-0'
          style={{ height: 'calc(100vh - 59px)' }}
        >
          {menuOptions}
        </div>
      </div>
    </div>
  );
};

export default MobileSideNav;
