import { child, get, ref, remove, set, onValue } from 'firebase/database';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import moment from 'moment';
import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ShiftContext = createContext({});

export const useShiftService = () => useContext(ShiftContext);

export const ApiShiftProvider = ({
  user,
  auth,
  database,
  storage,
  children,
  currentDate,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rotaCurrentDate, setRotaCurrentDate] = useState(currentDate);
  const params = useParams();

  // const [shifts, setShifts] = useState();
  const [rotaPadShiftsTopic, setRotaPadShiftsTopic] = useState();

  useEffect(() => {
    if (user && rotaCurrentDate) {
      // console.log(
      //   `shifts/${moment(rotaCurrentDate).format('YYYY')}/${moment(
      //     rotaCurrentDate
      //   ).format('MMMM')}`
      // );
      const query = ref(
        database,
        `shifts/${moment(rotaCurrentDate).format('YYYY')}/${moment(
          rotaCurrentDate
        ).format('MMMM')}/`
      );
      // return onValue(query, (snapshot) => {
      //   const data = snapshot.val();

      //   if (snapshot.exists()) {
      //     Object.values(data).map((project) => {
      //       console.log('projects ==== ', project);
      //     });
      //   }
      // });
      return onValue(query, (snapshot) => {
        // console.log('snapshot ==== ', snapshot.val());
        if (snapshot.exists()) {
          setRotaPadShiftsTopic(snapshot.val());
        } else {
          setRotaPadShiftsTopic();
        }
        // snapshot.forEach((childSnapshot) => {
        //   const childKey = childSnapshot.key;
        //   const childData = childSnapshot.val();
        //   console.log('childKey ==== ', childKey);
        //   console.log('childData ==== ', childData);
        //   // ...
        // });
      });
    }
  }, [database, user, rotaCurrentDate]);

  // useEffect(() => {
  //   const fetch = async () => {
  //     const dbRef = ref(database);
  //     // const getUser = await get(child(dbRef, `users/${user.uid}`));
  //     const getShifts = await get(
  //       child(
  //         dbRef,
  //         `shifts/${moment(rotaCurrentDate).format('YYYY')}/${moment(
  //           rotaCurrentDate
  //         ).format('MMMM')}`
  //       )
  //     );
  //     // const groups = await get(child(dbRef, `groups`));
  //     // const invites = await get(child(dbRef, `invitations`));
  //     // const absences = await get(child(dbRef, `absences`));
  //     // const workOrders = await get(child(dbRef, `workspace/workorders`));

  //     // const workspaces = await get(child(dbRef, `workspaces`));
  //     // const workflows = await get(child(dbRef, `workflows`));

  //     if (getShifts.exists()) {
  //       setShifts(getShifts.val());
  //       console.log('getShifts.val() ==== ', getShifts.val());
  //     } else {
  //       console.log('No shifts data available');
  //       setShifts();
  //     }
  //   };
  //   if (user && rotaCurrentDate) {
  //     fetch();
  //   }
  // }, [database, user, rotaCurrentDate]);

  const fetchProfileImageData = async ({ path }) => {
    const dbRef = storageRef(storage, path);
    // const getUser = await getDownloadURL(dbRef)
    const imgUrl = await getDownloadURL(dbRef);
    return imgUrl;
  };

  const fetchShiftsData = async ({ db, path }) => {
    setIsLoading(true);
    setError(null);
    try {
      const dbRef = ref(db);
      const user = await get(child(dbRef, path));
      if (user.exists()) {
        setIsLoading(false);
        return user.val();
      } else {
        console.log('No user data available');
        setIsLoading(false);
        return 'No user data available';
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };
  // Create a document
  const createShifDoc = async (db, path, data, callBackFn) => {
    setIsLoading(true);
    try {
      // const dbRef = ref(db);
      await set(ref(db, path), data);
      const dbRef = ref(database);
      const user = await get(child(dbRef, path));
      if (user.exists()) {
        setIsLoading(false);
        if (callBackFn) {
          callBackFn(user.val());
        }
        return user.val();
      } else {
        console.log('No user data available');
        setIsLoading(false);
        return 'No user data available';
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
      // throw new Error("Failed to create document");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteShiftDoc = async ({ db, path }) => {
    setIsLoading(true);
    setError(null);
    try {
      const dbRef = ref(db);
      const user = await remove(child(dbRef, path));
      if (user.exists()) {
        console.log('deleteDoc', user.val());
        setIsLoading(false);
        return user.val();
      } else {
        console.log('No user data available');
        setIsLoading(false);
        return 'No user data available';
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const value = {
    isLoading,
    error,
    // shifts,
    rotaCurrentDate,
    setRotaCurrentDate,
    fetchShiftsData,
    // setShifts,
    deleteShiftDoc,
    createShifDoc,
    rotaPadShiftsTopic,
  };
  return (
    <ShiftContext.Provider value={value}>{children}</ShiftContext.Provider>
  );
};
