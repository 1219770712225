import { css } from '@emotion/react';
import styled from '@emotion/styled';
import _ from 'lodash';
import { BiUser } from 'react-icons/bi';
import {
  BsBarChartLine,
  BsCalendarX,
  BsHouses,
  BsPeople,
  BsPersonWorkspace,
  BsSendPlus,
} from 'react-icons/bs';
import { FaCalendarAlt } from 'react-icons/fa';
import { FiHelpCircle, FiLogOut } from 'react-icons/fi';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { VscSourceControl } from 'react-icons/vsc';
import { BTN_VARIANTS } from '../../ButtonBase';
import { URLS } from '../../constants';
import { Badge } from '../../index';
import './index.css';

const getSizeStyles = ({ theme, active }) => {
  return active
    ? css`
        color: ${theme.light};
        background-color: ${theme.primary};
        border-right: 3px solid ${theme.primary};
        padding-right: 13px;
      `
    : css`
        padding-right: 13px;
        border-right: 3px solid transparent;
      `;
};

const StyledSpan = styled.span`
  color: #888;
  cursor: pointer;
  font-size: 0.78em;

  ${getSizeStyles}

  &:hover {
    color: ${(props) => props.theme[`light`]};
    background-color: ${(props) => props.theme.primary};
  }
`;

const StyledH6 = styled.h6`
  font-size: 0.857rem;
  color: ${(props) => props.theme.primary};
`;
export const getUrls = (
  shiftsCount,
  leaveRequests,
  users,
  project,
  navigate,
  setVisibleLeft,
  auth,
  rotaPadGroups,
  rotaPadInvitations,
  rotaPadAbsences,
  pathname,
  rotaPadActiveWorkSpace,
  rotaPadWorkflows,
  isRotaPadUserAdmin,
  rotaPadTeamMembersTopic,
  rotaPadUserGroupsTopic,
  rotaPadCustomersTopic
) => {
  const isDashBoard =
    _.includes(
      Object.keys(URLS).map((key) => URLS[key]),
      pathname
    ) || _.includes(pathname, '/workflows/');
  const activeWorkSpaceId = rotaPadActiveWorkSpace?.uid;
  // console.log('isRotaPadUserAdmin ===== ', isRotaPadUserAdmin);
  return isRotaPadUserAdmin !== undefined && isRotaPadUserAdmin
    ? [
        {
          name: 'Dashboard',
          urls: [
            {
              name: 'Shifts',
              path: '/dashboard',
              icon: (
                <FaCalendarAlt
                  style={{ marginRight: '8px', marginTop: '-4px' }}
                />
              ),
              sideBarAction: () => {
                setVisibleLeft(false);
                navigate('/dashboard');
              },
              // counter: (
              //   <Badge
              //     value={
              //       _.isObject(shiftsCount)
              //         ? Object.keys(shiftsCount).length
              //         : 0
              //     }
              //     variant={BTN_VARIANTS.primary}
              //   ></Badge>
              // ),
            },
          ],
        },
        {
          name: 'Team',
          urls: [
            {
              name: 'Team Members',
              path: '/team',
              icon: (
                <BsPeople style={{ marginRight: '8px', marginTop: '-4px' }} />
              ),
              sideBarAction: () => {
                setVisibleLeft(false);
                navigate('/team');
              },
              counter: (
                <Badge
                  value={
                    _.isObject(rotaPadTeamMembersTopic)
                      ? Object.keys(rotaPadTeamMembersTopic).length
                      : 0
                  }
                  // styles={BADGE_STYLE.Menu}
                  variant={BTN_VARIANTS.primary}
                ></Badge>
              ),
            },
            {
              name: 'User Groups',
              path: '/groups',
              icon: (
                <HiOutlineUserGroup
                  style={{ marginRight: '8px', marginTop: '-4px' }}
                />
              ),
              sideBarAction: () => {
                setVisibleLeft(false);
                navigate('/groups');
              },
              counter: (
                <Badge
                  value={
                    _.isObject(rotaPadUserGroupsTopic)
                      ? Object.keys(rotaPadUserGroupsTopic).length
                      : `0`
                  }
                  variant={BTN_VARIANTS.primary}
                ></Badge>
              ),
            },
            // {
            //   name: 'Invitations',
            //   path: '/invitations',
            //   icon: (
            //     <BsSendPlus style={{ marginRight: '8px', marginTop: '-4px' }} />
            //   ),
            //   sideBarAction: () => {
            //     setVisibleLeft(false);
            //     navigate('/invitations');
            //   },
            //   counter: (
            //     <Badge
            //       value={
            //         _.isObject(rotaPadInvitations)
            //           ? Object.keys(rotaPadInvitations).length
            //           : 0
            //       }
            //       variant={BTN_VARIANTS.primary}
            //     ></Badge>
            //   ),
            // },
            {
              name: 'Absences',
              path: '/absences',
              icon: (
                <BsCalendarX
                  style={{ marginRight: '8px', marginTop: '-4px' }}
                />
              ),
              sideBarAction: () => {
                setVisibleLeft(false);
                navigate('/absences');
              },
              counter: (
                <Badge
                  value={
                    _.isObject(rotaPadAbsences)
                      ? Object.keys(rotaPadAbsences).length
                      : 0
                  }
                  variant={BTN_VARIANTS.primary}
                ></Badge>
              ),
            },
          ],
        },
        {
          name: 'Org',
          urls: [
            {
              name: 'Customers',
              path: '/customers',
              icon: (
                <BsHouses style={{ marginRight: '8px', marginTop: '-4px' }} />
              ),
              sideBarAction: () => {
                setVisibleLeft(false);
                navigate('/customers');
              },
              counter: (
                <Badge
                  value={
                    _.isObject(rotaPadCustomersTopic)
                      ? Object.keys(rotaPadCustomersTopic).length
                      : `0`
                  }
                  // styles={BADGE_STYLE.Menu}
                  variant={BTN_VARIANTS.primary}
                ></Badge>
              ),
            },
            {
              name: 'Reports',
              path: '/reports',
              icon: (
                <BsBarChartLine
                  style={{ marginRight: '8px', marginTop: '-4px' }}
                />
              ),
              sideBarAction: () => {
                setVisibleLeft(false);
                navigate('/reports');
              },
              // counter: (
              //   <Badge
              //     value={project?.length}
              //     variant={BTN_VARIANTS.primary}
              //   ></Badge>
              // ),
            },
          ],
        },
        {
          name: 'Account',
          urls: [
            {
              name: 'My Profile',
              path: '/profile',
              icon: (
                <BiUser style={{ marginRight: '8px', marginTop: '-4px' }} />
              ),
              sideBarAction: () => {
                setVisibleLeft(false);
                navigate('/profile');
              },
            },
          ],
        },
        {
          name: 'Quick Links',
          urls: [
            {
              name: 'Help',
              action: () =>
                window.open('https://support.rotapad.com/', '_blank').focus(),
              sideBarAction: () => {
                setVisibleLeft(false);
                navigate('/team');
              },
              icon: (
                <FiHelpCircle
                  style={{ marginRight: '8px', marginTop: '-4px' }}
                />
              ),
            },
            {
              name: 'Log out',
              action: async () => {
                try {
                  await auth.signOut();
                  localStorage.removeItem('firebaseAuthToken');
                  localStorage.removeItem('firebaseAuthTokenExpireTime');
                  navigate('/');
                } catch (error) {
                  console.error(error);
                }
              },
              sideBarAction: () => {
                setVisibleLeft(false);
                navigate('/team');
              },
              icon: (
                <FiLogOut style={{ marginRight: '8px', marginTop: '-4px' }} />
              ),
            },
          ],
        },
      ]
    : [
        {
          name: 'Dashboard',
          urls: [
            {
              name: 'Shifts',
              path: '/dashboard',
              icon: (
                <FaCalendarAlt
                  style={{ marginRight: '8px', marginTop: '-4px' }}
                />
              ),
              sideBarAction: () => {
                setVisibleLeft(false);
                navigate('/dashboard');
              },
            },
          ],
        },
        {
          name: 'Team',
          urls: [
            {
              name: 'Team Members',
              path: '/team',
              icon: (
                <BsPeople style={{ marginRight: '8px', marginTop: '-4px' }} />
              ),
              sideBarAction: () => {
                setVisibleLeft(false);
                navigate('/team');
              },
              counter: (
                <Badge
                  value={
                    _.isObject(rotaPadTeamMembersTopic)
                      ? Object.keys(rotaPadTeamMembersTopic).length
                      : 0
                  }
                  // styles={BADGE_STYLE.Menu}
                  variant={BTN_VARIANTS.primary}
                ></Badge>
              ),
            },

            {
              name: 'Absences',
              path: '/absences',
              icon: (
                <BsCalendarX
                  style={{ marginRight: '8px', marginTop: '-4px' }}
                />
              ),
              sideBarAction: () => {
                setVisibleLeft(false);
                navigate('/absences');
              },
              counter: (
                <Badge
                  value={
                    _.isObject(rotaPadAbsences)
                      ? Object.keys(rotaPadAbsences).length
                      : 0
                  }
                  variant={BTN_VARIANTS.primary}
                ></Badge>
              ),
            },
          ],
        },

        {
          name: 'Account',
          urls: [
            {
              name: 'My Profile',
              path: '/profile',
              icon: (
                <BiUser style={{ marginRight: '8px', marginTop: '-4px' }} />
              ),
              sideBarAction: () => {
                setVisibleLeft(false);
                navigate('/profile');
              },
            },
          ],
        },
        {
          name: 'Quick Links',
          urls: [
            {
              name: 'Help',
              action: () =>
                window.open('https://support.rotapad.com/', '_blank').focus(),
              sideBarAction: () => {
                setVisibleLeft(false);
                navigate('/team');
              },
              icon: (
                <FiHelpCircle
                  style={{ marginRight: '8px', marginTop: '-4px' }}
                />
              ),
            },
            {
              name: 'Log out',
              action: async () => {
                try {
                  await auth.signOut();
                  localStorage.removeItem('firebaseAuthToken');
                  localStorage.removeItem('firebaseAuthTokenExpireTime');
                  navigate('/');
                } catch (error) {
                  console.error(error);
                }
              },
              sideBarAction: () => {
                setVisibleLeft(false);
                navigate('/team');
              },
              icon: (
                <FiLogOut style={{ marginRight: '8px', marginTop: '-4px' }} />
              ),
            },
          ],
        },
      ];
};

export const getMenuOptions = (urls, pathname, rotaPadActiveWorkSpace) => {
  return (
    <>
      {urls.map((url, index) => {
        return (
          <div
            key={index}
            className={`${urls.length === Number(index + 1) ? 'mb-auto' : ''}`}
          >
            <StyledH6 className='sidebar-heading d-flex justify-content-between align-items-center px-3 mt-3 mb-2 text-uppercase'>
              <span>{url.name}</span>
            </StyledH6>

            <ul className={`nav flex-column`}>
              {url.urls.map((path, index) => {
                return (
                  <li
                    key={index}
                    className='nav-item text-truncate'
                    onClick={path.action || path.sideBarAction}
                  >
                    <StyledSpan
                      className={`nav-link text-truncate`}
                      active={path?.path && path?.path.indexOf(pathname) === 0}
                    >
                      <span className='d-flex justify-content-between'>
                        <span>
                          {path.icon}
                          {path.name}
                        </span>
                        {path.counter && <span>{path.counter}</span>}
                      </span>
                    </StyledSpan>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}

      <div>
        <div className='dropdown'>
          <p style={{ fontSize: '10px', textAlign: 'center', color: '#999' }}>
            Powered by RotaPad APIs
          </p>
        </div>
      </div>
    </>
  );
};
