import styled from "@emotion/styled";
import { Button } from "primereact/button";
import { useCallback } from "react";
import { Field, Form } from "react-final-form";
import { TOAST_TYPE } from "../../common/constants";
import { useToast } from "../../common/hooks/useToast";
import { useApiService } from "../../context";
import TeamMemberAddress from "../../team/AddTeamMemberForm/TeamMemberAddress";

const StyledH5 = styled.h5`
	// border-width: 0 0 0 2px;
	// border-style: solid;
	// padding-left: 12px;
	// border-color: #6554c0;
	color: #6554c0;
`;

const UserAddressForm = ({ rotaPadUser }) => {
	const { user, createDoc, database, setRotaPadUser } = useApiService();

	const { triggerToast } = useToast();

	const validate = (data) => {
		let errors = {};

		// if (!data.firstname) {
		// 	errors.firstname = "First Name is required.";
		// }

		// if (!data.lastname) {
		// 	errors.lastname = "Last Name is required.";
		// }

		// if (!data.email) {
		// 	errors.email = "Email is required.";
		// } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
		// 	errors.email = "Invalid email address. E.g. example@email.com";
		// }

		return errors;
	};
	const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
	const getFormErrorMessage = (meta) => {
		return (
			isFormFieldValid(meta) && <small className='p-error'>{meta.error}</small>
		);
	};
	const handleSubmitUserDetails = useCallback(
		async (data, form) => {
			// console.log("rotaPadUser ====== ", rotaPadUser);
			if (rotaPadUser && user && user.uid) {
				const success = await createDoc(
					database,
					`users/${user.uid}`,
					{
						...rotaPadUser,
						...data,
						uid: user.uid,
					},
					setRotaPadUser
				);

				if (success) {
					triggerToast({
						message: "Profile successfully updated!",
						type: TOAST_TYPE.success,
					});
				} else {
					triggerToast({
						message: "Profile could not be updated!",
						type: TOAST_TYPE.warning,
					});
				}
			}
		},
		[createDoc, database, rotaPadUser, setRotaPadUser, triggerToast, user]
	);
	return (
		<div>
			<Form
				onSubmit={handleSubmitUserDetails}
				initialValues={{
					address: {},
				}}
				validate={validate}
				render={({
					handleSubmit,
					pristine,
					submitting,
					hasValidationErrors,
				}) => (
					<form onSubmit={handleSubmit} className='p-fluid'>
						<div className='d-flex flex-row mb-3'>
							<div className='col-xs-12 col-md-12'>
								<StyledH5 className='mb-3'>Update you location</StyledH5>
								<div>
									<Field
										name='address'
										label='address'
										component={TeamMemberAddress}
										labelPosition='right'
										visibleLeft={true}
										disabled={false}
										height='400px'
									/>
								</div>

								<Button
									type='submit'
									label='Update locations'
									className='mt-2'
									disabled={pristine || submitting || hasValidationErrors}
								/>
							</div>
							{/* <div className='col-xs-12 col-md-8 mb-4'>
								<div style={{ height: "500px" }}>
									<MapView rotaPadUser={rotaPadUser} />
								</div>
							</div> */}
						</div>
					</form>
				)}
			/>
		</div>
	);
};

export default UserAddressForm;
