import styled from '@emotion/styled';
import { useCallback, useRef } from 'react';
import { Form } from 'react-final-form';
import { generate as generateUUID } from 'short-uuid';
import { TOAST_TYPE } from '../../common/constants';
import { useToast } from '../../common/hooks/useToast';
import { useApiService } from '../../context';
import AddGroupForm from '../AddGroupForm';
import {
  StyledFooterDiv,
  StyledHeaderDiv,
} from '../../customers/AddCustomerFormContainer';
import { ButtonBase } from '../../common';
import { useSideBarClickAway } from '../../common/hooks/useSideBarClickAway';

export const StyledDropDownDiv = styled.div`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
  .customDropdown {
    width: 100%;
  }
  .customDropdown:not(.p-disabled):hover {
    border-color: #6366f1;
  }

  .customDropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    padding: 8px 8px;
    font-size: 12px;
  }

  .customDropdown .p-dropdown-trigger {
    width: 2rem;
  }

  .customDropdown .p-dropdown-trigger-icon {
    font-size: 10px;
  }

  .p-disabled,
  .p-component:disabled {
    opacity: 0.6;
    background-color: #e9ecef;
  }
`;

export const StyledInput = styled.input`
  padding: 8px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;

const StyledLabel = styled.label`
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
`;
const StyledH5 = styled.h5`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
`;
const StyledDiv = styled.div`
  right: 12px !important;
  top: 42px !important;

  i {
    cursor: pointer;
    z-index: 200;
    font-size: large;
    color: #6c757d;

    &:hover {
      color: #7f00ff;
    }
  }
`;

const AddGroupFormContainer = ({
  visibleLeft,
  setVisibleLeft,
  auth,
  newGroup,
  setNewGroup,
}) => {
  const { createDoc, database, rotaPadUser, setRotaPadGroups, fetchData } =
    useApiService();
  const { triggerToast } = useToast();
  const ref = useRef(null);
  useSideBarClickAway({
    ref,
    setVisibleLeft: () => setVisibleLeft(false),
    setNewGroup: () => setNewGroup({}),
  });

  const validate = (data) => {
    let errors = {};

    if (!data.name) {
      errors.name = 'Name is required.';
    }

    if (!data.handle) {
      errors.handle = 'Handle is required.';
    }

    return errors;
  };

  const handleSubmitForm = useCallback(
    async (data, form) => {
      // const { name, handle, description } = data;
      const uuid = data.uid ? data.uid : generateUUID();

      const newGroup = {
        ...data,
        uid: uuid,
      };
      if (rotaPadUser) {
        const success = await createDoc(database, `groups/${uuid}`, newGroup);

        // const fetchGroups = await fetchData({
        // 	db: database,
        // 	path: "groups",
        // });

        // // console.log("fetchGroups ===== ", fetchGroups);
        // if (fetchGroups) {
        // 	setRotaPadGroups(fetchGroups);
        // }

        if (success) {
          triggerToast({
            message: 'Group successfully created!',
            type: TOAST_TYPE.success,
          });
        } else {
          triggerToast({
            message: 'Group could not be created!',
            type: TOAST_TYPE.warning,
          });
        }

        form.reset();
        form.resetFieldState('name');
        form.resetFieldState('handle');
        form.resetFieldState('description');
        setNewGroup({});
        setVisibleLeft(false);
      }
    },
    [
      createDoc,
      database,
      fetchData,
      rotaPadUser,
      setNewGroup,
      setRotaPadGroups,
      setVisibleLeft,
      triggerToast,
    ]
  );
  return (
    <Form
      onSubmit={handleSubmitForm}
      initialValues={newGroup}
      validate={validate}
      render={({
        handleSubmit,
        pristine,
        submitting,
        hasValidationErrors,
        form,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div
              ref={ref}
              className={`offcanvas offcanvas-end  ${
                visibleLeft ? 'show shadow-lg' : 'hiding'
              }`}
              // tabIndex='-1'
              id='offcanvasExample'
              aria-labelledby='offcanvasExampleLabel'
              style={{ width: '24em', zIndex: 555 }}
            >
              <StyledHeaderDiv
                className='offcanvas-header'
                // style={{ backgroundColor: '#f5ebff' }}
              >
                <h5
                  className='offcanvas-title'
                  style={{ lineHeight: '27px' }}
                >
                  Create a user group
                </h5>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='offcanvas'
                  aria-label='Close'
                  onClick={() => {
                    setVisibleLeft(false);
                    form.reset();
                    form.resetFieldState('name');
                    form.resetFieldState('handle');
                    form.resetFieldState('description');
                    setNewGroup({});
                  }}
                ></button>
              </StyledHeaderDiv>
              <div
                style={{ maxHeight: 'calc(100vh - 118px)', overflow: 'auto' }}
              >
                <div className='offcanvas-body p-0'>
                  <div
                    className='d-flex flex-column mb-0'
                    style={{ height: '100vh' }}
                  >
                    <AddGroupForm
                      hasValidationErrors={hasValidationErrors}
                      submitting={submitting}
                      pristine={pristine}
                      handleSubmit={handleSubmit}
                      visibleLeft={visibleLeft}
                    />
                  </div>
                </div>
              </div>

              <StyledFooterDiv
                className='offcanvas-header'
                // style={{ backgroundColor: '#f5ebff' }}
              >
                <div className='btn-toolbar my-2 my-sm-0 my-md-0'>
                  <div className='d-flex flex-row mb-0'>
                    <div className='me-2'>
                      <ButtonBase
                        type='button'
                        variant='primary'
                        size='small'
                        onClick={() => {
                          setVisibleLeft(false);
                          form.reset();
                          form.resetFieldState('name');
                          form.resetFieldState('handle');
                          form.resetFieldState('description');
                          setNewGroup({});
                        }}
                      >
                        Cancel
                      </ButtonBase>
                    </div>
                    <div>
                      <ButtonBase
                        type='submit'
                        variant='primary'
                        size='small'
                        disabled={pristine || submitting || hasValidationErrors}
                      >
                        {`${newGroup.uid ? 'Update' : 'Create'} group`}
                      </ButtonBase>
                    </div>
                  </div>
                </div>
              </StyledFooterDiv>
            </div>
          </form>
        );
      }}
    />
  );
};

export default AddGroupFormContainer;
