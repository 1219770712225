import styled from '@emotion/styled';
import { Pill } from '../../../../common';
import { BADGE_STYLE } from '../../../../common/constants';
import { BsCaretRightFill } from 'react-icons/bs';
import { useContext, useMemo } from 'react';
import { ReportContext } from '../../../../reports/ReportContext';
import _ from 'lodash';
import { formatCurrency } from '../../../../common/utils';
import CustomerInvoiceTable from '../InvoiceTable';
import { useToggle } from 'react-use';

const EntryStyledDiv = styled.div`
  padding: 8px;
  border-bottom: 1px solid #e3e3e3;
  font-size: 12px;
  font-weight: 600;
`;
const EntryNameStyledDIv = styled.div`
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme[`primary`]};
  }
`;
const Entry = ({ unitId, name, computedShifts }) => {
  const [on, toggle] = useToggle(false);
  const {
    item: { payRate, sleepRate },
    expandAllUnits,
    setExpandAllUnits,
  } = useContext(ReportContext);

  const { count, totalDue, totalHours } = useMemo(() => {
    if (!computedShifts) return { count: 0, totalHours: 0, totalDue: 0 };
    // Get all attendees from shiftsCollection
    const attendeesCollection = _.reduce(
      computedShifts,
      function (sum, n) {
        const { attendees } = n;
        return attendees && attendees.length > 0
          ? [...sum, ...attendees]
          : [...sum];
      },
      []
    );

    // console.log('attendeesCollection === ', attendeesCollection);

    // Get all attendees hours
    const attendeesTotalHours = _.reduce(
      attendeesCollection,
      function (sum, n) {
        const { timeEntry } = n;
        return timeEntry ? sum + timeEntry : sum;
      },
      0
    );

    const getTotalSleep = _.reduce(
      attendeesCollection,
      function (sum, n) {
        const { sleepEntry } = n;
        return sleepEntry ? sum + sleepEntry : sum;
      },
      0
    );

    // console.log('attendeesTotalHours === ', attendeesTotalHours);

    return {
      count: computedShifts.length,
      totalHours: attendeesTotalHours,
      totalDue: payRate * attendeesTotalHours + sleepRate * getTotalSleep,
    };
  }, [computedShifts, payRate, sleepRate]);

  const renderTable = useMemo(() => {
    return (
      <CustomerInvoiceTable
        tableData={computedShifts}
        payRate={payRate}
        reportType='Invoice'
        unitName={name}
        sleepRate={sleepRate}
      />
    );
  }, [computedShifts, payRate, name, sleepRate]);
  return (
    <div>
      <EntryStyledDiv
        // key={unitId}
        className='d-flex justify-content-between align-items-center'
      >
        <EntryNameStyledDIv
          onClick={() => {
            setExpandAllUnits(false);
            toggle();
          }}
          className='d-flex justify-content-between align-items-center'
        >
          <BsCaretRightFill style={{ marginRight: '6px' }} />
          {name}
          <Pill
            content={`${computedShifts.length} ${
              computedShifts.length === 1 ? 'shift' : 'shifts'
            }`}
            bgStyle={{
              ...(computedShifts.length > 0
                ? BADGE_STYLE.Admin
                : BADGE_STYLE.Customer),
              marginBottom: 0,
              marginLeft: '8px',
            }}
            toolTipMessage={`${computedShifts.length} ${
              computedShifts.length === 1 ? 'shift' : 'shifts'
            }`}
          />
        </EntryNameStyledDIv>
        <div>{formatCurrency(totalDue || 0)}</div>
      </EntryStyledDiv>
      {(expandAllUnits || on) && <div>{renderTable}</div>}
    </div>
  );
};

export default Entry;
