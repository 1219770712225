import { useApiService } from "../../context";

const RenderPhoto = ({ placeholder }) => {
	const { profileImage } = useApiService();

	return (
		<img
			src={profileImage || placeholder}
			className='rounded-circle mr-2'
			style={{ width: "65px", height: "65px", cursor: "pointer" }}
			alt='user-img'
		/>
	);
};

export default RenderPhoto;
