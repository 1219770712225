import { useMemo, useState } from 'react';

import { useApiService } from '../../context';
import { convertObjectToArray } from '../../common/utils';
import Customer from '../Customer';
// import ReportCards from '../reportCards';
// import { REPORTS_CUSTOMERS } from 'authenticated-app/components/common/constants';

const CustomersContainer = ({ handleOpenSidebar, setPdfInvoicePayload }) => {
  const [expandCustomer, setExpandCustomer] = useState();
  const { rotaPadShiftsTopic, rotaPadCustomersTopic } = useApiService();
  // console.log(
  //   'rotaPadCustomersTopic ==== ',
  //   convertObjectToArray({ item: rotaPadCustomersTopic })
  // );
  const customersArray = useMemo(
    () => convertObjectToArray({ item: rotaPadCustomersTopic }),
    [rotaPadCustomersTopic]
  );
  return (
    <>
      {customersArray.length > 0 ? (
        <div className='mt-3 mb-0'>
          {customersArray.map((customer) => {
            return (
              <Customer
                key={customer.uid}
                item={customer}
                handleOpenSidebar={handleOpenSidebar}
                setPdfInvoicePayload={setPdfInvoicePayload}
                expandCustomer={expandCustomer}
                setExpandCustomer={setExpandCustomer}
              />
            );
          })}
        </div>
      ) : (
        <>Customer reports not available.</>
      )}
    </>
  );
};

export default CustomersContainer;
