import styled from '@emotion/styled';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { FaFacebookMessenger, FaSms } from 'react-icons/fa';
import { MdAttachEmail } from 'react-icons/md';
import { RiInstagramFill, RiWhatsappFill } from 'react-icons/ri';
import { TbWorldWww } from 'react-icons/tb';
import { ButtonBase, PageTitleBar } from '../common';
import { BADGE_STYLE } from '../common/constants';
import EmailImg from '../common/img/email.png';
import StarterImg from '../common/img/explore.png';
import FbImg from '../common/img/fb.png';
import IgImg from '../common/img/Ig.png';
import SmsImg from '../common/img/sms.png';
import WebsiteImg from '../common/img/website.png';
import WhatsAppImg from '../common/img/whatsapp.png';
import { useApiService } from '../context'; //
import Card from './Card';
import { generate as generateUUID } from 'short-uuid';
import AddCustomerFormContainer from './AddCustomerFormContainer';

const StyledCardContainer = styled.div`
  padding: 4px;
  span {
    font-weight: 500;
    font-size: 12px;
    color: #555;
  }
  &:hover {
    color: ${(props) => props.theme[`secondary-light-800`]};
    span {
      color: ${(props) => props.theme[`secondary-light-800`]};
    }
  }
`;
const StyledCard = styled.div`
  cursor: pointer;
  width: 12rem;
  font-size: 12px;
  padding-top: 20px;
  min-height: 276px;
  background-color: #fff;
  border: ${(props) => props.theme[`secondary-light-600`]} solid 3px;
  img {
    width: 170px;
    opacity: 40%;
  }

  div > svg {
    font-size: 34px;
    color: ${(props) => props.theme[`primary-200`]};
    opacity: 60%;
  }
  &:hover {
    border: ${(props) => props.theme.cardHoverBorder} solid 3px;
    background-color: #fff;
    div > svg {
      color: ${(props) => props.theme.cardHoverBorder};
      opacity: 80%;
    }
    img {
      opacity: 80%;
    }
  }

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
const StyleViewBtn = styled.button`
  line-height: 14px;
  text-transform: uppercase;
  font-size: 11px !important;
`;
const StyledInput = styled.input`
  font-size: 12px !important;
  line-height: 15px;
`;
const StyledInfoDiv = styled.div`
  // cursor: pointer;
  font-size: 12px;
  background: #f3fbf5;
  &:hover {
    background: #fff;
  }
`;

const StyledTemplatesDiv = styled.div`
  background: #ffc1071c;
  border-radius: 13px;
  width: 100%;
  overflow-x: auto;
`;
const StyleNamesSpan = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: #555;
`;

const StyledHeaders = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #444;
  letter-spacing: 0.0543215rem;
`;
const Customers = () => {
  const { rotaPadUser, auth, rotaPadCustomersTopic } = useApiService();
  const [visibleLeft, setVisibleLeft] = useState(false);
  const [newGroup, setNewGroup] = useState({
    id: generateUUID(),
    customerId: '',
    name: '',
    address: '',
    // createdAt: moment().format(),
    disabled: false,
    units: [],
    payRate: 0,

    sleepRate: 0,
    createdAt: moment().format(),
  });

  if (!rotaPadUser) return null;

  const WORKFLOW_TEMPLATES = [
    {
      title: '+ SMS',
      description: `Some quick example text to build on the card title and make up the bulk of the card's content.`,
      icon: <FaSms />,
      image: SmsImg,
    },
    {
      title: '+ Facebook Messenger',
      description: `Some quick example text to build on the card title and make up the bulk of the card's content.`,
      icon: <FaFacebookMessenger />,
      image: FbImg,
    },
    {
      title: '+ WhatsApp',
      description: `Some quick example text to build on the card title and make up the bulk of the card's content.`,
      icon: <RiWhatsappFill />,
      image: WhatsAppImg,
    },
    {
      title: '+ Instagram',
      description: `Some quick example text to build on the card title and make up the bulk of the card's content.`,
      icon: <RiInstagramFill />,
      image: IgImg,
    },
    {
      title: '+ Email',
      description: `Some quick example text to build on the card title and make up the bulk of the card's content.`,
      icon: <MdAttachEmail />,
      image: EmailImg,
    },
    {
      title: '+ Website',
      description: `Some quick example text to build on the card title and make up the bulk of the card's content.`,
      icon: <TbWorldWww />,
      image: WebsiteImg,
    },
  ];

  // console.log('rotaPadCustomersTopic ===== ', rotaPadCustomersTopic);
  return (
    <>
      <AddCustomerFormContainer
        visibleLeft={visibleLeft}
        setVisibleLeft={setVisibleLeft}
        auth={auth}
        newGroup={newGroup}
        setNewGroup={setNewGroup}
      />
      <div className='container-fluid mx-0 px-0'>
        <div className='row'>
          <div className='col-12 mt-0'>
            <PageTitleBar
              title='Customers'
              count={
                _.isObject(rotaPadCustomersTopic)
                  ? Object.keys(rotaPadCustomersTopic).length
                  : `0`
              }
              badgeStyle={BADGE_STYLE.Menu}
              handleClickAdd={() => setVisibleLeft(true)}
            />

            {_.isObject(rotaPadCustomersTopic) &&
            Object.keys(rotaPadCustomersTopic).length > 0 ? (
              <div className='container-fluid py-2 px-0'>
                <div className='row g-3 '>
                  {Object.keys(rotaPadCustomersTopic).map((key) => {
                    return (
                      <Card
                        key={key}
                        setNewGroup={setNewGroup}
                        setVisibleLeft={setVisibleLeft}
                        {...rotaPadCustomersTopic[key]}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <>
                <div className='card mt-3 text-center'>
                  <div className='pt-4 pb-2 text-muted'>
                    <img src={StarterImg} />
                  </div>
                  <div className='d-flex justify-content-center px-4 mb-2'>
                    <div className='text-muted w-50 text-center'>
                      <div className=''>
                        Turn your team’s tracked time and expenses into invoices
                        and collect payment quickly from your customers.
                      </div>
                      <div className='pt-4 mb-4'>
                        <ButtonBase
                          type='button'
                          variant='primary'
                          size='default'
                          className='w-50'
                          onClick={() => {
                            setNewGroup({
                              id: generateUUID(),
                              customerId: '',
                              name: '',
                              address: '',
                              // createdAt: moment().format(),
                              disabled: false,
                              units: [],
                              payRate: 0,
                              sleepRate: 0,
                              createdAt: moment().format(),
                            });
                            setVisibleLeft(true);
                          }}
                        >
                          <i
                            className='pi pi-user-plus'
                            style={{
                              fontSize: '12px',
                              marginRight: '4px',
                            }}
                          ></i>
                          Add new customer
                        </ButtonBase>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Customers;
