import styled from '@emotion/styled';
import { AiOutlineMenu } from 'react-icons/ai';
import { FiHelpCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useBranding } from '../../common/hooks/useBranding';
import { useApiService } from '../../context';
import ButtonBase from '../ButtonBase';
import { URLS } from '../constants';
import ToolTip from '../ToolTip';
import Logo from '../../common/img/logo.png';

const StyledSpan = styled.span`
  color: #7f00ff;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: #af97d8;
  }
`;

const StyledLogo = styled.img`
  margin-left: 8px;
  width: 50%;
  height: 50%;
`;
const HeaderNav = ({ handleShowSideBar }) => {
  const navigate = useNavigate();
  const {
    rotaPadUser,
    profileImage,
    user: { email },
    rotaPadAccountSettingsTopic,
  } = useApiService();

  const { profile } = rotaPadAccountSettingsTopic || { profile: { name: '' } };
  const { logo } = useBranding();
  const { firstname, lastname, group } = rotaPadUser || {};
  const { name } = profile;
  // console.log('rotaPadAccountSettingsTopic ==== ', rotaPadAccountSettingsTopic);
  return (
    <header
      className='navbar navbar-light sticky-top flex-md-nowrap py-2 border-bottom '
      style={{ backgroundColor: '#fff', zIndex: 0 }}
    >
      <div className='navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6'>
        <div className='d-flex flex-row align-items-center'>
          <div
            className='d-block d-xs-block d-sm-block d-lg-none d-md-none'
            style={{ marginRight: '12px', marginBottom: '2px' }}
            onClick={() => handleShowSideBar(true)}
          >
            <AiOutlineMenu />
          </div>
          <div className='text-start'>
            {/* <img
              src={logo?.fullLogo}
              alt='Logo'
              style={{ height: '33px' }}
            /> */}
            <img
              src={Logo}
              width='140'
              height='32'
              alt='ROTA PAD logo'
            />
          </div>
          {/* <div>{rotaPadActiveWorkSpace?.name}</div> */}
        </div>
      </div>

      <div className='navbar-nav me-0 pe-3 pe-sm-4 pe-md-4 fs-6'>
        <div className='nav-item text-nowrap'>
          <div className='d-flex flex-row'>
            {/* <div className='d-flex align-items-center'>
              <ToolTip
                placement='bottom'
                overlay={<span style={{ fontSize: 10 }}>Go to dashboard</span>}
              >
                <ButtonBase
                  type='button'
                  className='me-0'
                  // onClick={() => setVisibleLeft(true)}
                  onClick={() => {
                    // setRotaPadActiveWorkSpace();
                    navigate(URLS.dashboard);
                  }}
                >
                  Dashboard
                </ButtonBase>
              </ToolTip>
            </div> */}
            {/* <div className='d-flex align-items-center'>
              <ToolTip
                placement='bottom'
                overlay={<span style={{ fontSize: 10 }}>Learning center</span>}
              >
                <div
                  type='button'
                  className=' mx-4'
                  // onClick={() => setVisibleLeft(true)}
                >
                  <FiHelpCircle style={{}} />
                </div>
              </ToolTip>
            </div> */}

            <div
              className='d-flex flex-column'
              style={{
                fontSize: '12px',
                textAlign: 'right',
                marginRight: '8px',
              }}
            >
              <div>
                {firstname && lastname ? firstname + ' ' + lastname : email}
              </div>
              <div style={{ color: '#7a7a7a' }}>
                {group && name ? (
                  <>{`${name} · ${group}`}</>
                ) : (
                  <StyledSpan onClick={() => navigate(URLS.profile)}>
                    Update profile
                  </StyledSpan>
                )}
              </div>
            </div>

            <div className='d-flex align-items-center'>
              <ToolTip
                placement='bottom'
                overlay={<span style={{ fontSize: 10 }}>View profile</span>}
              >
                <img
                  src={profileImage}
                  className='rounded-circle mr-2 border'
                  style={{ width: '35px', height: '35px', cursor: 'pointer' }}
                  alt='user-img'
                  onClick={() => navigate(URLS.profile)}
                />
              </ToolTip>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderNav;
