import styled from "@emotion/styled";
import { useCallback } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOpenInNew } from "react-icons/md";
import { RiEditLine } from "react-icons/ri";
import { Card as PageCard, Pill, RoundButtonBase, ToolTip } from "../../common";
import { BADGE_STYLE } from "../../common/constants";
import { useApiService } from "../../context";

const RoundedPill = styled.div`
	padding: 7px;
	color: rgb(255, 255, 255);
	background-color: rgba(89, 161, 49, 0.66);
	font-size: 12px;
`;

const RoundedCircle = styled.div`
	padding: 4px;
	background: #0000004f;
	line-height: 7px;
	cursor: pointer;
	&:hover {
		background: #00000098;
	}
`;

const TimeCheckStyledDiv = styled.span`
	background: #00000036;
	border-radius: 5px;
	padding: 2px;
	margin-left: 8px;
	font-size: 11px;
	letter-spacing: 0.04rem;
	&:hover {
		background: #000000a1;
	}
`;

const StyledCard = styled.div`
	cursor: pointer;
	// width: 15rem;
	font-size: 12px;
	padding-top: 0px;
	min-height: 211px;
	border: #af97d8 solid 3px;
	img {
		width: 50%;
	}

	div > svg {
		font-size: 34px;
		color: #af97d8;
	}
	&:hover {
		border: #b2d897 solid 3px;

		div > svg {
			color: #b2d897;
		}
	}
`;
const StyledDescriptionDiv = styled.div`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
`;

const Card = ({
	email,
	firstname,
	lastname,
	group,
	role,
	uid,
	setNewGroup,
	setVisibleLeft,
}) => {
	const { deleteDoc, setRotaPadInvitations, fetchData, database } =
		useApiService();

	const handleClick = useCallback(async () => {
		const DeleteGroup = await deleteDoc({
			db: database,
			path: "invitations/" + uid,
		});

		const fetchGroups = await fetchData({
			db: database,
			path: "invitations",
		});

		// console.log("invitations ===== ", fetchGroups);
		if (fetchGroups) {
			setRotaPadInvitations(fetchGroups);
		}
	}, [database, deleteDoc, fetchData, setRotaPadInvitations, uid]);
	return (
		<>
			{" "}
			<div className='col-xs-12 col-sm-6 col-md-4 col-lg-3 px-0'>
				<PageCard className='card shadow-sm  mb-3 me-3'>
					<div className='card-header bg-transparent pt-3'>
						{email}
						<div>{firstname + " " + lastname}</div>
					</div>
					<div className='card-body '>
						<Pill
							content={role}
							bgStyle={{ ...BADGE_STYLE[role] }}
							toolTipMessage={role}
						/>
						<Pill
							content={group}
							bgStyle={{ ...BADGE_STYLE.Group }}
							toolTipMessage={group}
						/>
					</div>
					<div className='card-footer bg-transparent '>
						<ToolTip
							placement='top'
							overlay={<span style={{ fontSize: 10 }}>Open</span>}>
							<RoundButtonBase type='button' size='small' className={` me-2 `}>
								<MdOpenInNew />
							</RoundButtonBase>
						</ToolTip>
						<ToolTip
							placement='top'
							overlay={<span style={{ fontSize: 10 }}>Update</span>}>
							<RoundButtonBase
								onClick={() => {
									setNewGroup({
										email,
										firstname,
										lastname,
										group,
										role,
										uid,
									});
									setVisibleLeft(true);
								}}
								type='button'
								size='small'
								className={`me-2 `}>
								<RiEditLine />
							</RoundButtonBase>
						</ToolTip>
						<ToolTip
							placement='top'
							overlay={<span style={{ fontSize: 10 }}>Delete</span>}>
							<RoundButtonBase
								type='button'
								onClick={handleClick}
								size='small'
								className={` me-2 `}>
								<AiOutlineDelete />
							</RoundButtonBase>
						</ToolTip>
					</div>
				</PageCard>
			</div>
		</>
	);
};

export default Card;
