import { formatCurrency, formatHour } from 'components/common/utils';
import _ from 'lodash';
import moment from 'moment';

export const HeaderFooter = ({ address, email, name, phone, website }) => {
  return {
    header: {
      columns: [
        { text: '', style: 'documentHeaderLeft' },
        { text: '', style: 'documentHeaderCenter' },
        { text: '', style: 'documentHeaderRight' },
      ],
    },
    footer: {
      columns: [
        { text: '', style: 'documentFooterLeft' },
        {
          text: `${name}
        ${address} Email: ${email}; Tel: ${phone}; Website: ${website}`,
          style: 'documentFooterCenter',
        },
        { text: '', style: 'documentFooterRight' },
      ],
    },
  };
};

export const DocumentStyles = () => {
  return {
    styles: {
      // Document Header
      documentHeaderLeft: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: 'left',
      },
      documentHeaderCenter: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: 'center',
      },
      documentHeaderRight: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: 'right',
      },
      // Document Footer
      documentFooterLeft: {
        fontSize: 10,
        margin: [15, 15, 15, 15],
        alignment: 'left',
      },
      documentFooterCenter: {
        fontSize: 8,
        // margin: [0, 0, 0, 0],
        alignment: 'center',
      },
      documentFooterRight: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: 'right',
      },
      // Invoice Title
      invoiceTitle: {
        fontSize: 22,
        bold: true,
        alignment: 'right',
        margin: [0, 0, 0, 15],
      },

      customerBillingToTitle: {
        fontSize: 12,
        bold: true,
        alignment: 'right',
        margin: [0, 20, 0, 5],
      },
      customerBillingToValue: {
        fontSize: 12,
        // bold: true,
        alignment: 'right',
        // margin: [0, 20, 0, 5],
      },
      // Invoice Details
      invoiceSubTitle: {
        fontSize: 12,
        alignment: 'right',
      },
      invoiceSubValue: {
        fontSize: 12,
        alignment: 'right',
      },
      invoiceNumber: {
        fontSize: 9,
        alignment: 'right',
        color: '#f36e00',
        margin: [0, 0, 0, 5],
      },
      // Billing Headers
      invoiceBillingTitle: {
        fontSize: 12,
        bold: true,
        alignment: 'left',
        margin: [0, 20, 0, 5],
      },
      comapnyTitle: {
        fontSize: 12,
        bold: true,
        alignment: 'left',
        color: '#f36e00',
        // margin: [0, 20, 0, 5],
      },
      // Billing Details
      invoiceBillingDetails: {
        alignment: 'left',
      },
      invoiceBillingAddressTitle: {
        margin: [0, 7, 0, 3],
        bold: true,
      },
      invoiceBillingAddress: {},
      // Items Header
      itemsHeader: {
        margin: [0, 5, 0, 5],
        bold: false,
        fontSize: 8,
      },

      tableItemsHeader: {
        margin: [0, 5, 0, 5],
        bold: true,
        fontSize: 8,
      },
      itemsTotals: {
        margin: [0, 5, 0, 5],
        bold: true,
        fontSize: 9,
        color: 'red',
      },
      // Item Title
      itemTitle: {
        bold: true,
      },
      itemSubTitle: {
        italics: true,
        fontSize: 11,
      },
      itemNumber: {
        margin: [0, 5, 0, 5],
        alignment: 'center',
      },
      itemTotal: {
        margin: [0, 5, 0, 5],
        bold: true,
        alignment: 'center',
      },

      // Items Footer (Subtotal, Total, Tax, etc)
      itemsFooterSubTitle: {
        margin: [0, 5, 0, 5],
        bold: true,
        alignment: 'right',
      },
      itemsFooterSubValue: {
        margin: [0, 5, 0, 5],
        bold: true,
        alignment: 'center',
      },
      itemsFooterTotalTitle: {
        margin: [0, 5, 0, 5],
        bold: true,
        alignment: 'right',
      },
      itemsFooterTotalValue: {
        margin: [0, 5, 0, 5],
        bold: true,
        alignment: 'center',
      },
      signaturePlaceholder: {
        margin: [0, 70, 0, 0],
      },
      signatureName: {
        bold: true,
        alignment: 'center',
      },
      signatureJobTitle: {
        italics: true,
        fontSize: 10,
        alignment: 'center',
      },
      notesTitle: {
        fontSize: 10,
        bold: true,
        margin: [0, 50, 0, 3],
      },
      notesText: {
        fontSize: 10,
      },
      center: {
        alignment: 'center',
      },
    },
    defaultStyle: {
      columnGap: 20,
      // font: 'arial',
    },
  };
};

const GetUnitTableItemsTotal = ({
  computedShifts,
  payRate,
  sleepRate,
  unitName,
}) => {
  const attendeesCollection = _.reduce(
    computedShifts,
    function (sum, n) {
      const { attendees } = n;
      return attendees && attendees.length > 0
        ? [...sum, ...attendees]
        : [...sum];
    },
    []
  );

  // console.log('attendeesCollection === ', attendeesCollection);

  // Get all attendees hours
  const attendeesTotalHours = _.reduce(
    attendeesCollection,
    function (sum, n) {
      const { timeEntry } = n;
      return timeEntry ? sum + timeEntry : sum;
    },
    0
  );

  const getTotalSleep = _.reduce(
    attendeesCollection,
    function (sum, n) {
      const { sleepEntry } = n;
      return sleepEntry ? sum + sleepEntry : sum;
    },
    0
  );

  return [
    {
      text: `Grand Total for ${unitName}`,
      colSpan: 6,
      bold: true,
      fontSize: 10,
      color: 'red',
      margin: [0, 5, 0, 5],
    },
    {},
    {},
    {},
    {},
    {},
    {
      text: `${formatCurrency(
        payRate * attendeesTotalHours + sleepRate * getTotalSleep
      )}`,
      style: 'itemsTotals',
    },
    { text: '0%', style: 'itemsTotals' },
    {},
    {},
  ];
};
const GetUnitTableItems = ({ computedShifts, payRate, sleepRate }) => {
  const items = computedShifts.map(({ start, attendees }) => {
    const attendeesList =
      attendees && attendees.length > 0
        ? _.reduce(
            attendees,
            function (sum, n) {
              const { firstname, lastname } = n;
              return sum + `${firstname} ${lastname}`;
            },
            ''
          )
        : '-';

    const getTotalHours = _.reduce(
      attendees || [],
      function (sum, n) {
        const { timeEntry } = n;
        return timeEntry ? sum + timeEntry : sum;
      },
      0
    );

    const getTotalSleep = _.reduce(
      attendees || [],
      function (sum, n) {
        const { sleepEntry } = n;
        return sleepEntry ? sum + sleepEntry : sum;
      },
      0
    );

    return [
      {
        text: `${moment(start).format('DD/MM/YYYY')}`,
        style: 'itemsHeader',
      },
      {
        text: `${attendeesList}`,
        style: 'itemsHeader',
      },
      {
        text: `${getTotalHours !== 0 ? formatHour(getTotalHours) : '-'}`,
        style: 'itemsHeader',
      },
      {
        text: `${getTotalHours !== 0 ? formatCurrency(payRate) : '-'}`,
        style: 'itemsHeader',
      },
      {
        text: `${getTotalSleep !== 0 ? formatHour(getTotalSleep) : '-'}`,
        style: 'itemsHeader',
      },
      {
        text: `${getTotalSleep !== 0 ? formatCurrency(sleepRate) : '-'}`,
        style: 'itemsHeader',
      },
      {
        text: `${formatCurrency(
          payRate * getTotalHours + sleepRate * getTotalSleep
        )}`,
        style: 'itemsHeader',
      },
      {
        text: '0%',
        style: 'itemsHeader',
      },
      {
        text: '',
        style: 'itemsHeader',
      },
      {
        text: '',
        style: 'itemsHeader',
      },
    ];
  });
  return items;
};
const GetUnitTable = ({
  computedShifts,
  unitName,
  payRate,
  sleepRate,
  dateStr,
}) => {
  if (_.isEmpty(computedShifts)) {
    return {
      margin: [0, 0, 0, 10],
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex % 2 === 0 ? '#b5d872' : '#f5f5f5';
        },
      },
      table: {
        widths: ['100%'],
        heights: [16],
        body: [
          [
            {
              text: 'BLANK',
              fontSize: 16,
              bold: true,
              alignment: 'center',
            },
          ],
          // [
          //   {
          //     text: 'FUNÇÃO: DIRETOR DE ENSINO',
          //     fontSize: 9,
          //     bold: true,
          //   },
          // ],
        ],
      },
    };
  }

  // console.log('GetUnitTable computedShifts ====== ', computedShifts);
  return {
    table: {
      // headers are automatically repeated if the table spans over multiple pages
      // you can declare how many rows should be treated as headers
      headerRows: 1,
      widths: ['auto', '*', 40, 'auto', 'auto', 'auto', 60, 'auto', 'auto', 60],
      // heights: [5],

      body: [
        // Table Header
        [
          {
            text: 'Date',
            style: 'tableItemsHeader',
          },
          {
            text: 'Staff name',
            style: 'tableItemsHeader',
          },
          {
            text: 'Hrs',
            style: 'tableItemsHeader',
          },
          {
            text: 'Rate Type',
            style: 'tableItemsHeader',
          },
          {
            text: 'Sleep',
            style: 'tableItemsHeader',
          },
          {
            text: 'Sleep Rate',
            style: 'tableItemsHeader',
          },
          {
            text: 'Total',
            style: 'tableItemsHeader',
          },
          {
            text: 'VAT',
            style: 'tableItemsHeader',
          },
          {
            text: 'Shift',
            style: 'tableItemsHeader',
          },
          {
            text: 'Comment',
            style: 'tableItemsHeader',
          },
        ],
        // Items
        // Item 1

        ...GetUnitTableItems({ computedShifts, payRate, sleepRate }),
        // Item 2

        GetUnitTableItemsTotal({
          computedShifts,
          payRate,
          sleepRate,
          unitName,
        }),
        // [
        //   {
        //     text: `Grand Total for ${unitName}`,
        //     colSpan: 6,
        //     bold: true,
        //     fontSize: 10,
        //     color: 'red',
        //   },
        //   {},
        //   {},
        //   {},
        //   {},
        //   {},
        //   {
        //     text: '$999.99',
        //     style: 'itemsTotals',
        //   },
        //   { text: '0%', style: 'itemsTotals' },
        //   {},
        //   {},
        // ],
        // END Items
      ],
    },
  };
};
export const PageBuilder = ({
  companyLogo,
  address,
  bank,

  name,
  customerName,
  customerAddress,
  customerId,
  units,
  computedShifts,
  payRate,
  sleepRate,
  date,
}) => {
  const dateStr = `${moment(date[0]).format('DD/MM/YYYY')} - ${moment(
    date[1]
  ).format('DD/MM/YYYY')}`;

  // Get all attendees from shiftsCollection
  const attendeesCollection = _.reduce(
    computedShifts,
    function (sum, n) {
      const { attendees } = n;
      return attendees && attendees.length > 0
        ? [...sum, ...attendees]
        : [...sum];
    },
    []
  );

  const attendeesTotalHours = _.reduce(
    attendeesCollection,
    function (sum, n) {
      const { timeEntry } = n;
      return timeEntry ? sum + timeEntry : sum;
    },
    0
  );

  const getTotalSleep = _.reduce(
    attendeesCollection,
    function (sum, n) {
      const { sleepEntry } = n;
      return sleepEntry ? sum + sleepEntry : sum;
    },
    0
  );

  const pages = _.reduce(
    units.filter((unit) => _.find(computedShifts, { customer: unit.id })),
    function (sum, n, index) {
      // console.log('index ==== ', index);
      const { name: unitName, id: unitId } = n;
      if (Number(index) === 0) {
        const pageHeaderWithSummary = [
          {
            columns: [
              [
                {
                  image: companyLogo,
                  width: 100,
                },
                // {
                //   text: 'company name',
                //   style: 'invoiceBillingTitle',
                // },
              ],

              [
                {
                  text: 'INVOICE',
                  style: 'invoiceTitle',
                  width: '*',
                },
                {
                  stack: [
                    {
                      columns: [
                        {
                          text: '',
                          style: 'invoiceNumber',
                          width: '*',
                        },
                        {
                          text: `INVOICE #\n ${dateStr}\n`,
                          style: 'invoiceNumber',
                          width: '*',
                        },
                      ],
                    },
                    {
                      columns: [
                        {
                          text: 'Date Issued',
                          style: 'invoiceSubTitle',
                          width: '*',
                        },
                        {
                          text: moment().format('MMM DD, YYYY'),
                          style: 'invoiceSubValue',
                          width: 100,
                        },
                      ],
                    },
                    {
                      columns: [
                        {
                          text: 'Due Date',
                          style: 'invoiceSubTitle',
                          width: '*',
                        },
                        {
                          text: '-',
                          style: 'invoiceSubValue',
                          width: 100,
                        },
                      ],
                    },
                  ],
                },
              ],
            ],
          },
          {
            columns: [
              [
                {
                  text: 'Billing From',
                  style: 'invoiceBillingTitle',
                },
              ],

              [
                {
                  text: 'Billing To',
                  style: 'customerBillingToTitle',
                },
              ],
            ],
          },

          {
            columns: [
              [
                {
                  text: `${name} \n ${address}`,
                  // style: 'invoiceBillingTitle',
                },
              ],

              [
                {
                  text: `${customerName} \n ${customerAddress} \n Customer ID: ${customerId}`,
                  style: 'customerBillingToValue',
                },
              ],
            ],
          },
          // Billing Headers

          // Billing Address Title
          {
            columns: [
              {
                text: 'Make all transfer to',
                style: 'invoiceBillingAddressTitle',
              },
            ],
          },
          // Billing Address
          {
            columns: [
              {
                text: `${bank}`,
                style: 'invoiceBillingAddress',
              },
              // {
              //   text: '1111 Other street 25 \n New-York City NY 00000 \n   USA',
              //   style: 'invoiceBillingAddress',
              // },
            ],
          },

          //INVOICE TOTAL
          '\n\n',
          {
            margin: [0, 0, 0, 10],
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return rowIndex % 2 === 0 ? '#fff' : '#bcfb64';
              },
            },
            table: {
              widths: ['100%'],
              heights: [10, 20],
              body: [
                [
                  {
                    text: `Invoice for WC – ${dateStr}`,
                    fontSize: 9,
                    bold: true,
                  },
                ],
                [
                  {
                    text: `Invoice ${dateStr} Grand Total: ${formatCurrency(
                      payRate * attendeesTotalHours + sleepRate * getTotalSleep
                    )}`,
                    fontSize: 14,
                    bold: true,
                  },
                ],
              ],
            },
          },

          {
            margin: [0, 0, 0, 10],
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return rowIndex % 2 === 0 ? '#f89494' : '#f5f5f5';
              },
            },
            table: {
              widths: ['100%'],
              heights: [10],
              body: [
                [
                  {
                    text: `Invoice for ${unitName} - ${dateStr}`,
                    fontSize: 12,
                    bold: true,
                  },
                ],
                // [
                //   {
                //     text: 'FUNÇÃO: DIRETOR DE ENSINO',
                //     fontSize: 9,
                //     bold: true,
                //   },
                // ],
              ],
            },
          },

          {
            // if _.find(computedShifts, {customer: unitId})
            ...GetUnitTable({
              computedShifts: _.filter(computedShifts, { customer: unitId }),
              unitName,
              payRate,
              sleepRate,
              dateStr,
            }),
            // table
            //  layout: 'lightHorizontalLines'
          },
        ];

        return [...pageHeaderWithSummary, ...sum];
      } else {
        const pageHeaderWithOutSummary = [
          { text: '', pageBreak: 'before' },

          {
            columns: [
              [
                {
                  image: companyLogo,
                  width: 100,
                },
                {
                  text: 'RotaPad Inc.',
                  style: 'comapnyTitle',
                },
              ],
            ],
          },
          '\n\n',
          {
            margin: [0, 0, 0, 10],
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return rowIndex % 2 === 0 ? '#f89494' : '#f5f5f5';
              },
            },
            table: {
              widths: ['100%'],
              heights: [10],
              body: [
                [
                  {
                    text: `Invoice for ${unitName} - ${dateStr}`,
                    fontSize: 12,
                    bold: true,
                  },
                ],
                // [
                //   {
                //     text: 'FUNÇÃO: DIRETOR DE ENSINO',
                //     fontSize: 9,
                //     bold: true,
                //   },
                // ],
              ],
            },
          },

          {
            ...GetUnitTable({
              computedShifts: _.filter(computedShifts, { customer: unitId }),
              unitName,
              payRate,
              sleepRate,
              dateStr,
            }),
          },
        ];
        return [...sum, ...pageHeaderWithOutSummary];
      }
      // return sum + n;
    },
    []
  );
  return pages;
};

const GetDateRange = (start, end) => {
  let dateArr = []; //Array where dates will be stored

  let fromDate = new Date(moment(start).format('MM-DD-YYYY')); //From Date

  //Let's assume the endDate to be 15 days later from today
  let endDate = new Date(moment(end).format('MM-DD-YYYY'));

  //Logic for getting rest of the dates between two dates("FromDate" to "EndDate")
  while (fromDate <= endDate) {
    dateArr.push(moment(fromDate).format());
    let newDate = fromDate.setDate(fromDate.getDate() + 1);
    fromDate = new Date(newDate);
  }
  return dateArr;
};
const GetTeamMemberTableItems = ({
  date,
  computedShifts,
  teamMembers,
  email,
  firstname,
  lastname,
}) => {
  // console.log('GetDateRange ===== ', GetDateRange(date[0], date[1]));
  // console.log('computedShifts ======= ', computedShifts);
  // console.log('email ======= ', email);
  const days = GetDateRange(date[0], date[1]);
  const items = days.map((dayStr) => {
    const getShifts = computedShifts.filter((shift) => {
      const { attendees, start } = shift;
      return (
        _.find(attendees, { email }) &&
        moment(dayStr).format('dddd') === moment(start).format('dddd')
      );
    });

    const getShift = getShifts && getShifts.length === 1 ? getShifts[0] : null;

    const teamMemberTimeEntry = getShift
      ? _.find(getShift.attendees, { email })?.timeEntry
      : 0;

    const teamMemberStartTimeEntry = getShift
      ? moment(_.find(getShift.attendees, { email })?.startTimeEntry).format(
          'h:mm a'
        )
      : '-';

    const teamMemberEndTimeEntry = getShift
      ? moment(_.find(getShift.attendees, { email })?.endTimeEntry).format(
          'h:mm a'
        )
      : '-';

    const teamMemberSleepEntry = getShift
      ? _.find(getShift.attendees, { email })?.sleepEntry || 0
      : 0;

    return [
      {
        text: `${moment(dayStr).format('dddd')}`,
        style: 'itemsHeader',
      },
      {
        text: `${moment(dayStr).format('DD/MM/YYYY')}`,
        style: 'itemsHeader',
      },
      {
        text: `${teamMemberStartTimeEntry}`,
        style: 'itemsHeader',
      },
      {
        text: `${teamMemberEndTimeEntry}`,
        style: 'itemsHeader',
      },
      {
        text: `${formatHour(teamMemberTimeEntry)}`,
        style: 'itemsHeader',
      },
      {
        text: `${formatHour(teamMemberSleepEntry)}`,
        style: 'itemsHeader',
      },
      {
        text: `${getShift ? getShift.type : '-'}`,
        style: 'itemsHeader',
      },
      {
        text: `-`,
        style: 'itemsHeader',
      },
      {
        text: '-',
        style: 'itemsHeader',
      },
    ];
  });
  return items;
};

export const WorkSheetPageBuilder = ({
  teamMembers,
  companyLogo,
  unitName,
  unitAddress,
  date,
  computedShifts,
}) => {
  const dateStr = `${moment(date[0]).format('DD/MM/YYYY')} - ${moment(
    date[1]
  ).format('DD/MM/YYYY')}`;
  const attendeesCollection = _.reduce(
    computedShifts,
    function (sum, n) {
      const { attendees } = n;
      return attendees && attendees.length > 0
        ? [...sum, ...attendees]
        : [...sum];
    },
    []
  );

  // console.log(attendeesCollection);
  const pages = _.reduce(
    teamMembers.filter((teamMember) =>
      _.find(attendeesCollection, { email: teamMember.email })
    ),
    function (sum, n, index) {
      const { firstname, lastname, group, email } = n;
      const page = [
        { ...(Number(index) === 0 ? {} : { text: '', pageBreak: 'before' }) },
        {
          columns: [
            [
              {
                image: companyLogo,
                width: 100,
              },
              {
                text: 'RotaPad Inc.',
                style: 'comapnyTitle',
              },
            ],
          ],
        },
        '\n',
        {
          text: 'Weekly Timesheet',
          style: 'header',
          fontSize: 18,
          bold: true,
          margin: [10, 0, 0, 10],
          alignment: 'center',
        },
        '\n',
        {
          margin: [0, 0, 0, 10],
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex % 2 === 0 ? '#f5f5f5' : '#f5f5f5';
            },
          },
          table: {
            widths: ['*', '*'],
            heights: [10],
            body: [
              [
                {
                  text: 'Week Ending',
                  fontSize: 9,
                  bold: true,
                },
                {
                  text: `${dateStr}`,
                  fontSize: 9,
                  bold: true,
                },
              ],
            ],
          },
        },
        {
          margin: [0, 0, 0, 10],
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex % 2 === 0 ? '#f5f5f5' : '#f5f5f5';
            },
          },
          table: {
            widths: ['*', '*'],
            heights: [10],
            body: [
              [
                {
                  text: `Employee name: ${firstname} ${lastname}`,
                  fontSize: 9,
                  bold: true,
                },
                {
                  text: `Client Name: ${unitName}`,
                  fontSize: 9,
                  bold: true,
                },
              ],
              [
                {
                  text: `Title: ${group}`,
                  fontSize: 9,
                  bold: true,
                },
                {
                  text: `Client Address: ${unitAddress.trim()}`,
                  fontSize: 9,
                  bold: true,
                },
              ],
            ],
          },
        },
        // '\n',
        // Items
        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              '*',
              // 'auto',
              '*',
            ],
            // heights: [5],

            body: [
              // Table Header
              [
                {
                  text: 'Day',
                  style: 'tableItemsHeader',
                },
                {
                  text: 'Date',
                  style: 'tableItemsHeader',
                },

                {
                  text: 'Start Time',
                  style: 'tableItemsHeader',
                },

                {
                  text: 'End Time',
                  style: 'tableItemsHeader',
                },
                {
                  text: 'Hours',
                  style: 'tableItemsHeader',
                },
                {
                  text: 'Sleep',
                  style: 'tableItemsHeader',
                },
                {
                  text: 'Shift Type',
                  style: 'tableItemsHeader',
                },
                {
                  text: 'Staff Name',
                  style: 'tableItemsHeader',
                },
                {
                  text: 'Signature',
                  style: 'tableItemsHeader',
                },
              ],
              // Items
              // Item 1
              ...GetTeamMemberTableItems({
                date,
                computedShifts,
                teamMembers,
                email,
                firstname,
                lastname,
              }),

              [
                {
                  text: 'WEEKLY TOTALS (Hours worked)',
                  colSpan: 4,
                  bold: true,
                  fontSize: 9,
                  // color: 'red',
                },
                {},
                {},
                {},
                {
                  text: '',
                  colSpan: 5,
                  bold: true,
                  fontSize: 10,
                  color: 'red',
                  border: [false, false, true, false],
                },
                {},
                {},
                {},
                {},
              ],

              [
                {
                  text: 'WEEKLY TOTALS (Sleeps)',
                  colSpan: 4,
                  bold: true,
                  fontSize: 9,
                  // color: 'red',
                },
                {},
                {},
                {},
                {
                  text: '',
                  colSpan: 5,
                  bold: true,
                  fontSize: 10,
                  color: 'red',
                  border: [false, false, true, true],
                },
                {},
                {},
                {},
                {},
              ],
              // END Items
            ],
          }, // table
          //  layout: 'lightHorizontalLines'
        },
        '\n',
        {
          margin: [0, 0, 0, 10],
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex % 2 === 0 ? '#f5f5f5' : '#f5f5f5';
            },
          },
          table: {
            widths: ['*', '*', '*', '*', '*', '*', '*', '*'],
            heights: [10],
            body: [
              [
                {
                  text: '1:1',
                  fontSize: 8,
                  bold: true,
                },
                {
                  text: '',
                  fontSize: 8,
                  bold: true,
                },
                {
                  text: 'Care',
                  fontSize: 8,
                  bold: true,
                },
                {
                  text: '',
                  fontSize: 8,
                  bold: true,
                },
                {
                  text: 'Support work',
                  fontSize: 8,
                  bold: true,
                },
                {
                  text: '',
                  fontSize: 8,
                  bold: true,
                },
                {
                  text: 'Nurse',
                  fontSize: 8,
                  bold: true,
                },
                {
                  text: '',
                  fontSize: 8,
                  bold: true,
                },
              ],
            ],
          },
        },

        // '\n',
        // {
        //   margin: [0, 0, 0, 10],
        //   layout: {
        //     fillColor: function (rowIndex, node, columnIndex) {
        //       return rowIndex % 2 === 0 ? '#f5f5f5' : '#f5f5f5';
        //     },
        //   },
        //   table: {
        //     widths: ['*', '*'],
        //     heights: [10],
        //     body: [
        //       [
        //         {
        //           text: 'Employee signature: ',
        //           fontSize: 9,
        //           bold: true,
        //         },
        //         {
        //           text: 'Date:',
        //           fontSize: 9,
        //           bold: true,
        //         },
        //       ],
        //     ],
        //   },
        // },
        {
          text: 'TO BE COMPLETED BY CLIENT',
          style: 'header',
          fontSize: 11,
          bold: true,
          margin: [0, 0, 0, 0],
          alignment: 'left',
        },
        {
          margin: [0, 0, 0, 4],
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex % 2 === 0 ? '#f5f5f5' : '#f5f5f5';
            },
          },
          table: {
            widths: ['*', '*'],
            heights: [10],
            body: [
              [
                {
                  text: 'Staff name:',
                  fontSize: 9,
                  bold: true,
                },
                {
                  text: 'Signature:',
                  fontSize: 9,
                  bold: true,
                },
              ],
              [
                {
                  text: 'Position:',
                  fontSize: 9,
                  bold: true,
                },
                {
                  text: 'Date:',
                  fontSize: 9,
                  bold: true,
                },
              ],
            ],
          },
        },
        {
          text: 'We certify that the above mentioned staff worker was present to cover the stated times and to our satisfaction. We agree to be bound by the employment business Terms & Condition.',
          style: 'header',
          fontSize: 7,
          bold: true,
          margin: [0, 0, 0, 0],
          alignment: 'left',
        },
        {
          text: 'Note:',
          style: 'header',
          fontSize: 10,
          bold: true,
          margin: [0, 20, 0, 0],
          alignment: 'left',
        },
        {
          text: '* Please email fully signed and completed timesheet to: timesheet@rotapad.com',
          style: 'header',
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10],
          alignment: 'left',
        },
      ];
      return [...sum, ...page];
    },
    []
  );
  return pages;
};
