import React, { useCallback, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { RiTimerLine } from 'react-icons/ri';
import moment from 'moment';
import { ButtonBase } from 'components/common';
import { InputNumber } from 'primereact/inputnumber';
import _ from 'lodash';
import { Calendar } from 'primereact/calendar';
import { StyledLabel } from 'components/workspace/AddWorkSpaceForm/styles';
import { classNames } from 'primereact/utils';
import { StyledDropDownDiv } from 'components/absences/AddAbsencesForm';
import Select from 'react-select';
import { customerColourStylesFx } from 'components/dashboard/AddShiftForm';
import Timer from './Timer';

const TimeEntry = ({
  visible = false,
  setVisible,
  hours = 0,
  sleepEntry = 0,
  startTimeEntry,
  endTimeEntry,
  start,
  uid,
  title,
  handleAddAttendeeHours,
}) => {
  // const [visible, setVisible] = useState(false);
  const [value3, setValue3] = useState(hours || 0.0);

  const [datetime24h, setDateTime24h] = useState(null);
  const [startTime, setStartTime] = useState(
    startTimeEntry ? new Date(startTimeEntry) : null
  );
  const [endTime, setEndTime] = useState(
    endTimeEntry ? new Date(endTimeEntry) : null
  );
  const [sleepHours, setSleepHours] = useState(sleepEntry || 0.0);

  const footerContent = useCallback(
    () => (
      <div>
        <ButtonBase
          variant='outline'
          size='small'
          onClick={() => {
            setVisible(false);
            // setValue3(0);
          }}
          // autoFocus
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          variant='primary'
          size='small'
          onClick={() => {
            console.log('sleepHours ====== ', sleepHours);
            handleAddAttendeeHours(
              _.isNil(value3)
                ? {
                    timeEntry: 0,
                  }
                : _.pickBy(
                    {
                      timeEntry: value3,
                      startTimeEntry: startTime
                        ? moment(startTime).format()
                        : startTime,
                      endTimeEntry: endTime
                        ? moment(endTime).format()
                        : endTime,
                      sleepEntry: sleepHours,
                    },
                    _.identity
                  )
            );
            setVisible(false);
            // setValue3(0);
          }}
          // autoFocus
          style={{ marginRight: 0 }}
        >
          Update
        </ButtonBase>
      </div>
    ),
    [handleAddAttendeeHours, value3, sleepHours, startTime, endTime]
  );

  return (
    <div className='flex justify-content-center'>
      <Dialog
        header={
          <>
            <RiTimerLine
              className='me-1'
              style={{ marginTop: '2px', fontSize: '1rem' }}
            />
            <span style={{ fontSize: '14px' }}>Track time</span>
          </>
        }
        visible={visible}
        style={{ width: '40vw' }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        <p
          className='m-0'
          style={{ fontSize: '12px' }}
        >
          {`Edit time entry for ${moment(new Date(start)).format(
            'dddd Do,'
          )} ${moment(new Date(start)).format('MMMM YYYY')} shift ${title}`}
        </p>

        <Timer
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          hours={value3}
          setHours={setValue3}
          sleepHours={sleepHours}
          setSleepHours={setSleepHours}
        />
      </Dialog>
    </div>
  );
};

export default TimeEntry;
