import styled from "@emotion/styled";
import { classNames } from "primereact/utils";
import { useState } from "react";
import { Field } from "react-final-form";

const StyledInputTextArea = styled.textarea`
	padding: 8px 8px;
	font-size: 12px;
	width: 100% !important;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
		Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	color: #495057;
	background: #ffffff;
	border: 1px solid #ced4da;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s,
		box-shadow 0.2s;
	appearance: none;
	border-radius: 6px;
	margin: 0;

	&:hover {
		border-color: #6366f1;
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem #c7d2fe;
		border-color: #6366f1;
	}
	&:disabled {
		background-color: #e9ecef;
		opacity: 0.6;
		&:hover {
			border-color: #ced4da;
		}
	}
`;

export const StyledDropDownDiv = styled.div`
	// border-width: 0 0 0 2px;
	// border-style: solid;
	// padding-left: 12px;
	// border-color: #6554c0;
	color: #6554c0;
	font-size: 18px;
	.customDropdown {
		width: 100%;
	}
	.customDropdown:not(.p-disabled):hover {
		border-color: #6366f1;
	}

	.customDropdown .p-dropdown-label {
		background: transparent;
		border: 0 none;
		padding: 8px 8px;
		font-size: 12px;
	}

	.customDropdown .p-dropdown-trigger {
		width: 2rem;
	}

	.customDropdown .p-dropdown-trigger-icon {
		font-size: 10px;
	}

	.p-disabled,
	.p-component:disabled {
		opacity: 0.6;
		background-color: #e9ecef;
	}
`;

export const StyledInput = styled.input`
	padding: 8px 8px;
	font-size: 12px;
	width: 100% !important;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
		Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	color: #495057;
	background: #ffffff;
	border: 1px solid #ced4da;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s,
		box-shadow 0.2s;
	appearance: none;
	border-radius: 6px;
	margin: 0;

	&:hover {
		border-color: #6366f1;
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem #c7d2fe;
		border-color: #6366f1;
	}
	&:disabled {
		background-color: #e9ecef;
		opacity: 0.6;
		&:hover {
			border-color: #ced4da;
		}
	}
`;

const StyledLabel = styled.label`
	font-size: 12px;
	display: inline-block;
	font-weight: 500;
`;
const StyledH5 = styled.h5`
	// border-width: 0 0 0 2px;
	// border-style: solid;
	// padding-left: 12px;
	// border-color: #6554c0;
	color: #6554c0;
	font-size: 18px;
	padding-top: 16px;
`;
const StyledDiv = styled.div`
	right: 12px !important;
	top: 40px !important;
	cursor: pointer;
	color: #6c757d;

	&:hover {
		color: #7f00ff;
	}

	i {
		cursor: pointer;
		z-index: 200;
		font-size: large;
		color: #6c757d;

		&:hover {
			color: #7f00ff;
		}
	}
`;

const AddGroupForm = ({
	handleSubmit,
	_id,
	pristine,
	submitting,
	hasValidationErrors,
	visibleLeft,
}) => {
	const [showNewPassword, setShowNewPassword] = useState({
		hide: true,
		info: "Show",
	});

	const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
	const getFormErrorMessage = (meta) => {
		return (
			isFormFieldValid(meta) && <small className='p-error'>{meta.error}</small>
		);
	};

	const isSelf = false;
	const isUserAdmin = true;

	return (
		<div className='w-100 p-3'>
			<div className='row g-3'>
				<div className='col-12 mb-1 text-muted'>
					User groups make it easy to get the attention of lots of members at
					once. When you use a group’s handle – like @accounts, for example –
					everyone in that group will be notified.
				</div>
				<div className='col-12 mb-1'>
					<Field
						name='name'
						render={({ input, meta }) => (
							<div className='field'>
								<div className='block'>
									<StyledLabel
										htmlFor='name'
										className={classNames({
											"p-error": isFormFieldValid(meta),
										})}>
										Name <span className='text-danger'>*</span>
									</StyledLabel>
									<StyledInput
										id='name'
										{...input}
										className={classNames({
											"p-invalid": isFormFieldValid(meta),
										})}
										disabled={!isUserAdmin}
									/>
								</div>
								{getFormErrorMessage(meta)}
							</div>
						)}
					/>
				</div>

				<div className='col-12 mt-1 mb-1'>
					<Field
						name='handle'
						render={({ input, meta }) => (
							<div className='field'>
								<div className='block'>
									<StyledLabel
										htmlFor='handle'
										className={classNames({
											"p-error": isFormFieldValid(meta),
										})}>
										Handle <span className='text-danger'>*</span>
									</StyledLabel>
									<StyledInput
										id='handle'
										{...input}
										className={classNames({
											"p-invalid": isFormFieldValid(meta),
										})}
										disabled={!isUserAdmin}
									/>
								</div>
								{getFormErrorMessage(meta)}
							</div>
						)}
					/>
				</div>
				<div className='col-12 mt-1 mb-1'>
					<Field
						name='description'
						render={({ input, meta }) => (
							<div className='field'>
								<div className='block'>
									<StyledLabel htmlFor='description'>Description</StyledLabel>
									<StyledInputTextArea
										id='description'
										placeholder='Enter description'
										rows={5}
										cols={30}
										{...input}
										className='w-100 p-inputtext-sm'
										disabled={!isUserAdmin}
									/>
								</div>
							</div>
						)}
					/>
				</div>
			</div>
		</div>
	);
};

export default AddGroupForm;
