import { ref as storageRef } from "firebase/storage";
import { useRef } from "react";
import { useUploadFile } from "react-firebase-hooks/storage";
import { TOAST_TYPE } from "../../common/constants";
import { useToast } from "../../common/hooks/useToast";
import UserImg from "../../common/img/user.png";
import { useApiService } from "../../context";
import RenderPhoto from "../RenderPhoto";

const validateSelectedFile = (newImage) => {
	// const MIN_FILE_SIZE = 1024; // 1MB
	const MAX_FILE_SIZE = 2024; // 5MB

	if (!newImage) {
		// setIsSuccess(false);
		return false;
	}

	// console.log("newImage.size ===== ", newImage.size);
	const fileSizeKiloBytes = newImage.size / 1024;

	// if (fileSizeKiloBytes < MIN_FILE_SIZE) {
	// 	setErrorMsg("File size is less than minimum limit");
	// 	setIsSuccess(false);
	// 	return;
	// }
	if (fileSizeKiloBytes > MAX_FILE_SIZE) {
		return false;
	}

	return true;
};

const UpdatePhoto = ({ rotaPadUser }) => {
	const hiddenFileInput = useRef(null);
	const { user, storage, fetchProfileImageData, setProfileImage } =
		useApiService();
	const [uploadFile] = useUploadFile();
	const ref = storageRef(storage, "/" + user.uid + "/file.png");
	const { triggerToast } = useToast();

	const handleClick = (event) => {
		hiddenFileInput.current.click();
	};

	const handleFileUpload = async (e) => {
		const file = e.target.files[0];
		// console.log("file.size ===== ", file);

		if (validateSelectedFile(file)) {
			await uploadFile(ref, file, {
				contentType: file.type,
			});
			const getUrl = await fetchProfileImageData({
				path: "/" + user.uid + "/file.png",
			});

			setProfileImage(getUrl);
		} else {
			//toast
			triggerToast({
				message: "Image should not exceed 2MB",
				type: TOAST_TYPE.warning,
			});
		}
	};

	return (
		<div>
			<input
				type='file'
				label='Image'
				name='myFile'
				accept='.jpeg, .png, .jpg'
				onChange={(e) => handleFileUpload(e)}
				ref={hiddenFileInput}
				// onChange={handleChange}
				style={{ display: "none" }}
			/>
			<div className='d-flex flex-row mt-2 mb-2 align-items-center'>
				<div>
					<RenderPhoto placeholder={UserImg} />
				</div>
				<div
					className='d-flex flex-column ms-2 text-truncate'
					style={{ marginTop: "-8px" }}>
					<div
						className='text-uppercase fw-light text-muted text-truncate'
						style={{
							fontSize: "12px",
							marginBottom: "-4px",
						}}>
						{rotaPadUser.email}
					</div>
					<div className='fw-semibold'>{`${rotaPadUser.firstname} ${rotaPadUser.lastname}`}</div>
					<div className='fw-semibold' style={{ marginTop: "-8px" }}>
						<button
							type='button'
							className='btn btn-link p-0'
							style={{ fontSize: "12px" }}
							onClick={handleClick}>
							Change image
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UpdatePhoto;
