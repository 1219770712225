import { css } from '@emotion/react';
import styled from '@emotion/styled';
import KeyMirror from 'keymirror';

export const BTN_VARIANTS = KeyMirror({
  primary: null,
  secondary: null,
  outline: null,
  danger: null,
  warning: null,
  success: null,
});

export const BTN_SIZES = KeyMirror({
  large: null,
  default: null,
  small: null,
});

const getVariantStyles = ({ variant, theme }) => {
  const variations = {
    [BTN_VARIANTS.outline]: css`
      color: ${theme.primary};
      background-color: transparent;
      border: 1px solid ${theme.primary};
      box-shadow: 0px 1px 3px #0000000d;
      &:hover {
        background-color: ${theme['primary']};
        border-color: ${theme['primary']};
        color: #fff;
      }
      &:active {
        background-color: ${theme['primary-500']};
      }
      &:disabled {
        background-color: ${theme['primary-300']};
        border-color: ${theme['primary-300']};
        color: #ffffffcc;
        box-shadow: none;
      }
    `,
    [BTN_VARIANTS.primary]: css`
      color: #fff;
      background-color: ${theme.primary};
      border: 1px solid ${theme.primary};
      box-shadow: 0px 1px 3px #0000000d;
      &:hover {
        background-color: ${theme['primary-700']};
        border-color: ${theme['primary-700']};
      }
      &:active {
        background-color: ${theme['primary-500']};
      }
      &:disabled {
        background-color: ${theme['primary-300']};
        border-color: ${theme['primary-300']};
        color: #ffffffcc;
        box-shadow: none;
      }
    `,
    [BTN_VARIANTS.danger]: css`
      color: #fff;
      background-color: ${theme.danger};
      border: 1px solid ${theme.danger};
      box-shadow: 0px 1px 3px #0000000d;
      &:hover {
        background-color: ${theme['primary-500']};
      }
      &:active {
        background-color: ${theme['primary-700']};
        border-color: ${theme['primary-700']};
      }
      &:disabled {
        background-color: ${theme['primary-300']};
        border-color: ${theme['primary-300']};
        color: #ffffffcc;
        box-shadow: none;
      }
    `,
  };

  return variant ? variations[variant] : variations[BTN_VARIANTS.primary];
};

const getSizeStyles = ({ variant, size }) => {
  const variations = {
    [BTN_SIZES.large]: css`
      padding: 7px 20px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
    `,
    [BTN_SIZES.default]: css`
      padding: 7px 16px;
      font-size: 12px;
      line-height: 17px;
      font-weight: 600;
    `,
    [BTN_SIZES.small]: css`
      padding: 5px 12px;
      font-size: 10px;
      line-height: 15px;
      font-weight: 600;
    `,
  };

  return size ? variations[size] : variations[BTN_SIZES.default];
};

const StyledButtonBase = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  width: 100%;
  cursor: pointer;
  user-select: none;
  border-radius: 0.5rem;

  ${getVariantStyles}
  ${getSizeStyles}

	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:disabled {
    cursor: default;
  }
`;

const ButtonBase = ({ variant, size, disabled, children, ...rest }) => {
  return (
    <StyledButtonBase
      {...rest}
      variant={variant}
      size={size}
      disabled={disabled}
    >
      {children}
    </StyledButtonBase>
  );
};

export default ButtonBase;
