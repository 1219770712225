import styled from '@emotion/styled';
import _ from 'lodash';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
import { useCallback, useMemo } from 'react';
import { Pill, ToolTip } from '../../common';
import { BADGE_STYLE, TOAST_TYPE } from '../../common/constants';
import { useToast } from 'components/common/hooks/useToast';

const StyledLabel = styled.label`
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
  color: ${(props) => (props.disabled ? '#6c757da6' : 'inherit')};
`;
const StyledH5 = styled.h5`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
`;

const TeamAvailability = ({
  input: { onChange, value },
  users,
  endValue,
  startValue,
  leaveRequests,
  rotaPadTeam,
}) => {
  // console.log("TeamAvailability rotaPadTeam ====== ", rotaPadTeam);
  const { triggerToast } = useToast();
  const getLeaveRequestsForDates = useMemo(() => {
    return (
      leaveRequests &&
      leaveRequests.filter((obj) => {
        const startOfMonth = moment(obj.startDate);
        const endOfMonth = moment(obj.endDate);
        var startDate = moment(startValue);
        var endDate = moment(endValue);
        return (
          startDate.isBetween(startOfMonth, endOfMonth, 'days', '[]') ||
          endDate.isBetween(startOfMonth, endOfMonth, 'days', '[]')
        );
      })
    );
  }, [endValue, leaveRequests, startValue]);

  const isDisabled = (attendeeId, getLeaveRequests) => {
    return (
      getLeaveRequests &&
      getLeaveRequests.filter((obj) => obj.user.userId === attendeeId).length >
        0
    );
  };

  const onAssigneeChange = useCallback(
    (e) => {
      if (value && value.length === 1 && e.checked) {
        triggerToast({
          message: 'Shifts can only be assigned to one team member!',
          type: TOAST_TYPE.warning,
        });

        return;
      }

      let _selectedCategories = [...value];

      if (e.checked) _selectedCategories.push(e.value);
      else
        _selectedCategories = _selectedCategories.filter(
          (category) => category.email !== e.value.email
        );

      onChange(_selectedCategories);
    },
    [onChange, value, triggerToast]
  );

  if (!_.isObject(rotaPadTeam)) return;

  const AbsenceBadgeContainer = ({ attendee, leaveRequests }) => {
    return (
      <>
        {getLeaveRequestsForDates &&
          getLeaveRequestsForDates
            .filter((obj) => obj.user.userId === attendee._id)
            .map((leaveRequest) => {
              return (
                <Pill
                  content={leaveRequest.leavePolicy}
                  bgStyle={{ ...BADGE_STYLE[leaveRequest.leavePolicy] }}
                  toolTipMessage={`From ${moment(leaveRequest.startDate).format(
                    'MMMM Do YYYY'
                  )} to ${moment(leaveRequest.endDate).format('MMMM Do YYYY')}`}
                />
              );
            })}
      </>
    );
  };
  return (
    <div className='my-4'>
      <StyledH5 className='mt-3 mb-2'>Team Availability</StyledH5>

      <div className='row'>
        {Object.keys(rotaPadTeam).map((attendee) => {
          const { uid, firstname, lastname, email, role, group } =
            rotaPadTeam[attendee];
          // console.log('uid ==== ', uid);
          return (
            <div
              key={uid || ''}
              className='col-12 mb-1'
            >
              <div className='field'>
                <div className='block'>
                  <Checkbox
                    inputId={uid}
                    name={firstname + ' ' + lastname}
                    value={rotaPadTeam[attendee]}
                    checked={(value || []).some((item) => item.email === email)}
                    disabled={role && role === 'Admin'}
                    onChange={onAssigneeChange}
                  />
                  <StyledLabel
                    htmlFor={firstname + ' ' + lastname}
                    className={`ms-2 me-2 `}
                    disabled={role && role === 'Admin'}
                  >
                    {firstname + ' ' + lastname}
                  </StyledLabel>

                  {role && role === 'Admin' && (
                    <ToolTip
                      placement='top'
                      overlayClassName='tooltipCustom'
                      overlay={
                        <span
                          style={{
                            fontSize: 10,
                          }}
                        >
                          Admin user
                        </span>
                      }
                    >
                      <button
                        type='button'
                        style={{
                          background: '#be20a5',
                          color: '#fff',
                          fontSize: '10px',
                          height: '20px',
                          border: 'none',
                          borderRadius: '10px',
                          lineHeight: '19px',
                          cursor: 'auto',
                          marginRight: '3px',
                        }}
                      >
                        {role}
                      </button>
                    </ToolTip>
                  )}
                  <AbsenceBadgeContainer
                    attendee={rotaPadTeam[attendee]}
                    leaveRequests={getLeaveRequestsForDates}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TeamAvailability;
