import React, { useCallback, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { RiTimerLine } from 'react-icons/ri';
import moment from 'moment';
import { ButtonBase } from 'components/common';
import { InputNumber } from 'primereact/inputnumber';
import _ from 'lodash';
import { BsHouses } from 'react-icons/bs';
import { useApiService } from 'components/context';
import { convertObjectToArray, formatHour } from 'components/common/utils';
import Select from 'react-select';
import {
  StyledDropDownDiv,
  customerColourStylesFx,
} from 'components/dashboard/AddShiftForm';
import { useBranding } from 'components/common/hooks/useBranding';
import { StyledLabel } from 'components/workspace/AddWorkSpaceForm/styles';
import { classNames } from 'primereact/utils';
import { BiMailSend } from 'react-icons/bi';
import axios from 'axios';
import Timer from '../TimeEntry/Timer';
import { useToast } from 'components/common/hooks/useToast';
import { TOAST_TYPE } from 'components/common/constants';

const CustomerApproval = ({
  visible = false,
  setVisible,
  hours = 0,
  sleepEntry,
  startTimeEntry,
  endTimeEntry,
  uid,
  start,
  title,
  handleAddAttendeeHours,
  customer,
}) => {
  // const [visible, setVisible] = useState(false);
  const {
    colour: { primary },
    logo,
  } = useBranding();
  const [value3, setValue3] = useState(hours || 0.0);
  const [selectedCustomerStaff, setSelectedCustomerStaff] = useState();
  const { isRotaPadUserAdmin, rotaPadCustomersTopic, rotaPadUser } =
    useApiService();
  const { triggerToast } = useToast();
  // console.log('rotaPadCustomersTopic === ', rotaPadCustomersTopic);
  // console.log('rotaPadUser ==== ', rotaPadUser);

  const getCustomerStaff = useMemo(() => {
    const getCustomers = convertObjectToArray({ item: rotaPadCustomersTopic });
    // Step 1: Get all units
    const unitsCollection = _.reduce(
      getCustomers,
      function (sum, n) {
        const { units, id, name } = n;
        const newUnitsArray = (units || []).map((obj) => {
          return {
            ...obj,
            customerId: id,
            customerName: name,
          };
        });
        return [...sum, ...newUnitsArray];
      },
      []
    );

    const { customerStaff, name } = _.find(unitsCollection, { id: customer });

    return customerStaff && !_.isEmpty(customerStaff)
      ? customerStaff.map(({ email, firstname, lastname }) => {
          return {
            label: `${firstname} ${lastname}`,
            value: email,
            name,
          };
        })
      : [];
  }, [rotaPadCustomersTopic, customer]);

  // console.log('selectedCustomerStaff === ', selectedCustomerStaff);
  const footerContent = useCallback(
    () => (
      <div>
        <ButtonBase
          variant='outline'
          size='small'
          onClick={() => {
            setVisible(false);
            // setValue3(0);
          }}
          // autoFocus
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          variant='primary'
          size='small'
          onClick={async () => {
            // handleAddAttendeeHours({
            //   approved: true,
            //   approvalDate: moment().format(),
            // });

            const success = await axios.post(
              'https://api.sendinblue.com/v3/smtp/email',
              {
                sender: { name: 'RotaPad', email: 'noreply@rotapad.com' },
                to: [
                  {
                    email: selectedCustomerStaff.value,
                    // email: 'kcijezie+006@gmail.com',
                    name: selectedCustomerStaff.label,
                  },
                ],
                subject: 'Customer Approval Request Email',
                htmlContent: `<!doctype html>
                <html>
                  <head>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                    <title>Simple Transactional Email</title>
                    <style>
                @media only screen and (max-width: 620px) {
                  table.body h1 {
                    font-size: 28px !important;
                    margin-bottom: 10px !important;
                  }
                
                  table.body p,
                table.body ul,
                table.body ol,
                table.body td,
                table.body span,
                table.body a {
                    font-size: 16px !important;
                  }
                
                  table.body .wrapper,
                table.body .article {
                    padding: 10px !important;
                  }
                
                  table.body .content {
                    padding: 0 !important;
                  }
                
                  table.body .container {
                    padding: 0 !important;
                    width: 100% !important;
                  }
                
                  table.body .main {
                    border-left-width: 0 !important;
                    border-radius: 0 !important;
                    border-right-width: 0 !important;
                  }
                
                  table.body .btn table {
                    width: 100% !important;
                  }
                
                  table.body .btn a {
                    width: 100% !important;
                  }
                
                  table.body .img-responsive {
                    height: auto !important;
                    max-width: 100% !important;
                    width: auto !important;
                  }
                }
                @media all {
                  .ExternalClass {
                    width: 100%;
                  }
                
                  .ExternalClass,
                .ExternalClass p,
                .ExternalClass span,
                .ExternalClass font,
                .ExternalClass td,
                .ExternalClass div {
                    line-height: 100%;
                  }
                
                  .apple-link a {
                    color: inherit !important;
                    font-family: inherit !important;
                    font-size: inherit !important;
                    font-weight: inherit !important;
                    line-height: inherit !important;
                    text-decoration: none !important;
                  }
                
                  #MessageViewBody a {
                    color: inherit;
                    text-decoration: none;
                    font-size: inherit;
                    font-family: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                  }
                
                  .btn-primary table td:hover {
                    background-color: #34495e !important;
                  }
                
                  .btn-primary a:hover {
                    background-color: #34495e !important;
                    border-color: #34495e !important;
                  }
                }
                </style>
                  </head>
                  <body style="background-color: #f6f6f6; font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                    <span class="preheader" style="color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;">This is preheader text. Some clients will show this text as a preview.</span>
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f6f6f6; width: 100%;" width="100%" bgcolor="#f6f6f6">
                      <tr>
                        <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
                        <td class="container" style="font-family: sans-serif; font-size: 14px; vertical-align: top; display: block; max-width: 580px; padding: 10px; width: 580px; margin: 0 auto;" width="580" valign="top">
                          <div class="content" style="box-sizing: border-box; display: block; margin: 0 auto; max-width: 580px; padding: 10px;">
                
                            <!-- START CENTERED WHITE CONTAINER -->
                            <table role="presentation" class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background: #ffffff; border-radius: 3px; width: 100%;" width="100%">
                
                              <!-- START MAIN CONTENT AREA -->
                              <tr>
                                <td class="wrapper" style="font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 20px;" valign="top">
                                  <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
                                    <tr>
                                      <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">
                                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;"><img src="https://rotapad.com/wp-content/uploads/2018/01/rota_pad_logo_large2.png"  alt="user-img" style="width: 139px; height: 32px; cursor: pointer;"></p>
                                        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Hi ${
                                          selectedCustomerStaff.label
                                        },</p>
                                        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Approve time entry for ${moment(
                                          new Date(start)
                                        ).format('dddd Do,')} ${moment(
                  new Date(start)
                ).format('MMMM YYYY')} shift ${title}. This is ${
                  rotaPadUser.firstname
                } ${rotaPadUser.lastname} logging ${formatHour(hours)}hrs at ${
                  selectedCustomerStaff.name
                }</p>
                                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; box-sizing: border-box; width: 100%;" width="100%">
                                          <tbody>
                                            <tr>
                                              <td align="left" style="font-family: sans-serif; font-size: 14px; vertical-align: top; padding-bottom: 15px;" valign="top">
                                                <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: auto;">
                                                  <tbody>
                                                    <tr>
                                                      <td style="font-family: sans-serif; font-size: 14px; vertical-align: top; border-radius: 5px; text-align: center; background-color: #3498db;" valign="top" align="center" bgcolor="#3498db"> <a href="https://beta.rotapad.com/approve?_atemail=${
                                                        rotaPadUser.email
                                                      }&_month=${moment(start)
                  .format('MMMM')
                  .toLowerCase()}&_sid=${uid}&_email=${
                  selectedCustomerStaff.value
                }" target="_blank" style="border: solid 1px #3498db; border-radius: 5px; box-sizing: border-box; cursor: pointer; display: inline-block; font-size: 14px; font-weight: bold; margin: 0; padding: 12px 25px; text-decoration: none; text-transform: capitalize; background-color: #3498db; border-color: #3498db; color: #ffffff;">Click to Approve</a> </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Approver - If you select this option, the email will go to the Approver who is configured. Based on your selection, the email will go to the respective person.</p>
                                        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Have a nice day ahead!</p>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                
                            <!-- END MAIN CONTENT AREA -->
                            </table>
                            <!-- END CENTERED WHITE CONTAINER -->
                
                            <!-- START FOOTER -->
                            <div class="footer" style="clear: both; margin-top: 10px; text-align: center; width: 100%;">
                              <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
                                <tr>
                                  <td class="content-block" style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; color: #999999; font-size: 12px; text-align: center;" valign="top" align="center">
                                    <span class="apple-link" style="color: #999999; font-size: 12px; text-align: center;">Rota Pad Services Inc. T2Z 1J9, Calgary AB.</span>
                                    <br> Do you want fnd out more? <a href="https://rotapad.com" style="text-decoration: underline; color: #999999; font-size: 12px; text-align: center;">RotaPad.com</a>.
                                  </td>
                                </tr>
                                <tr>
                                  <td class="content-block powered-by" style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; color: #999999; font-size: 12px; text-align: center;" valign="top" align="center">
                                    Powered by <a href="https://rotapad.com" style="color: #999999; font-size: 12px; text-align: center; text-decoration: none;">RotaPad APIs</a>.
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <!-- END FOOTER -->
                
                          </div>
                        </td>
                        <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
                      </tr>
                    </table>
                  </body>
                </html>`,
                headers: {
                  charset: 'iso-8859-1',
                },
              },
              {
                headers: {
                  'api-key': `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`,
                },
              }
            );

            if (success) {
              triggerToast({
                message: 'Customer approval email sent successfully!',
                type: TOAST_TYPE.success,
              });
            } else {
              triggerToast({
                message: 'Customer approval email could not sent!',
                type: TOAST_TYPE.warning,
              });
            }

            setVisible(false);
            // setValue3(0);
          }}
          // autoFocus
          style={{ marginRight: 0 }}
          disabled={!selectedCustomerStaff}
        >
          Send Email Approval Request
        </ButtonBase>
      </div>
    ),
    [selectedCustomerStaff, hours, rotaPadUser]
  );

  return (
    <div className='flex justify-content-center'>
      <Dialog
        header={
          <>
            <BsHouses
              className='me-1'
              style={{ marginTop: '2px', fontSize: '1rem' }}
            />
            <span style={{ fontSize: '14px' }}>Customer appproval</span>
          </>
        }
        visible={visible}
        style={{ width: '40vw', height: '80vh' }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        <p
          className='m-0'
          style={{ fontSize: '0.875rem' }}
        >
          {`Approve time entry for ${moment(new Date(start)).format(
            'dddd Do,'
          )} ${moment(new Date(start)).format('MMMM YYYY')} shift ${title}`}
        </p>

        {/* <div className='d-flex justify-content-start mt-4'>
          <InputNumber
            inputId='minmaxfraction'
            value={value3}
            onValueChange={(e) => {
              setValue3(e.value);
            }}
            minFractionDigits={2}
            maxFractionDigits={2}
            className='w-100'
            disabled
          />
        </div> */}

        <Timer
          startTime={startTimeEntry ? new Date(startTimeEntry) : null}
          setStartTime={(x) => console.log(x)}
          endTime={endTimeEntry ? new Date(endTimeEntry) : null}
          setEndTime={(x) => console.log(x)}
          hours={hours}
          setHours={(x) => console.log(x)}
          sleepHours={sleepEntry || 0}
          setSleepHours={(x) => console.log(x)}
          disabled
        />

        <div className='mt-3'>
          <StyledLabel
            htmlFor='status'
            className={classNames({
              'p-error': false,
            })}
          >
            Select Customer Staff <span className='text-danger'>*</span>
          </StyledLabel>
          <StyledDropDownDiv>
            <Select
              id='type'
              placeholder='Enter staff name'
              styles={customerColourStylesFx(primary)}
              value={selectedCustomerStaff}
              onChange={(x) => {
                // console.log('Select ===== ', x);
                setSelectedCustomerStaff(x);
              }}
              options={getCustomerStaff}
              // isDisabled={!isUserAdmin}
            />
          </StyledDropDownDiv>
        </div>

        <div className='d-flex text-center mt-4 pt-3'>
          <div className='mt-4'>
            <BiMailSend style={{ fontSize: '8rem' }} />
            <p>
              Approver - If you select this option, the email will go to the
              Approver who is configured. Based on your selection, the email
              will go to the respective person.
            </p>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default CustomerApproval;
