import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { BsPeople } from 'react-icons/bs';
import { ButtonBase, PageTitleBar } from '../common';
import { BADGE_STYLE } from '../common/constants';
import { useApiService } from '../context'; //theme
import AddGroupFormContainer from './AddGroupFormContainer';
import StarterImg from '../common/img/explore.png';
import Card from './Card';

const UserGroups = () => {
  const { rotaPadUser, auth, rotaPadUserGroupsTopic } = useApiService();
  const [visibleLeft, setVisibleLeft] = useState(false);
  const [newGroup, setNewGroup] = useState({
    name: '',
    handle: '',
    description: '',
    uid: undefined,
    createdAt: moment().format(),
  });

  // console.log('rotaPadUserGroupsTopic ===== ', rotaPadUserGroupsTopic);
  // console.log('rotaPadGroups ===== ', rotaPadGroups);
  if (!rotaPadUser) return null;

  // console.log("rotaPadGroups ==== ", rotaPadGroups);
  return (
    <div className='container-fluid mx-0 px-0'>
      <div className='row'>
        <div className='col-12 mt-0'>
          <AddGroupFormContainer
            visibleLeft={visibleLeft}
            setVisibleLeft={setVisibleLeft}
            auth={auth}
            newGroup={newGroup}
            setNewGroup={setNewGroup}
          />

          <PageTitleBar
            title='User groups'
            count={
              _.isObject(rotaPadUserGroupsTopic)
                ? Object.keys(rotaPadUserGroupsTopic).length
                : `0`
            }
            badgeStyle={BADGE_STYLE.Menu}
            handleClickAdd={() => setVisibleLeft(true)}
          />
          {_.isObject(rotaPadUserGroupsTopic) &&
          Object.keys(rotaPadUserGroupsTopic).length > 0 ? (
            <div className='container-fluid py-2 px-0'>
              <div className='row g-3 '>
                {Object.keys(rotaPadUserGroupsTopic).map((key) => {
                  return (
                    <Card
                      key={key}
                      setNewGroup={setNewGroup}
                      setVisibleLeft={setVisibleLeft}
                      {...rotaPadUserGroupsTopic[key]}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <>
              <div className='card mt-3 text-center'>
                <div className='pt-4 pb-2 text-muted'>
                  <img src={StarterImg} />
                </div>
                <div className='d-flex justify-content-center px-4 mb-2'>
                  <div className='text-muted w-50'>
                    <div className=''>
                      Organise your team with user groups User groups let you
                      manage entire departments at once by bundling them
                      together in a group, such as @experienced or @intern.
                      Learn more
                    </div>
                    <div className='pt-4 mb-4'>
                      <ButtonBase
                        type='button'
                        variant='primary'
                        size='default'
                        className='w-50'
                        onClick={() => setVisibleLeft(true)}
                      >
                        <i
                          className='pi pi-user-plus'
                          style={{
                            fontSize: '12px',
                            marginRight: '4px',
                          }}
                        ></i>
                        Add new group
                      </ButtonBase>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserGroups;
