import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { BsCalendarX } from 'react-icons/bs';
import { ButtonBase, PageTitleBar } from '../common';
import { BADGE_STYLE } from '../common/constants';
import { useApiService } from '../context'; //theme
import AddAbsencesFormContainer from './AddAbsencesFormContainer';
import StarterImg from '../common/img/explore.png';
import Card from './Card';

const Absences = () => {
  const { rotaPadUser, auth, rotaPadAbsencesTopic } = useApiService();
  const [visibleLeft, setVisibleLeft] = useState(false);
  const [newGroup, setNewGroup] = useState({
    autoApproved: true,
    endDate: '',
    leavePolicy: '',
    reason: '',
    startDate: '',
    startEndDateTimeRange: '',
    approver: '',
    user: '',
    status: 'APPROVED',
    uid: undefined,
    createdAt: moment().format(),
  });

  if (!rotaPadUser) return null;

  // console.log("rotaPadAbsences ==== ", rotaPadAbsences);
  return (
    <div className='container-fluid mx-0 px-0'>
      <div className='row'>
        <div className='col-12 mt-0'>
          <AddAbsencesFormContainer
            visibleLeft={visibleLeft}
            setVisibleLeft={setVisibleLeft}
            auth={auth}
            newGroup={newGroup}
            setNewGroup={setNewGroup}
          />

          <PageTitleBar
            title='Absences'
            count={
              _.isObject(rotaPadAbsencesTopic)
                ? Object.keys(rotaPadAbsencesTopic).length
                : '0'
            }
            badgeStyle={BADGE_STYLE.Menu}
            handleClickAdd={() => setVisibleLeft(true)}
          />
          {_.isObject(rotaPadAbsencesTopic) &&
          Object.keys(rotaPadAbsencesTopic).length > 0 ? (
            <div className='container-fluid py-2 px-0'>
              <div className='row g-3 '>
                {Object.keys(rotaPadAbsencesTopic).map((key) => {
                  return (
                    <Card
                      key={key}
                      setNewGroup={setNewGroup}
                      setVisibleLeft={setVisibleLeft}
                      {...rotaPadAbsencesTopic[key]}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <>
              <div className='card mt-3 text-center'>
                <div className='pt-4 pb-2 text-muted'>
                  <img src={StarterImg} />
                </div>
                <div className='d-flex justify-content-center px-4 mb-2'>
                  <div className='text-muted w-50'>
                    <div className=''>
                      Create an absence request to let your manager and team
                      know when you are out-of-office. Options used and allowed
                      for team members to use vary based on your organization’s
                      work policies.
                    </div>
                    <div className='pt-4 mb-4'>
                      <ButtonBase
                        type='button'
                        variant='primary'
                        size='default'
                        className='w-50'
                        onClick={() => setVisibleLeft(true)}
                      >
                        <i
                          className='pi pi-user-plus'
                          style={{
                            fontSize: '12px',
                            marginRight: '4px',
                          }}
                        ></i>
                        Request leave
                      </ButtonBase>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Absences;
