import styled from "@emotion/styled";
import { BADGE_STYLE } from "../../common/constants";

export const StyledLabel = styled.label`
	font-size: 12px;
	display: inline-block;
	font-weight: 500;
`;

export const StyledInput = styled.input`
	padding: 8px 8px;
	font-size: 12px;
	width: 100% !important;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
		Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	color: #495057;
	background: #ffffff;
	border: 1px solid #ced4da;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s,
		box-shadow 0.2s;
	appearance: none;
	border-radius: 6px;
	margin: 0;

	&:hover {
		border-color: #6366f1;
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem #c7d2fe;
		border-color: #6366f1;
	}
	&:disabled {
		background-color: #e9ecef;
		opacity: 0.6;
		&:hover {
			border-color: #ced4da;
		}
	}
`;

export const StyledCalendarDiv = styled.div`
	.p-calendar {
		width: 100%;
	}
	input {
		padding: 8px 8px;
		font-size: 12px;
		width: 100% !important;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
			Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
			"Segoe UI Symbol";
		color: #495057;
		background: #ffffff;
		border: 1px solid #ced4da;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s,
			box-shadow 0.2s;
		appearance: none;
		border-radius: 6px;
		margin: 0;

		&:hover {
			border-color: #6366f1;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: 0 0 0 0.2rem #c7d2fe;
			border-color: #6366f1;
		}
		&:disabled {
			background-color: #e9ecef;
			opacity: 0.6;
			&:hover {
				border-color: #ced4da;
			}
		}
	}
`;
export const StyledInputTextArea = styled.textarea`
	padding: 8px 8px;
	font-size: 12px;
	width: 100% !important;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
		Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	color: #495057;
	background: #ffffff;
	border: 1px solid #ced4da;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s,
		box-shadow 0.2s;
	appearance: none;
	border-radius: 6px;
	margin: 0;

	&:hover {
		border-color: #6366f1;
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem #c7d2fe;
		border-color: #6366f1;
	}
	&:disabled {
		background-color: #e9ecef;
		opacity: 0.6;
		&:hover {
			border-color: #ced4da;
		}
	}
`;

export const colourStyles = {
	control: (styles) => ({
		...styles,
		borderRadius: "6px",
		backgroundColor: "white",
		borderColor: "#ced4da",
		fontSize: "12px",
		boxShadow: "none",
		":hover": {
			borderColor: "#6366f1",
		},
	}),
	option: (styles, { isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: isDisabled
				? undefined
				: isSelected
				? "#EEF2FF"
				: isFocused
				? "#e8ebef"
				: undefined,
			color: isDisabled ? "#ccc" : isSelected ? "#4338CA" : "#495057",
			cursor: isDisabled ? "not-allowed" : "default",
			fontSize: "12px",

			":active": {
				...styles[":active"],
				backgroundColor: !isDisabled ? (isSelected ? "blue" : "red") : "green",
			},
		};
	},
	valueContainer: (styles) => ({ ...styles, padding: "4px" }),
	singleValue: (styles, { data }) => {
		return {
			...styles,
			...BADGE_STYLE[data.value],
			color: "#fff",
			padding: "2px 4px",
			borderRadius: "4px",
		};
	},
};
