import { useMemo, useState } from 'react';

import { useApiService } from '../../context';
import { convertObjectToArray } from '../../common/utils';
import Customer from '../Customer';
import TeamMember from '../TeamMember';
// import ReportCards from '../reportCards';
// import { REPORTS_CUSTOMERS } from 'authenticated-app/components/common/constants';

const TeamMembersContainer = ({ handleOpenSidebar, setPdfInvoicePayload }) => {
  const { rotaPadTeamMembersTopic, rotaPadCustomersTopic } = useApiService();
  // console.log(
  //   'rotaPadTeamMembersTopic ==== ',
  //   convertObjectToArray({ item: rotaPadTeamMembersTopic })
  // );
  const teamMembersArray = useMemo(
    () => convertObjectToArray({ item: rotaPadTeamMembersTopic }),
    [rotaPadTeamMembersTopic]
  );
  const customersArray = useMemo(
    () => convertObjectToArray({ item: rotaPadCustomersTopic }),
    [rotaPadCustomersTopic]
  );
  return (
    <>
      {customersArray.length > 0 ? (
        <TeamMember
          // key={teamMember.uid}
          item={teamMembersArray}
          handleOpenSidebar={handleOpenSidebar}
          setPdfInvoicePayload={setPdfInvoicePayload}
        />
      ) : (
        <>Team members reports not available.</>
      )}
    </>
  );
};

export default TeamMembersContainer;
