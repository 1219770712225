import styled from '@emotion/styled';

const StyledCard = styled.div`
  cursor: ${(props) => (props.clickable ? 'pointer' : 'inherit')};
  font-size: 12px;
  padding-top: 0px;
  min-height: 100%;
  // margin-bottom: 4px !important;
  border: transparent solid 3px;
  img {
    width: 70px;
  }

  &:hover {
    border: ${(props) => props.theme[`secondary-light-700`]} solid 3px;
  }

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const Card = ({ children, ...rest }) => {
  return <StyledCard {...rest}>{children}</StyledCard>;
};

export default Card;
