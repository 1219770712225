import styled from '@emotion/styled';
import { ReportContext } from 'components/reports/ReportContext';
import { useContext } from 'react';

const HeaderStyledDIv = styled.div`
  font-size: 0.65rem;
  font-weight: 600;
  display: block;
  color: ${(props) => props.theme.primary};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme[`primary-400`]};
  }
`;

const HeaderToggle = ({ hideHeaders, setHideHeaders }) => {
  // const { hideHeaders, setHideHeaders } = useContext(ReportContext);
  return (
    <>
      <div className='d-flex justify-content-end'>
        <div className='d-flex flex-column text-end'>
          <HeaderStyledDIv
            className='form-check-label'
            onClick={setHideHeaders}
          >
            {`${hideHeaders ? 'Hide sections' : 'Show sections'}`}
          </HeaderStyledDIv>
          <div className='form-check form-switch d-flex justify-content-end'>
            <input
              className='form-check-input '
              type='checkbox'
              checked={hideHeaders}
              onChange={setHideHeaders}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderToggle;
