import _ from "lodash";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

const destroyTooltipOnHide = { keepParent: false };

const ToolTip = ({ visible, children, ...rest }) => {
	return (
		<Tooltip
			{...rest}
			{...(_.isUndefined(visible)
				? { destroyTooltipOnHide }
				: { visible, destroyTooltipOnHide: false })}>
			{children}
		</Tooltip>
	);
};

export default ToolTip;
