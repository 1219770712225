import styled from '@emotion/styled';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { ButtonBase, PageTitleBar } from '../common';
import { BADGE_STYLE } from '../common/constants';
import GymImg from '../common/img/bball.PNG';
import ChurchImg from '../common/img/church.PNG';
import StarterImg from '../common/img/explore.png';
import PoliceImg from '../common/img/hospital.PNG';
import SecurityImg from '../common/img/police.PNG';
import SchoolImg from '../common/img/school.PNG';
import WalmartImg from '../common/img/walmart.PNG';
import { useApiService } from '../context'; //
import AddShiftFormContainer from './AddShiftFormContainer';
import Card from './Card';
import { ApiShiftProvider } from './ShiftContext';
import MonthlyShiftsContainer from './MonthlyShiftsContainer';
const StyledCardContainer = styled.div`
  padding: 4px;
  span {
    font-weight: 500;
    font-size: 12px;
    color: #555;
  }
  &:hover {
    color: ${(props) => props.theme[`secondary-light-800`]};
    span {
      color: ${(props) => props.theme[`secondary-light-800`]};
    }
  }
`;
const StyledTemplatesDiv = styled.div`
  background: #ffc1071c;
  border-radius: 13px;
  width: 100%;
  overflow-x: auto;
`;
const StyledCard = styled.div`
  cursor: pointer;
  width: 12rem;
  font-size: 12px;
  padding-top: 20px;
  min-height: 276px;
  background-color: #fff;
  border: ${(props) => props.theme[`secondary-light-600`]} solid 3px;
  img {
    width: 130px;
    height: 130px;
    opacity: 40%;
    border-radius: 50%;
  }

  div > svg {
    font-size: 34px;
    color: ${(props) => props.theme[`primary-200`]};
    opacity: 60%;
  }
  &:hover {
    border: ${(props) => props.theme.cardHoverBorder} solid 3px;
    background-color: #fff;
    div > svg {
      color: ${(props) => props.theme.cardHoverBorder};
      opacity: 80%;
    }
    img {
      opacity: 80%;
    }
  }

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
const StyleViewBtn = styled.button`
  line-height: 14px;
  text-transform: uppercase;
  font-size: 11px !important;
`;
const StyledInput = styled.input`
  font-size: 12px !important;
  line-height: 15px;
`;
const StyledInfoDiv = styled.div`
  // cursor: pointer;
  font-size: 12px;
  background: #f3fbf5;
  &:hover {
    background: #fff;
  }
`;

const StyleNamesSpan = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: #555;
`;

const StyledHeaders = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #444;
  letter-spacing: 0.0543215rem;
`;
const Dashboard = () => {
  const { rotaPadUser, auth, rotaPadShifts, user, database, storage } =
    useApiService();
  const [visibleLeft, setVisibleLeft] = useState(false);
  const [newGroup, setNewGroup] = useState({
    name: '',
    type: '',
    handle: '',
    description: '',
    address: '',
    group: '',
    attendees: [],
    uid: undefined,
    createdAt: moment().format(),
  });

  // console.log('rotaPadShifts ===== ', rotaPadShifts);

  if (!rotaPadUser) return null;

  const WORKFLOW_TEMPLATES = [
    {
      title: '+ Events',
      description: `Some quick example text to build on the card title and make up the bulk of the card's content.`,

      image: ChurchImg,
    },
    {
      title: '+ Shifts',
      description: `Some quick example text to build on the card title and make up the bulk of the card's content.`,

      image: PoliceImg,
    },
    {
      title: '+ Workorders',
      description: `Some quick example text to build on the card title and make up the bulk of the card's content.`,

      image: WalmartImg,
    },
    {
      title: '+ School',
      description: `Some quick example text to build on the card title and make up the bulk of the card's content.`,

      image: SchoolImg,
    },
    {
      title: '+ Security',
      description: `Some quick example text to build on the card title and make up the bulk of the card's content.`,

      image: SecurityImg,
    },
    {
      title: '+ Gym',
      description: `Some quick example text to build on the card title and make up the bulk of the card's content.`,

      image: GymImg,
    },
  ];

  return (
    <>
      <ApiShiftProvider
        auth={auth}
        user={user}
        database={database}
        storage={storage}
        currentDate={moment().format().toString()}
      >
        <AddShiftFormContainer
          visibleLeft={visibleLeft}
          setVisibleLeft={setVisibleLeft}
          auth={auth}
          newGroup={newGroup}
          setNewGroup={setNewGroup}
        />

        <MonthlyShiftsContainer
          setVisibleLeft={setVisibleLeft}
          setNewGroup={setNewGroup}
          newGroup={newGroup}
        />
      </ApiShiftProvider>
    </>
  );
};

export default Dashboard;
