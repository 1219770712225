import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import _ from 'lodash';
import { formatCurrency, formatHour } from '../../../../common/utils';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontSize: 10,
    fontStyle: 'bold',
  },
  description: {
    width: '110%',
    textAlign: 'right',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 4,
    fontSize: 11,
  },
  totalHours: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: 11,
  },
});

const InvoiceTableFooter = ({ items }) => {
  // const total = items
  //   .map((item) => item.qty * item.rate)
  //   .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  // console.log('items ===== ', items);
  const totalHours = _.reduce(
    items,
    function (sum, n) {
      const { hours } = n;
      const subTotal = parseFloat(hours);
      return sum + subTotal;
    },
    0
  );

  const totalAmount = _.reduce(
    items,
    function (sum, n) {
      const { hours, payRate } = n;
      const subTotal = parseFloat(hours) * payRate;
      return sum + subTotal;
    },
    0
  );

  return (
    <View style={styles.row}>
      <Text style={styles.description}>TOTALS</Text>
      <Text style={styles.totalHours}>{formatHour(totalHours)}</Text>
      <Text style={styles.total}>{formatCurrency(totalAmount)}</Text>
    </View>
  );
};

export default InvoiceTableFooter;
